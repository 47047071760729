import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ConceptApiService } from '../../../../generated/v3';
import { ConceptSearchResult } from '../../../models';
import { TaxonomySearchOnRulesStateUseCases } from './usecases/taxonomy-search-on-rules-usecases.state';
import { SearchCriteria } from '@apiax/web-commons';
import { SearchOnRules, SearchOnRulesExport } from './taxonomy-search-on-rules.action';

export interface TaxonomySearchOnRulesStateModel {
  searchResults: ConceptSearchResult[];
  totalResults: number;
  isLoadingSearch: boolean;
  searchCriteria: SearchCriteria;
}

export const DEFAULT_STATE: TaxonomySearchOnRulesStateModel = {
  searchResults: [],
  totalResults: undefined,
  isLoadingSearch: false,
  searchCriteria: undefined
};

@State<TaxonomySearchOnRulesStateModel>({
  name: 'taxonomySearchOnRules',
  defaults: DEFAULT_STATE
})
@Injectable()
export class TaxonomySearchOnRulesState {
  private taxonomyConceptRuleSearchStateUseCases: TaxonomySearchOnRulesStateUseCases;

  @Selector()
  public static searchResults(state: TaxonomySearchOnRulesStateModel): ConceptSearchResult[] {
    return state.searchResults;
  }

  @Selector()
  public static isLoadingSearch(state: TaxonomySearchOnRulesStateModel): boolean {
    return state.isLoadingSearch;
  }

  @Selector()
  public static totalResults(state: TaxonomySearchOnRulesStateModel): number {
    return state.totalResults;
  }

  @Selector()
  public static searchCriteria(state: TaxonomySearchOnRulesStateModel): SearchCriteria {
    return state.searchCriteria;
  }

  constructor(taxonomyV3ApiService: ConceptApiService) {
    this.taxonomyConceptRuleSearchStateUseCases = new TaxonomySearchOnRulesStateUseCases(
      taxonomyV3ApiService
    );
  }

  @Action(SearchOnRules, { cancelUncompleted: true })
  public searchOnRules(
    context: StateContext<TaxonomySearchOnRulesStateModel>,
    { searchCriteria }: SearchOnRules
  ) {
    return this.taxonomyConceptRuleSearchStateUseCases.searchOnRules(context, searchCriteria);
  }

  @Action(SearchOnRulesExport)
  public searchOnRulesExport(
    context: StateContext<TaxonomySearchOnRulesStateModel>,
    { searchCriteria }: SearchOnRulesExport
  ) {
    return this.taxonomyConceptRuleSearchStateUseCases.searchOnRulesExport(context, searchCriteria);
  }
}
