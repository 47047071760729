import { StateContext } from '@ngxs/store';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FiltersApiService, GetVariablesResponse, VariablesApiService } from '../../../../../generated/v3';
import { FilterOptionMapper } from '../../../mappers/filter-option-mapper';
import { TaxonomyFiltersStateModel } from '../taxonomy-filters.state';
import { isEmpty } from 'lodash-es';
import { FilterOption } from '../../../../models';
import { RuleSetFilterOption } from '../../../../models/rule-set-filter-option';

export class TaxonomyFiltersStateUseCases {
  constructor(private filtersApiService: FiltersApiService) {}

  private loadJurisdictionOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.filtersApiService.getJurisdictionsFilterOptions().pipe(
      tap(response => {
        context.patchState({
          jurisdictionOptions: response.jurisdictions.map(option =>
            FilterOptionMapper.mapToFilterOption(option)
          )
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadOrganizationOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.filtersApiService.getOrganizationsFilterOptions().pipe(
      tap(response => {
        context.patchState({
          organizationOptions: response.organizations.map(option =>
            FilterOptionMapper.mapToFilterOption(option)
          )
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadCategoriesOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.filtersApiService.getCategoryFilterOptions().pipe(
      tap(response => {
        context.patchState({
          categoryOptions: response.categories.map(option =>
            FilterOptionMapper.mapToFilterOption(option)
          )
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadRuleSetOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.filtersApiService.getRuleSetFilterOptions().pipe(
      tap(response => {
        const ruleSets = response.ruleSetFamilies.flatMap(r => r.ruleSets);
        const ruleSetFamilyOptions: FilterOption[] = response.ruleSetFamilies
          .map(FilterOptionMapper.mapRuleSetFamilyToFilterOption)
          .sort((a, b) => this.anyFirstComparator(a.name, b.name));
        const ruleSetOptions: RuleSetFilterOption[] = ruleSets
          .map(FilterOptionMapper.mapRuleSetToFilterOption)
          .sort((a, b) => this.anyFirstComparator(a.name, b.name));
        context.patchState({
          ruleSetFamilyOptions: ruleSetFamilyOptions,
          ruleSetOptions: ruleSetOptions
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadEmployeeOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.filtersApiService.getEmployeesOptions().pipe(
      tap(response => {
        context.patchState({
          employeeOptions: response.options.map(option =>
            FilterOptionMapper.mapToFilterOption(option)
          )
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private anyFirstComparator(name1: string, name2: string): number {
    if (name1 === 'Any') {
      return -1;
    }
    if (name2 === 'Any') {
      return 1;
    }
    return name1.localeCompare(name2);
  }

  private loadUsageRuleSetOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.filtersApiService.getUsageRuleSetFilterOptions().pipe(
      tap(response => {
        const ruleSets = response.ruleSetFamilies.flatMap(r => r.ruleSets);
        const ruleSetFamilyOptions: FilterOption[] = response.ruleSetFamilies
          .map(FilterOptionMapper.mapRuleSetFamilyToFilterOption)
          .sort((a, b) => a.name.localeCompare(b.name));

        const ruleSetOptions: RuleSetFilterOption[] = ruleSets
          .map(FilterOptionMapper.mapRuleSetToFilterOption)
          .sort((a, b) => a.name.localeCompare(b.name));
        context.patchState({
          usageRuleSetFamilyOptions: ruleSetFamilyOptions,
          usageRuleSetOptions: ruleSetOptions
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadUsageTypeGroupOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.filtersApiService.getUsageTypeGroupOptions().pipe(
      tap(response => {
        context.patchState({
          usageTypeGroupOptions: response.usageTypeGroups.map(option =>
            FilterOptionMapper.mapFilterUsageTypeGroupOptionToFilterOption(option)
          )
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadActivationStateOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.filtersApiService.getActivationStateOptions().pipe(
      tap(response => {
        context.patchState({
          activationStateOptions: response.list.map(option =>
            FilterOptionMapper.mapFilterActivationStateOptionToFilterOption(option)
          )
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public loadUsageFilterOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return combineLatest([
      isEmpty(context.getState().usageRuleSetFamilyOptions)
        ? this.loadUsageRuleSetOptions(context)
        : of(context.getState().usageRuleSetFamilyOptions),
      isEmpty(context.getState().organizationOptions)
        ? this.loadOrganizationOptions(context)
        : of(context.getState().organizationOptions),
      isEmpty(context.getState().jurisdictionOptions)
        ? this.loadJurisdictionOptions(context)
        : of(context.getState().jurisdictionOptions),
      isEmpty(context.getState().usageTypeGroupOptions)
        ? this.loadUsageTypeGroupOptions(context)
        : of(context.getState().usageTypeGroupOptions),
      isEmpty(context.getState().activationStateOptions)
        ? this.loadActivationStateOptions(context)
        : of(context.getState().activationStateOptions)
    ]).pipe(
      tap(_response => {
        context.patchState({
          isUsageFiltersLoaded: true
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public loadSearchTermsFilterOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return combineLatest([
      isEmpty(context.getState().ruleSetFamilyOptions)
        ? this.loadRuleSetOptions(context)
        : of(context.getState().ruleSetFamilyOptions),
      isEmpty(context.getState().organizationOptions)
        ? this.loadOrganizationOptions(context)
        : of(context.getState().organizationOptions),
      isEmpty(context.getState().jurisdictionOptions)
        ? this.loadJurisdictionOptions(context)
        : of(context.getState().jurisdictionOptions),
      isEmpty(context.getState().categoryOptions)
        ? this.loadCategoriesOptions(context)
        : of(context.getState().categoryOptions)
    ]).pipe(
      tap(_response => {
        context.patchState({
          isSearchTermsFiltersLoaded: true
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public loadLatestChangesFilterOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return combineLatest([
      isEmpty(context.getState().ruleSetFamilyOptions)
        ? this.loadRuleSetOptions(context)
        : of(context.getState().ruleSetFamilyOptions),
      isEmpty(context.getState().organizationOptions)
        ? this.loadOrganizationOptions(context)
        : of(context.getState().organizationOptions),
      isEmpty(context.getState().jurisdictionOptions)
        ? this.loadJurisdictionOptions(context)
        : of(context.getState().jurisdictionOptions),
      isEmpty(context.getState().categoryOptions)
        ? this.loadCategoriesOptions(context)
        : of(context.getState().categoryOptions),
      isEmpty(context.getState().employeeOptions)
        ? this.loadEmployeeOptions(context)
        : of(context.getState().employeeOptions)
    ]).pipe(
      tap(_response => {
        context.patchState({
          isLatestChangesFiltersLoaded: true
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public loadSearchOnRulesFilterOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return combineLatest([
      isEmpty(context.getState().usageRuleSetFamilyOptions)
        ? this.loadUsageRuleSetOptions(context)
        : of(context.getState().usageRuleSetFamilyOptions),
      isEmpty(context.getState().organizationOptions)
        ? this.loadOrganizationOptions(context)
        : of(context.getState().organizationOptions),
      isEmpty(context.getState().jurisdictionOptions)
        ? this.loadJurisdictionOptions(context)
        : of(context.getState().jurisdictionOptions),
      isEmpty(context.getState().documentActivationStatusOptions)
        ? this.loadDocumentActivationStatusOptions(context)
        : of(context.getState().documentActivationStatusOptions),
      isEmpty(context.getState().documentVersionOptions)
        ? this.loadDocumentVersionOptions(context)
        : of(context.getState().documentVersionOptions),
      isEmpty(context.getState().documentTypeOptions)
        ? this.loadDocumentTypeOptions(context)
        : of(context.getState().documentTypeOptions)
    ]).pipe(
      tap(_response => {
        context.patchState({
          isSearchOnRulesFiltersLoaded: true
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadDocumentActivationStatusOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.filtersApiService.getDocumentActivationStatusOptions().pipe(
      tap(response => {
        context.patchState({
          documentActivationStatusOptions: response.options.map(option =>
            FilterOptionMapper.mapToFilterOption(option)
          )
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadDocumentTypeOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.filtersApiService.getDocumentTypeOptions().pipe(
      tap(response => {
        context.patchState({
          documentTypeOptions: response.documentTypes.map(option =>
            FilterOptionMapper.mapToFilterOption(option)
          )
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadDocumentVersionOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.filtersApiService.getDocumentVersionOptions().pipe(
      tap(response => {
        context.patchState({
          documentVersionOptions: response.versions.map(option =>
            FilterOptionMapper.mapStringToFilterOption(option)
          )
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
