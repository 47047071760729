import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class LoaderService {

    private static readonly GLOBAL_LOADER = 'global';
    private loaders = new Map<string, BehaviorSubject<boolean>>();

    constructor() {
        this.loaders.set(LoaderService.GLOBAL_LOADER, new BehaviorSubject<boolean>(false));
    }

    public startLoading(loader?: string) {
        this.getLoader(loader).next(true);

    }

    public stopLoading(loader?: string) {
        this.getLoader(loader).next(false);
    }

    public getLoader(loader: string) {
        if (!loader) {
            return this.loaders.get(LoaderService.GLOBAL_LOADER);
        }
        const loaderFound = this.loaders.get(loader);
        if (!loaderFound) {
            this.loaders.set(loader, new BehaviorSubject<boolean>(true));
            return this.loaders.get(loader);
        }
        return loaderFound;
    }
}
