import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContentChange, QuillEditorComponent } from 'ngx-quill';
import { ControlContainer, FormControl, FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import Quill from 'quill';
import {
  ConceptSelectionVariablesModalData
} from '../modals/concept-selection-variables/concept-selection-variables.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, first } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ConceptUtils } from '../../../domain/utils/concept.utils';
import { TaxonomyConceptState } from '../../../domain/stores/taxonomy-concept';

@UntilDestroy()
@Component({
  selector: 'app-apx-quill',
  templateUrl: './apx-quill.component.html',
  styleUrls: ['./apx-quill.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ApxQuillComponent implements OnInit {
  @Input() isDisabled: any;
  @Input() controlName: string;
  @Input() isReadOnly: boolean;
  @Input() isRequired: boolean;
  @Input() isSanitize: boolean;
  @Input() showBtnModal: boolean;
  @Input() variableValue: string;
  @Input() conceptUtils: ConceptUtils;

  @ViewChild('definitionEditor')
  definitionInput: QuillEditorComponent;

  private quillEditor: Quill;
  public form: UntypedFormGroup;
  public readonly quillModules = {
    clipboard: {
      matchVisual: false
    }
  };
  internalControl = new FormControl(undefined);
  private markControlAsDirtyOnNextChange = false;

  constructor(
    private controlContainer: ControlContainer,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.form = (this.controlContainer as FormGroupDirective).form;

    this.internalControl.setValue(this.form.get(this.controlName)?.value, {
      onlySelf: true,
      emitEvent: false,
      emitModelToViewChange: true,
      emitViewToModelChange: false
    });

    this.form.get(this.controlName)?.valueChanges
      .pipe(untilDestroyed(this),
        filter(v =>
          this.internalControl.value !== v
        ))
      .subscribe(v => this.internalControl.setValue(v, {
        onlySelf: true,
        emitEvent: false,
        emitModelToViewChange: true,
        emitViewToModelChange: false
      }));
  }

  onDefinitionFocus() {
    this.definitionInput.removeClasses('ql-highlight');
  }

  onAddVariableClicked() {
    const variables = this.store.selectSnapshot(TaxonomyConceptState.variables);

    const data: ConceptSelectionVariablesModalData = {
      variables
    };

    this.conceptUtils
      .openSelectionVariablesModal(data)
      .pipe(untilDestroyed(this), first())
      .subscribe(variable => {
        if (this.quillEditor && variable) {
          this.markControlAsDirtyOnNextChange = true;
          const cursorPosition = this.quillEditor.getSelection(true)?.index || 0;
          this.quillEditor.insertText(cursorPosition, variable.value, 'user');
          this.quillEditor.setSelection(cursorPosition + variable?.value?.length);
        }
      });
  }

  onEditorCreated(quill: Quill) {
    this.quillEditor = quill;
  }

  change(_$event: ContentChange) {
    const quillContent = this.definitionInput.editorElem.querySelector('.ql-editor')?.innerHTML;
    this.internalControl.setValue(quillContent, {
      onlySelf: true,
      emitEvent: false,
      emitModelToViewChange: false,
      emitViewToModelChange: false
    });
    this.form.get(this.controlName)?.setValue(quillContent);
    if (this.markControlAsDirtyOnNextChange) {
      this.form.get(this.controlName)?.markAsDirty();
    }
  }

  focusIn(_$event: FocusEvent) {
    this.markControlAsDirtyOnNextChange = true;
  }
}
