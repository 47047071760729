<apx-template-modal [data]='templateData' [template]='modalTemplate'>
  <ng-template #modalTemplate>
    <apx-loading-spinner [isLoading]='isLoading$'>
      <mat-dialog-content class='outdated-concept-modal-wrapper'>
        <span>We could not save your changes because someone already created a newer version of the concept.
            Please reload the newer concept version and apply your changes there.</span>
      </mat-dialog-content>
      <mat-dialog-actions class='outdated-concept-modal-actions'>
        <button class='btn-action secondary-action' (click)='onCancel()'>
          Cancel
        </button>
        <button class='btn-action primary-action' (click)="onConfirmation()">
          Reload Concept
        </button>
      </mat-dialog-actions>
    </apx-loading-spinner>
  </ng-template>
</apx-template-modal>
