import { SearchCriteria } from '@apiax/web-commons';

export enum TaxonomySearchOnRulesAction {
  SearchOnRules = '[Search on rules] perform a search for concepts based on rule criteria',
  SearchOnRulesExport = '[Search on rules Export] perform a export for concepts based on rule criteria'
}

export class SearchOnRules {
  public static readonly type = TaxonomySearchOnRulesAction.SearchOnRules;

  constructor(public searchCriteria: SearchCriteria) {}
}

export class SearchOnRulesExport {
  public static readonly type = TaxonomySearchOnRulesAction.SearchOnRulesExport;

  constructor(public searchCriteria: SearchCriteria) {}
}
