import { ConceptLLM } from '../../../models/concept-llm';
export enum ConceptLlmAction {
  GetConceptLLM = '[Load LLM Term Definitions for a concept] Loads LLM Term Definitions for a given concept',
  UpsertConceptLLM = '[Upsert LLM Term Definition for a concept] Upsert LLM Term Definitions for a given concept',
  LoadAvailableLLMOptions = '[Load LLM Options] Loads LLM Provider Options',
  UpdateConceptLLMLocally = '[Update LLM Term Definition for a concept locally] Updates LLM Term Definitions for the concept locally',
  ClearConceptLLMState = '[Clear Concept LLM State] Clear conceptLLm state',
}

export class GetConceptLLM {
  public static readonly type = ConceptLlmAction.GetConceptLLM;

  constructor(public conceptId: string) {}
}

export class UpsertConceptLLM {
  public static readonly type = ConceptLlmAction.UpsertConceptLLM;

  constructor(
    public payload: {
      conceptId: string;
      conceptLLMList: ConceptLLM[];
    }
  ) {}
}

export class LoadAvailableLLMOptions {
  public static readonly type = ConceptLlmAction.LoadAvailableLLMOptions;

  constructor(public organizationId: string) {}
}

export class UpdateConceptLLMLocally {
  public static readonly type = ConceptLlmAction.UpdateConceptLLMLocally;

  constructor(
    public payload: {
      conceptLLMList: ConceptLLM[];
    }
  ) {}
}

export class ClearConceptLLMState {
  public static readonly type = ConceptLlmAction.ClearConceptLLMState;
}
