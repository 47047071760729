<apx-template-modal [data]='templateData' [template]='modalTemplate'>
  <ng-template #modalTemplate>
    <apx-loading-spinner [isLoading]='isLoading$'>
      <mat-dialog-content class='concept-jurisdictions-modal-wrapper'>
        <div class='concept-jurisdictions-modal-search'>
          <app-search-input [placeholder]="'Search Jurisdictions'" (valueChange)="onSearch($event)">
          </app-search-input>
        </div>
        <div class='concept-jurisdictions-modal-content'>
          <div class="table-container">
            <table mat-table matSort
                [dataSource]="dataSource"
                [matSortActive]="'selected'"
                [matSortDirection]="'asc'">
              <ng-container matColumnDef="selected">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="select-all">
                  <mat-checkbox [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      (change)="$event ? toggleAll() : null"
                      (click)="$event.stopPropagation()">
                  </mat-checkbox>
                  Selected
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox [checked]="selection.isSelected(row)"
                      (change)="onSelectionChange($event, row)"
                      (click)="$event.stopPropagation()">
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let row">
                  {{row.name}}
                </td>
              </ng-container>
              <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
                <td mat-cell *matCellDef="let row">
                  {{row.alpha3}}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: columns;"></tr>
            </table>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions class='concept-jurisdictions-modal-actions'>
        <div>
          <span class="foot-note">{{footNote}}</span>
        </div>
        <div>
          <button class='btn-action secondary-action'
              (click)='onCancel()'>
            Cancel
          </button>
          <button class='btn-action primary-action'
              (click)="onConfirmation()">
            Confirm
          </button>
        </div>
      </mat-dialog-actions>
    </apx-loading-spinner>
  </ng-template>
</apx-template-modal>
