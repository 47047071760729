import { GetConceptLLM } from '../../../generated/v3';
import { ConceptLLM } from '../../models/concept-llm';

export class ConceptLLMMapper {
  static mapToConceptLLM({ term, definition, llms }: GetConceptLLM): ConceptLLM {
    return {
      definition,
      term,
      llms
    };
  }
}
