import { ConceptRelation } from '../../models/concept-relation';
import {
  AddedConceptRelation,
  GetConceptRelation,
  UpdateConceptRelation
} from '../../../generated/v3';
import ConceptRelationTypeEnum = UpdateConceptRelation.ConceptRelationTypeEnum;
import { AddedConceptRelationModel } from '../../models/added-concept-relation-model';
import { ConceptRelationType } from '../../models/concept-relation-type';

export class TaxonomyRelationsMapper {
  static convertToRelations(relations: GetConceptRelation[]): ConceptRelation[] {
    return relations.map(r => {
      let type;
      switch (r.conceptRelationType) {
        case 'RELATED':
          type = ConceptRelationType.RELATED;
          break;
        case 'BROADER':
          type = ConceptRelationType.BROADER;
          break;
        case 'NARROWER':
          type = ConceptRelationType.NARROWER;
          break;
      }
      return {
        relationId: r.relationId,
        targetConceptId: r.targetConceptId,
        targetConceptJurisdictions: r.targetConceptJurisdictions,
        targetConceptOwnerName: r.targetConceptOwnerName,
        targetConceptTerm: r.targetConceptTerm,
        targetConceptTermId: r.targetConceptTermId,
        relationType: type
      };
    });
  }

  static convertToUpdateConceptRelation(
    conceptNarrowerRelations: ConceptRelation[],
    conceptBroaderRelations: ConceptRelation[],
    conceptRelatedRelations: ConceptRelation[]
  ): UpdateConceptRelation[] {
    return [
      ...conceptBroaderRelations.map(r => {
        return {
          relationId: r.relationId,
          targetConceptId: r.targetConceptId,
          conceptRelationType: ConceptRelationTypeEnum.BROADER
        };
      }),
      ...conceptNarrowerRelations.map(r => {
        return {
          relationId: r.relationId,
          targetConceptId: r.targetConceptId,
          conceptRelationType: ConceptRelationTypeEnum.NARROWER
        };
      }),
      ...conceptRelatedRelations.map(r => {
        return {
          relationId: r.relationId,
          targetConceptId: r.targetConceptId,
          conceptRelationType: ConceptRelationTypeEnum.RELATED
        };
      })
    ];
  }

  static convertToAddedConceptRelations(
    addedConceptRelations: AddedConceptRelation[]
  ): AddedConceptRelationModel[] {
    if (!addedConceptRelations) {
      return [];
    }
    return addedConceptRelations.map(r => {
      let type;
      switch (r.conceptRelationType) {
        case 'RELATED':
          type = ConceptRelationType.RELATED;
          break;
        case 'BROADER':
          type = ConceptRelationType.BROADER;
          break;
        case 'NARROWER':
          type = ConceptRelationType.NARROWER;
          break;
      }
      return {
        relationId: r.relationId,
        targetConceptId: r.targetConceptId,
        relationType: type
      };
    });
  }
}
