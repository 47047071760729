<div class="term-editor-wrapper">
  <apx-loading-spinner [isLoading]="isLoading$">
    <form [formGroup]="form">
      <div class="single-field">
        <apx-country-slider
          [control]="form.get(formNames.Slider)"
          [options]="languageOptions"
          [selectAddButton]="selectSliderAdd"
          [showAddButton]="taxonomyUtilsService.canUpdateConcept()"
          (plusClicked)="openTranslationLanguageModal()"
        ></apx-country-slider>
      </div>
      <div class="multiple-fields">
        <apx-simple-input [control]="form.get(formNames.Term)" [placeholder]="'Term'"> </apx-simple-input>
        <apx-simple-input [control]="form.get(formNames.TermId)" [placeholder]="'Term ID'"> </apx-simple-input>
        <apx-template-picker
          [control]="form.get(formNames.Owner)"
          [placeholder]="'Owner'"
          [label]="'Owner'"
          [removeSelection]="true"
          [isJurisdiction]="false"
          [defaultIcon]="'business'"
          [availableOptions$]="availableOwners$"
        ></apx-template-picker>
        <apx-chips-with-addition
          [control]="form.get(formNames.RuleSets)"
          [placeholder]="'Rule Sets'"
          [removable]="true"
          [entityPlural]="'Rule Sets'"
          [additionalInfo]="ruleSetsAdditionalInfo"
          [additionalInfoTooltip]="ruleSetsAdditionalInfoTooltip"
        >
        </apx-chips-with-addition>
        <apx-chips-with-addition
          [control]="form.get(formNames.Jurisdictions)"
          [placeholder]="'Jurisdictions'"
          [removable]="true"
          [entityPlural]="'Jurisdictions'"
        >
        </apx-chips-with-addition>
        <apx-chips
          [control]="form.get(formNames.Categories)"
          [removable]="true"
          [placeholder]="'Categories'"
          [onlyRemovedOnes]="false"
        >
        </apx-chips>
        <apx-template-picker
          [control]="form.get(formNames.Privacy)"
          [placeholder]="'Privacy'"
          [label]="'Privacy'"
          [removeSelection]="false"
          [isJurisdiction]="false"
          [iconCode]="'info'"
          [iconTooltip]="privacyTooltip"
          [availableOptions$]="availablePrivacyValues$"
        ></apx-template-picker>
        <apx-template-picker
          [control]="form.get(formNames.Status)"
          [placeholder]="'Status'"
          [label]="'Status'"
          [removeSelection]="false"
          [isJurisdiction]="false"
          [iconCode]="'info'"
          [iconTooltip]="statusTooltip"
          [availableOptions$]="availableStatusValues$"
        ></apx-template-picker>
        <apx-simple-input
          [control]="form.get(formNames.Id)"
          [placeholder]="'ID'"
          [callbackIcon]="'copy'"
          [callbackTooltip]="'Copy ID'"
          (callbackAction)="copyID()"
        >
        </apx-simple-input>
      </div>
      <div class="single-field">
        <app-apx-quill
          [conceptUtils]="conceptUtils"
          [isDisabled]="form.get(formNames.Definition).disabled"
          [controlName]="formNames.Definition"
          [isReadOnly]="form.get(formNames.Definition).disabled"
          [isRequired]="true"
          [isSanitize]="true"
          [showBtnModal]="true"
        ></app-apx-quill>
      </div>
      <div class="actions-wrapper">
        <apx-action-button
          *ngIf="taxonomyUtilsService.canUpdateConcept()"
          [colorClass]="'secondary-action critical'"
          [loadingName]="'Deleting..'"
          [name]="'Delete this language'"
          [method]="onDeleteTranslation()"
        >
        </apx-action-button>
        <apx-action-button
          *ngIf="taxonomyUtilsService.canUpdateConcept()"
          [enabled]="form.valid && form.dirty"
          [colorClass]="'primary-action'"
          [loadingName]="'Updating...'"
          [name]="'Update term'"
          [method]="onSubmit()"
        >
        </apx-action-button>
      </div>
    </form>
  </apx-loading-spinner>
</div>
