import { FilterData, SearchCriteria, StorageData } from '@apiax/web-commons';
import { StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConceptApiService } from '../../../../../generated/v3';
import { ConceptSearchMode, TaxonomySearchTermsMapper } from '../../../mappers/taxonomy-search-terms-mapper';
import { TaxonomyMyTermsStateModel } from '../taxonomy-my-terms.state';
import { FileUtils } from '../../../utils/file.utils';

export class TaxonomySearchTermsStateUseCases {
  constructor(private taxonomyV3ApiService: ConceptApiService) {
  }

  public setSearchMode(
    context: StateContext<TaxonomyMyTermsStateModel>,
    searchMode: ConceptSearchMode
  ) {
    context.patchState({
      searchMode: searchMode
    });
  }

  // Search

  public search(
    context: StateContext<TaxonomyMyTermsStateModel>,
    searchCriteria: SearchCriteria
  ) {
    const searchMode = context.getState().searchMode;
    context.patchState({
      isLoadingSearch: true
    });

    switch (searchMode) {
      case ConceptSearchMode.Standard:
        return this.standardSearch(searchCriteria, context);
      case ConceptSearchMode.Exact:
        return this.exactSearch(searchCriteria, context);
    }
  }

  private standardSearch(
    searchCriteria: SearchCriteria,
    context: StateContext<TaxonomyMyTermsStateModel>
  ) {
    return this.taxonomyV3ApiService
      .standardSearch(TaxonomySearchTermsMapper.mapToStandardSearchRequest(searchCriteria))
      .pipe(
        tap(response => {

          context.patchState({
            searchResults: response.list.map(result =>
              TaxonomySearchTermsMapper.mapToConceptSearchResult(result)
            ),
            isLoadingSearch: false,
            totalResults: response.total,
            searchCriteria: searchCriteria
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  private exactSearch(
    searchCriteria: SearchCriteria,
    context: StateContext<TaxonomyMyTermsStateModel>
  ) {
    return this.taxonomyV3ApiService
      .exactSearch(TaxonomySearchTermsMapper.mapToExactSearchRequest(searchCriteria))
      .pipe(
        tap(response => {
          context.patchState({
            searchResults: response.list.map(result =>
              TaxonomySearchTermsMapper.mapToConceptSearchResult(result)
            ),
            isLoadingSearch: false,
            totalResults: response.total,
            searchCriteria: searchCriteria
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  // Export

  public export(
    context: StateContext<TaxonomyMyTermsStateModel>,
  ) {
    const searchMode = context.getState().searchMode;
    switch (searchMode) {
      case ConceptSearchMode.Standard:
        return this.standardExport(context);
      case ConceptSearchMode.Exact:
        return this.exactExport(context);
    }
  }

  public setStorageData(
    context: StateContext<TaxonomyMyTermsStateModel>,
    storageData: StorageData
  ) {
    context.patchState({
      storageData
    });
  }

  public setFilterStorageData(context: StateContext<TaxonomyMyTermsStateModel>, storageData: Map<string, FilterData>) {
    // web-commons is returning a Map<string, FilterData> however NGXS does not support MAP. So we need to convert it to object
    context.patchState({
      filterStorageData: Object.fromEntries(storageData)
    });
  }

  private standardExport(
    context: StateContext<TaxonomyMyTermsStateModel>
  ) {
    return this.taxonomyV3ApiService
      .standardExport(TaxonomySearchTermsMapper.mapToStandardExportRequest(context.getState().searchCriteria))
      .pipe(
        tap(response => {
          FileUtils.downloadFile(response, 'termsList');
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  private exactExport(
    context: StateContext<TaxonomyMyTermsStateModel>
  ) {
    return this.taxonomyV3ApiService
      .exactExport(TaxonomySearchTermsMapper.mapToExactExportRequest(context.getState().searchCriteria))
      .pipe(
        tap(response => {
          FileUtils.downloadFile(response, 'termsList');
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
