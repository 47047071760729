import { AppService } from './app.service';
import { AuthorizationService } from './authorization.service';
import { ConsoleService } from './console.service';
import { LogService } from './log.service';
import { PhotoService } from './photo.service';
import { RouterExtensions } from './router-extensions.service';
import { StorageManagerService } from './storage-manager.service';
import { WindowService } from './window.service';

// app
export const CORE_PROVIDERS: any[] = [
  AppService,
  AuthorizationService,
  ConsoleService,
  LogService,
  PhotoService,
  RouterExtensions,
  StorageManagerService,

  WindowService
];

export * from './app.service';
export * from './authorization.service';
export * from './console.service';
export * from './log.service';
export * from './router-extensions.service';
export * from './window.service';
export * from './storage-manager.service';
export * from './photo.service';
