<div class="taxonomy-change-log-wrapper">
  <apx-loading-spinner [isLoading]="isLoading$">
    <ng-container *ngIf="conceptChangeLogByDay">
      <div class="changelog-wrapper">
        <div class="content-wrapper">
          <ng-template [ngForOf]="conceptChangeLogByDay.days" let-changeLogDay ngFor>
            <div class="changelog-date-wrapper">
              <span>{{ changeLogDay.day | date: 'fullDate' }}</span>
              <div class="changelog-container">
                <ng-template [ngForOf]="changeLogDay.entries" let-changeLog ngFor>
                  <div class="changelog-row">
                    <div class="changelog-timestamp">
                      <span>{{ changeLog.timestamp * 1000 | date: 'shortTime' }}</span>
                    </div>
                    <div class="changelog-detail" data-hj-suppress>
                      <div class="changelog-detail-info ql-editor ql-view">
                        <span [innerHTML]="replaceMessage(changeLog) | safeHtml"></span>
                        <span
                          *ngIf="hasMoreDetails(changeLog)"
                          class="link-action"
                          [class.hide]="changeLog.expanded"
                          [class.show]="!changeLog.expanded"
                          (click)="toggleDetails(changeLog)"
                          >{{ changeLog.expanded ? 'Hide details' : 'See details' }}
                        </span>
                      </div>
                      <div *ngIf="changeLog.expanded" class="changelog-detail-additional">
                        <div *ngFor="let childChange of changeLog.detailChanges | keyvalue">
                          <span class="detail-field">{{ childChange.key | titlecase }}:&nbsp;</span>
                          <span class="ql-editor ql-view detail-value break-line" [innerHTML]="childChange.value | safeHtml"> </span>
                        </div>
                      </div>
                    </div>
                    <div class="changelog-info">
                      <ng-template [ngForOf]="changeLog.tags" let-changeLogTag ngFor>
                        <span>{{ changeLogTag | titlecase }}</span>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-template>
          <ng-container *ngIf="previewOnly | async">
            <app-teaser-modal
              [topPosition]="75"
              [title]="'Change Log Feature'"
              [message]="
                'Currently your subscription does not cover this Audit service. Take control and keep track of term changes and user activity. Contact us to learn more.'
              "
            >
            </app-teaser-modal>
            <app-changelog-skeleton-entry *ngFor="let width of [350, 450, 350]" [messageWidth]="width">
            </app-changelog-skeleton-entry>
            <span class="skeleton-new-day"></span>
            <app-changelog-skeleton-entry *ngFor="let width of [350, 450, 350, 550, 350]" [messageWidth]="width">
            </app-changelog-skeleton-entry>
          </ng-container>

          <ng-container *ngIf="conceptChangeLogByDay.days.length === 0">
            <div class="noResultsMessage">
              <img src="common-assets/common-img/no-results.svg" alt="no results" />
              <span>No changes found</span>
            </div>
          </ng-container>
          <button
            *ngIf="conceptChangeLogByDay.hasMore && !(previewOnly | async)"
            mat-mini-fab
            (click)="loadMore()"
            matTooltip="Load More"
          >
            <mat-icon>get_app</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </apx-loading-spinner>
</div>
