import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../../models/user';

export enum Activity {
  // Platform Access Activities
  PlatformAccess = 'Platform Access',
  TaxonomyAccess = 'Taxonomy Access',
  CompaniesManagement = 'Companies Management',

  // Taxonomy
  TaxonomyManagement = 'Taxonomy Management',
  ViewTerms = 'View Terms',
  EditTerms = 'Edit Terms',
  ViewInactiveTerms = 'View Inactive Terms',
  InactivateTerms = 'Inactivate Terms',
  TaxonomyLatestChanges = 'Taxonomy Latest Changes',
  TechnicalAdministration = 'Technical Administration',
  ExportData = 'Export data',

  // Misc Activities
  AllCompaniesView = 'All Companies View',
  ChangeLogAccess = 'Change Log Access',
  LLMTermDefinitions = 'LLM term definitions',
  MyCollectionsAccess = 'My Collections Access'
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  public currentUser: BehaviorSubject<User> = new BehaviorSubject(null);

  public static getUserActivities(user: User): Activity[] {
    if (!user) {
      return [];
    }
    return [].concat(...user.activities.map(gr => gr));
  }

  public static getUserPermissions(user: User): Map<Activity, boolean> {
    const activities = AuthorizationService.getUserActivities(user) || [];
    return AuthorizationService.buildPermissionsFromActivities(activities);
  }

  private static buildPermissionsFromActivities(activities = []) {
    const permissions = new Map<Activity, boolean>();
    for (const activity of Object.values(Activity)) {
      if (!Number(activity)) {
        permissions.set(activity, activities ? activities.some(a => a === activity) : false);
      }
    }
    return permissions;
  }

  constructor(
    private keycloakService: KeycloakService,
    private localStorage: LocalStorageService
  ) {}

  public get currentUserPermissions() {
    return this.currentUser.pipe(map(user => AuthorizationService.getUserPermissions(user)));
  }

  public logout() {
    this.localStorage.store('kc-logout', 'true');
    setTimeout(() => this.localStorage.clear('kc-logout'));
    this.keycloakService.logout();
  }

  public isAllowed(activity: Activity | Activity[] = []): Observable<boolean> {
    const activities = Array.isArray(activity) ? activity : [activity];
    return this.currentUserPermissions.pipe(
      map(permissions =>
        activities && activities.length > 0 ? activities.some(a => permissions.get(a)) : true
      )
    );
  }
}
