export interface ConceptChangeLog {
  id: string;
  conceptId?: string;
  timestamp?: number;
  commandText?: string;
  commandName?: string;
  tags?: string[];
  userName?: string;
  userOrganizationName?: string;
  oldValue?: string;
  newValue?: string;
  language?: string;
  expanded?: boolean;
  detailChanges: Map<string, string>;
}

export interface ChangeLogResultByDay {
  days?: ChangeLogDayEntry[];
  hasMore?: boolean;
}

export interface ChangeLogDayEntry {
  day?: string;
  entries?: ConceptChangeLog[];
}

export enum CommandEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  ADD = 'ADD',
  REMOVE = 'REMOVE'
}
