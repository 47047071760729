import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { TaxonomySearchOnRulesState } from '../../../../domain/stores/taxonomy-search-on-rules/taxonomy-search-on-rules-state.service';
import { TaxonomySearchOnRulesComponent } from './taxonomy-search-on-rules.component';
import {
  ActionButtonModule,
  DataTableModule,
  LoadingSpinnerModule,
  WebCommonsModule
} from '@apiax/web-commons';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [TaxonomySearchOnRulesComponent],
  exports: [TaxonomySearchOnRulesComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([TaxonomySearchOnRulesState]),
    ActionButtonModule,
    LoadingSpinnerModule,
    MatTabsModule,
    DataTableModule,
    WebCommonsModule
  ]
})
export class TaxonomySearchOnRulesModule {}
