import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoadVariables, TaxonomyConceptState } from '../stores/taxonomy-concept';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyVariablesResolver  {
  constructor(private store: Store) {}

  resolve(): Observable<void> {
    const variables = this.store.selectSnapshot(TaxonomyConceptState.variables);
    if(variables.length === 0) {
      return this.store.dispatch(new LoadVariables());
    }
  }
}
