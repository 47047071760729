import { PhotoService } from 'shared/core';

export class PhotoDAO {

  constructor(
    private photoService: PhotoService
  ) {
  }

  public getPhotoUrl(photoId: string): string {
    return photoId ? this.photoService.getUrl(photoId) : null;
  }

}
