import { isEqual } from 'lodash-es';
import { AccessibleOwner, AccessibleRuleSetFamilyLabel, AccessibleRuleSets } from '../../../generated/v3';
import { Jurisdiction } from '../../models/jurisdiction';
import { Owner } from '../../models/owner';
import { ProductRuleSet } from '../../models/product-rule-set';
import { RuleSet } from '../../models/rule-set';
import { RuleSetFamily } from '../../models/rule-set-family';

export class OrganizationsMapper {

  static apiaxCompanyID = '5a670b06a54c9d0004e97253';

  static isApiaxCompany(value: string) {
    return OrganizationsMapper.apiaxCompanyID === value;
  }

  static mapToOwner(generatedModel: AccessibleOwner): Owner {
    return {
      id: generatedModel.id,
      name: generatedModel.name,
      photoId: generatedModel.photoId
    };
  }

  static mapToProductRuleSet(generatedModel: AccessibleRuleSets, ruleSetLabels: AccessibleRuleSetFamilyLabel[], jurisdictionLabels: {
    [key: string]: string;
  }): ProductRuleSet {
    return {
      ruleSetFamily: this.mapToRuleSetFamily(generatedModel.ruleSetFamilyId, ruleSetLabels),
      ruleSet: this.mapToRuleSet(generatedModel.ruleSetFamilyId, generatedModel.ruleSetId, ruleSetLabels),
      jurisdictions: generatedModel.jurisdictions.map(jurisdiction => this.mapToJurisdiction(jurisdiction, jurisdictionLabels))
    };
  }

  static mapToRuleSetFamily(ruleSetFamilyId: string, ruleSetLabels: AccessibleRuleSetFamilyLabel[]): RuleSetFamily {
    return {
      id: ruleSetFamilyId,
      label: ruleSetLabels.find(obj => isEqual(obj.id, ruleSetFamilyId)).label
    };
  }

  static mapToRuleSet(ruleSetFamilyId: string, ruleSetId: string, ruleSetLabels: AccessibleRuleSetFamilyLabel[]): RuleSet {
    const ruleSetFamily = ruleSetLabels.find(obj => isEqual(obj.id, ruleSetFamilyId));
    return {
      id: ruleSetId,
      label: ruleSetFamily.ruleSets.find(obj => isEqual(obj.id, ruleSetId)).label
    };
  }

  static mapToJurisdiction(generatedModel: string, jurisdictionLabels: { [key: string]: string; }): Jurisdiction {
    return {
      name: jurisdictionLabels[generatedModel],
      alpha3: generatedModel,
      code: null
    };
  }

}
