import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TaxonomyLatestChangesStateUseCases } from './usecases/taxonomy-latest-changes-usecases.state';
import { SearchCriteria } from '@apiax/web-commons';
import { LatestChange } from '../../../models/latest-change';
import { LatestChangeApiService } from '../../../../generated/v3';
import { ExportLatestChanges, SearchLatestChanges } from './taxonomy-latest-changes.action';

export interface TaxonomyLatestChangesStateModel {
  latestChangesSearchResults: LatestChange[];
  latestChangesTotalResults: number;
  isLoadingLatestChangesSearch: boolean;
  latestChangesSearchCriteria: SearchCriteria;
}

export const DEFAULT_STATE: TaxonomyLatestChangesStateModel = {
  latestChangesSearchResults: [],
  latestChangesTotalResults: undefined,
  isLoadingLatestChangesSearch: false,
  latestChangesSearchCriteria: undefined
};

@State<TaxonomyLatestChangesStateModel>({
  name: 'taxonomyLatestChanges',
  defaults: DEFAULT_STATE
})
@Injectable()
export class TaxonomyLatestChangesState {
  private taxonomyLatestChangesStateUseCases: TaxonomyLatestChangesStateUseCases;

  @Selector()
  public static latestChangesSearchResults(state: TaxonomyLatestChangesStateModel): LatestChange[] {
    return state.latestChangesSearchResults;
  }

  @Selector()
  public static isLoadingLatestChangesSearch(state: TaxonomyLatestChangesStateModel): boolean {
    return state.isLoadingLatestChangesSearch;
  }

  @Selector()
  public static latestChangesTotalResults(state: TaxonomyLatestChangesStateModel): number {
    return state.latestChangesTotalResults;
  }

  @Selector()
  public static latestChangesSearchCriteria(
    state: TaxonomyLatestChangesStateModel
  ): SearchCriteria {
    return state.latestChangesSearchCriteria;
  }

  constructor(latestChangeApiService: LatestChangeApiService) {
    this.taxonomyLatestChangesStateUseCases = new TaxonomyLatestChangesStateUseCases(
      latestChangeApiService
    );
  }

  @Action(SearchLatestChanges, { cancelUncompleted: true })
  public searchLatestChanges(
    context: StateContext<TaxonomyLatestChangesStateModel>,
    action: SearchLatestChanges
  ) {
    return this.taxonomyLatestChangesStateUseCases.search(context, action);
  }

  @Action(ExportLatestChanges)
  public export(
    context: StateContext<TaxonomyLatestChangesStateModel>,
    action: ExportLatestChanges
  ) {
    return this.taxonomyLatestChangesStateUseCases.export(action, context);
  }
}
