import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ActionInput,
  Column,
  DataTableComponent,
  DataTableSearchMetadata,
  Filter,
  NotificationService,
  RowIcon,
  SearchCriteria
} from '@apiax/web-commons';
import { FilterOption } from '../../../../models';
import { isEmpty, isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { TaxonomyFiltersState } from '../../../../domain/stores/taxonomy-filters/taxonomy-filters.state';
import { first, map } from 'rxjs/operators';
import { LoadLatestChangesFilterOptions } from '../../../../domain/stores/taxonomy-filters/taxonomy-filters.action';
import { Store } from '@ngxs/store';
import { RuleSetFilterOption } from '../../../../models/rule-set-filter-option';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Activity, WindowService } from 'shared/core';
import { TaxonomyUtilsService } from '../../../../domain/services/taxonomy-utils.service';
import { LatestChange } from '../../../../models/latest-change';
import { TaxonomyLatestChangesState } from '../../../../domain/stores/taxonomy-latest-changes/taxonomy-latest-changes.state';
import {
  ExportLatestChanges,
  SearchLatestChanges
} from '../../../../domain/stores/taxonomy-latest-changes/taxonomy-latest-changes.action';
import { formatDate } from '@angular/common';
import { FilterUtils } from '../../../../domain/utils/filter.utils';

@UntilDestroy()
@Component({
  selector: 'app-taxonomy-latest-changes',
  templateUrl: './taxonomy-latest-changes.component.html',
  styleUrls: ['./taxonomy-latest-changes.component.scss']
})
export class TaxonomyLatestChangesComponent implements OnInit {
  public readonly PAGE_SIZE = 10;

  @ViewChild('table')
  table: DataTableComponent<any>;

  public filters: Filter[];
  public columns: Column[];
  public search: DataTableSearchMetadata = {
    width: '568px',
    defaultText: '',
    placeholder: 'Search term'
  };

  public searchResults: LatestChange[];
  public totalResults: number;
  public page: number;
  public isDownloading$ = new BehaviorSubject(false);
  public downloadPermission = Activity.TaxonomyLatestChanges;
  public rowIcon: RowIcon;
  isLoading$: Observable<boolean>;
  idFetcher = obj => obj.id;

  constructor(
    public taxonomyUtilsService: TaxonomyUtilsService,
    private store: Store,
    private notificationService: NotificationService,
    private window: WindowService
  ) {
    this.isLoading$ = this.store.select(TaxonomyFiltersState.isLatestChangesFiltersLoaded).pipe(
      untilDestroyed(this),
      map(value => !value)
    );
    this.buildColumns();
  }

  ngOnInit(): void {
    this.loadFilterOptions();
    this.buildRowIcon();
    this.initSubscriptions();
  }

  private buildRowIcon() {
    this.rowIcon = this.buildOpenConcept();
  }

  private buildOpenConcept(): RowIcon {
    return {
      permissions: [],
      icon: 'open_in_new',
      iconClass: 'material-icons-outlined',
      tooltip: 'Open in a new tab',
      actionFunction: (input: ActionInput) => {
        this.openConcept(input.rowId);
        return of(true);
      }
    };
  }

  private openConcept(id: string) {
    const record = this.searchResults.find(r => r.id === id);
    return this.window.open(`/concept/term-editor?conceptId=${record.conceptId}`, '_blank');
  }

  private initSubscriptions() {
    this.store.select(TaxonomyLatestChangesState.latestChangesSearchResults)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.searchResults = results));

    this.store.select(TaxonomyLatestChangesState.latestChangesTotalResults)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.totalResults = results));
  }

  private formatUTC(
    value: string | number | Date,
    format: string = 'd MMM y h:mm a z',
    locale = 'en-US'
  ) {
    return value ? formatDate(value, format, locale, 'UTC').replace('GMT+0', 'UTC') : '-';
  }

  private buildColumns() {
    this.columns = [
      {
        prop: 'timestamp',
        label: 'Date & time',
        transformValue: value => this.formatUTC(value),
        sortable: false,
        size: 2
      },
      {
        prop: 'userName',
        label: 'Employee',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'term',
        label: 'Term',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'termId',
        label: 'Term ID',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'changes',
        label: 'Changes',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        tooltip: value => (!isEmpty(value) ? value.replace(/<[^>]*>?/gm, '') : '-'),
        sortable: false,
        size: 8
      },
      {
        prop: 'rowIcon',
        label: '',
        transformValue: value => value,
        tooltip: value => value,
        extraClasses: 'center-aligned',
        sortable: false,
        size: 0.2
      }
    ];
  }

  private loadFilterOptions() {
    if (!this.store.selectSnapshot(TaxonomyFiltersState.isLatestChangesFiltersLoaded)) {
      this.store.dispatch(new LoadLatestChangesFilterOptions())
        .pipe(first())
        .subscribe(() => this.buildFilters());
    } else {
      this.buildFilters();
    }
  }

  private buildFilters() {
    combineLatest([
      this.store.select(TaxonomyLatestChangesState.latestChangesSearchCriteria),
      this.store.select(TaxonomyFiltersState.jurisdictionOptions),
      this.store.select(TaxonomyFiltersState.organizationOptions),
      this.store.select(TaxonomyFiltersState.ruleSetFamilyOptions),
      this.store.select(TaxonomyFiltersState.ruleSetOptions),
      this.store.select(TaxonomyFiltersState.categoryOptions),
      this.store.select(TaxonomyFiltersState.employeeOptions)
    ])
      .pipe(first())
      .subscribe(
        ([
          searchCriteria,
          jurisdictions,
          organizations,
          ruleSetFamilies,
          ruleSets,
          categories,
          employees
        ]) => {
          const searchCriteriaAux = searchCriteria as SearchCriteria;
          const jurisdictionsAux = jurisdictions as FilterOption[];
          const organizationsAux = organizations as FilterOption[];
          const ruleSetFamiliesAux = ruleSetFamilies as FilterOption[];
          const ruleSetsAux = ruleSets as RuleSetFilterOption[];
          const categoriesAux = categories as FilterOption[];
          const employeesAux = employees as FilterOption[];

          if (searchCriteriaAux && searchCriteriaAux.searchFilter) {
            this.search = {
              defaultText: searchCriteriaAux.searchFilter,
              placeholder: this.search.placeholder,
              width: this.search.width
            };
            this.page = searchCriteriaAux.page;
          }
          const ruleSetFamiliesFilter = FilterUtils.buildRuleSetFamiliesFilter(
            ruleSetFamiliesAux,
            searchCriteriaAux
          );
          const ruleSetsFilter = FilterUtils.buildRuleSetsFilter(ruleSetsAux, searchCriteriaAux);
          this.initReactRuleSetToRuleSetFamiliesChanges(
            ruleSetFamiliesFilter,
            ruleSetsAux,
            searchCriteriaAux,
            ruleSetsFilter
          );
          const hasViewInactiveAccess = this.taxonomyUtilsService.currentUserPermissions.get(
            Activity.ViewInactiveTerms
          );
          this.filters = [
            FilterUtils.buildDateRangeFilter(searchCriteriaAux),
            ruleSetFamiliesFilter,
            ruleSetsFilter,
            FilterUtils.buildCategoriesFilter(categoriesAux, searchCriteriaAux),
            FilterUtils.buildJurisdictionsFilter(jurisdictionsAux, searchCriteriaAux),
            FilterUtils.buildOrganizationFilter(organizationsAux, searchCriteriaAux),
            FilterUtils.buildStatusFilter(searchCriteriaAux, hasViewInactiveAccess),
            FilterUtils.buildEmployeesFilter(employeesAux, searchCriteriaAux)
          ];
        }
      );
  }

  private initReactRuleSetToRuleSetFamiliesChanges(
    ruleSetFamiliesFilter: Filter,
    ruleSets: RuleSetFilterOption[],
    searchCriteria: SearchCriteria,
    ruleSetsFilter: Filter
  ) {
    ruleSetFamiliesFilter.selectionChange$
      .pipe(untilDestroyed(this))
      .subscribe(selectedRuleSetFamilyFilterOptions => {
        const selectedRuleSetFamilies = isEmpty(selectedRuleSetFamilyFilterOptions)
          ? ruleSets
          : ruleSets.filter(rs =>
              selectedRuleSetFamilyFilterOptions.find(
                selectedRuleSetFamily => selectedRuleSetFamily.value === rs.ruleSetFamilyId
              )
            );
        const ruleSetsFilterAux = FilterUtils.buildRuleSetsFilter(
          selectedRuleSetFamilies,
          searchCriteria
        );
        if (!isEqual(ruleSetsFilter.options.values, ruleSetsFilterAux.options.values)) {
          ruleSetsFilter.options.values = ruleSetsFilterAux.options.values;
          ruleSetsFilter.rebuildOptions$.next(true);
        } else {
          ruleSetsFilter.rebuildOptions$.next(false);
        }
      });
  }

  public fetchData(searchCriteria: SearchCriteria) {
    this.store.dispatch(new SearchLatestChanges(searchCriteria));
  }

  downloadData(searchCriteria: SearchCriteria) {
    this.store.dispatch(new ExportLatestChanges(searchCriteria))
      .pipe(first())
      .subscribe(() => {
        this.isDownloading$.next(false);
        this.notificationService.showSimpleAlert(
          'Latest changes list downloaded successfully',
          'success'
        );
      });
  }
}
