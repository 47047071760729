<div class="instruments-data-variables-container">
  <div class="search-input-container">
    <mat-form-field>
      <mat-label>
        Search variables
      </mat-label>
      <div class="input-wrapper">
          <input matInput
                 [(ngModel)]="searchText" (ngModelChange)="onSearchTextChange()">
      </div>
    </mat-form-field>
  </div>
  @if (searchedGeneralVariables.length > 0) {
    <mat-accordion>
      <mat-expansion-panel [expanded]="generalPanelState()" (opened)="generalPanelState.set(true)"
                           (closed)="generalPanelState.set(false)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="title-field-wrapper">
      <span
      >
        General
      </span></div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="section-wrapper" [ngClass]="!generalPanelState() ? 'less' : 'more'">
          <div class="variables-wrapper">
            <div *ngFor="let variable of searchedGeneralVariables" class="variable-container">
              <div>{{ variable.value }}</div>
              <apx-action-button
                [colorClass]="'secondary-action'"
                [name]="'Add variable'"
                [method]="onSelectVariable(variable)"
              ></apx-action-button>
            </div>
          </div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  }
  @if (searchedInvestmentFundsVariables.length > 0) {
    <mat-accordion>
      <mat-expansion-panel [expanded]="investmentPanelState()" (opened)="investmentPanelState.set(true)"
                           (closed)="investmentPanelState.set(false)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="title-field-wrapper">
                <span
                >
                  Investment funds
                </span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="section-wrapper" [ngClass]="!investmentPanelState() ? 'less' : 'more'">
          <div class="variables-wrapper">
            <div *ngFor="let variable of searchedInvestmentFundsVariables" class="variable-container">
              <div>{{ variable.value }}</div>
              <apx-action-button
                [colorClass]="'secondary-action'"
                [name]="'Add variable'"
                [method]="onSelectVariable(variable)"
              ></apx-action-button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  }
</div>
