import { GetEditorConceptHierarchyEntry } from 'generated/v3/model/getEditorConceptHierarchyEntry';
import { ConceptHierarchy } from '../../models';
import { v4 as uuidv4 } from 'uuid';

export class TaxonomyHierarchyMapper {
  static buildTreeFromBroaderRelations(
    hierarchyEntries: GetEditorConceptHierarchyEntry[]
  ): ConceptHierarchy[] {
    const allElements: ConceptHierarchy[] = [];
    let elementToAddChildren: ConceptHierarchy[] = hierarchyEntries
      .filter(h => !h.targetIds || h.targetIds.length === 0)
      .map(this.buildConceptHierarchy);

    allElements.push(...elementToAddChildren);
    while (elementToAddChildren.length > 0) {
      const nextIteration: ConceptHierarchy[] = [];

      elementToAddChildren.forEach(broader => {
        const narrowerElements: ConceptHierarchy[] = hierarchyEntries
          .filter(hierarchy => hierarchy.targetIds)
          .filter(hierarchy => {
            return hierarchy.targetIds.find(id => id === broader.id);
          })
          .map(entry => {
            return this.buildConceptHierarchyWithParent(entry, broader);
          });

        if (narrowerElements) {
          narrowerElements.sort(ConceptHierarchy.comparator);
        }
        broader.childrenConcepts = narrowerElements;
        broader.hasChildrenLoaded = narrowerElements.length > 0;

        nextIteration.push(...narrowerElements);
        allElements.push(...narrowerElements);
      });
      elementToAddChildren = nextIteration;
    }
    return allElements;
  }

  static appendNarrowerRelations(
    conceptId: string,
    allTreeElements: ConceptHierarchy[],
    narrowerEntries: GetEditorConceptHierarchyEntry[]
  ) {
    const conceptTreeElements = allTreeElements.filter(e => e.id === conceptId);
    conceptTreeElements.forEach(element => {
      const childrenConcepts = narrowerEntries
        .find(narrower => narrower.concept.id === element.id)
        .targetIds.map(targetId => {
          const targetNarrower = narrowerEntries.find(narrower => narrower.concept.id === targetId);
          return this.buildConceptHierarchyWithParent(targetNarrower, element, []);
        });

      if (childrenConcepts) {
        childrenConcepts.sort(ConceptHierarchy.comparator);
      }
      element.childrenConcepts = childrenConcepts;
      element.hasChildrenLoaded = true;
      allTreeElements.push(...element.childrenConcepts);
    });
  }

  static appendSiblings(
    conceptId: string,
    parentConceptId: string,
    allTreeElements: ConceptHierarchy[],
    siblings: GetEditorConceptHierarchyEntry[]
  ) {
    const concepts = allTreeElements.filter(
      e => e.id === conceptId && e.parent?.id === parentConceptId
    );
    concepts.forEach(concept => {
      const parentConcept = concept?.parent;
      const siblingConcepts: ConceptHierarchy[] = siblings
        .filter(e => e.concept.id !== conceptId)
        .filter(e => e.concept.id !== parentConceptId)
        .map(sibling => this.buildConceptHierarchyWithParent(sibling, parentConcept))
        .sort(ConceptHierarchy.comparator);
      parentConcept.childrenConcepts = [concept, ...siblingConcepts];
      parentConcept.hasChildrenLoaded = true;
      parentConcept.childrenConcepts.forEach(c => (c.hasSiblingsLoaded = true));
      allTreeElements.push(...siblingConcepts);
    });
  }

  static buildConceptHierarchy(entry: GetEditorConceptHierarchyEntry): ConceptHierarchy {
    return {
      uid: uuidv4(),
      id: entry.concept.id,
      term: entry.concept.term,
      jurisdictions: entry.concept.jurisdictions,
      ownerName: entry.concept.ownerName,
      categories: entry.concept.categories,
      parent: undefined,
      hasChildrenLoaded: false,
      hasSiblingsLoaded: false
    };
  }

  static buildConceptHierarchyWithParent(
    entry: GetEditorConceptHierarchyEntry,
    parent?: ConceptHierarchy,
    childrenConcepts?: ConceptHierarchy[]
  ): ConceptHierarchy {
    return {
      uid: uuidv4(),
      id: entry.concept.id,
      term: entry.concept.term,
      jurisdictions: entry.concept.jurisdictions,
      ownerName: entry.concept.ownerName,
      categories: entry.concept.categories,
      parent,
      hasChildrenLoaded: false,
      hasSiblingsLoaded: false,
      childrenConcepts
    };
  }
}
