import { VariableConcept } from '../../models/variable-concept';

export class VariablesMapper {
  static mapToVariable(generatedModel: any): VariableConcept {
    return {
      name: generatedModel.name,
      value: generatedModel.value,
      type: generatedModel.type
    };
  }
}
