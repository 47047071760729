<apx-template-modal [data]="templateData" [template]="modalTemplate">
  <ng-template #modalTemplate>
    <apx-loading-spinner [isLoading]="isLoading$">
      <mat-dialog-content class="concept-rule-sets-modal-wrapper">
        <div class="concept-rule-sets-modal-search">
          <app-search-input [placeholder]="'Search Rule Set Families or Rule Sets'" (valueChange)="onSearch($event)">
          </app-search-input>
        </div>
        <div class="concept-rule-sets-modal-content">
          <span class="rule-set-any-container">
            <mat-checkbox [checked]="anyRuleSet" [disabled]="!hasSelection()" (change)="setAny($event.checked)">
              <div class="info-wrapper">
                <span class="rule-set-family-name any-rule-set">Any Rule Set</span>
                <mat-icon
                  class="material-icons-outlined"
                  [matTooltip]="
                    'Choosing this option means that there is no restriction on Rule Set level where this term can be applied. This term will be resolvable for any Rule Set supported by Apiax.'
                  "
                  [matTooltipPosition]="'above'"
                  >info</mat-icon
                >
              </div>
            </mat-checkbox>
          </span>
          <mat-accordion [multi]="true">
            <ng-template [ngForOf]="ruleSetFamilies" let-ruleSetFamily ngFor>
              <mat-expansion-panel *ngIf="ruleSetFamily.visible">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span>
                      <mat-checkbox
                        [checked]="ruleSetFamily.selected"
                        (click)="onEvent($event)"
                        [indeterminate]="someComplete(ruleSetFamily)"
                        (change)="setAll($event.checked, ruleSetFamily)"
                      >
                        <span class="rule-set-family-name">{{ ruleSetFamily.name }}</span>
                        <ng-template [ngIf]="ruleSetFamily.selectedRuleSetsCount > 0">
                          <span class="selected-rule-sets-count">
                            {{ ruleSetFamily.selectedRuleSetsCount }} Rule Set(s) Selected
                          </span>
                        </ng-template>
                      </mat-checkbox>
                    </span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <span>
                    <ul class="rule-set-list">
                      <ng-template [ngForOf]="ruleSetFamily.ruleSets" let-ruleSet ngFor>
                        <li *ngIf="ruleSet.visible">
                          <mat-checkbox
                            [(ngModel)]="ruleSet.selected"
                            (ngModelChange)="updateAllComplete(ruleSetFamily)"
                          >
                            <span class="rule-set-name">{{ ruleSet.name }}</span>
                          </mat-checkbox>
                        </li>
                      </ng-template>
                    </ul>
                  </span>
                </ng-template>
              </mat-expansion-panel>
            </ng-template>
          </mat-accordion>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions class="concept-rule-sets-modal-actions">
        <div>
          <span class="foot-note">{{ footNote }}</span>
        </div>
        <div>
          <button class="btn-action secondary-action" (click)="onCancel()">Cancel</button>
          <button class="btn-action primary-action" (click)="onConfirmation()">Confirm</button>
        </div>
      </mat-dialog-actions>
    </apx-loading-spinner>
  </ng-template>
</apx-template-modal>
