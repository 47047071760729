export enum TaxonomyFiltersAction {
  LoadUsageFilterOptions = '[Load usage filters options] loads all the filter options for the usage',
  LoadSearchTermsFilterOptions = '[Load search terms filters options] loads all the filter options for the search terms',
  LoadLatestChangesFilterOptions = '[Load latest changes filters options] loads all the filter options for the latest changes',
  LoadSearchOnRulesFilterOptions = '[Load search on rules filters options] loads all the filter options for the search on rules'
}

export class LoadUsageFilterOptions {
  public static readonly type = TaxonomyFiltersAction.LoadUsageFilterOptions;
}

export class LoadSearchTermsFilterOptions {
  public static readonly type = TaxonomyFiltersAction.LoadSearchTermsFilterOptions;
}

export class LoadLatestChangesFilterOptions {
  public static readonly type = TaxonomyFiltersAction.LoadLatestChangesFilterOptions;
}

export class LoadSearchOnRulesFilterOptions {
  public static readonly type = TaxonomyFiltersAction.LoadSearchOnRulesFilterOptions;
}
