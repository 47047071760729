<div class="taxonomy-search-terms-wrapper">
  <apx-loading-spinner [isLoading]='isLoading$'>
    <div class="taxonomy-search-terms-search-mode-container">
      <apx-radio-button [control]="searchModeControl" [label]="'Search mode:'"
          [availableOptions$]="searchModeOptions$"></apx-radio-button>
    </div>
    <div class="taxonomy-search-terms-list-container">
      <apx-data-table
        #table
          [search]="search"
          [entity]="'Term'"
          [data]="searchResults"
          [totalData]="totalResults"
          [columns]="columns"
          [pageSize]="PAGE_SIZE"
          [filters]="filters"
          [noResultsMessage]="searchValue?.length > 0 ? 'No terms found' : 'Search for terms'"
          [idFetcher]="idFetcher"
          [initialLoadingState]="true"
          [showDownload]="true"
          [downloadPermission]="downloadPermission"
          [downloadTooltip]="'Download terms'"
          [isDownloading$]="isDownloading$"
          [currentUserPermissions]="taxonomyUtilsService.currentUserPermissions"
          [allowSelection]="(hasSelectionMode$ | async)"
          [menuActions]='menuActions'
          [saveToStorage]="saveToStorageStore"
          [loadFromStorage]="loadFromStorageStore"
          [filterSaveToStorage]="filterSaveToStore"
          [filterLoadFromStorage]="filterLoadFromStore"
          (onFetch)="fetchData($event)"
          (onSelect)="onSelect($event)"
          (onDownload)="downloadData($event)"
      ></apx-data-table>
    </div>
  </apx-loading-spinner>
</div>
