import { LinkableGroupInfo } from '../../../generated/v3/model/linkableGroupInfo';
import { GroupWeb } from '../../models/group-web';

export class LinkableGroupsMapper {

  public static mapFromLinkableGroupInfoToModel(generatedModel: LinkableGroupInfo): GroupWeb {
    return {
      id: generatedModel.id,
      name: generatedModel.groupName,
      organizationId: generatedModel.organizationId,
      organizationName: generatedModel.organizationName,
      organizationPhotoId: generatedModel.organizationPhotoId
    };
  }
}
