import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { TaxonomyExportDataState } from '../../../../../domain/stores/taxonomy-export-data/taxonomy-export-data.state';
import { FullConceptExport } from '../../../../../domain/stores/taxonomy-export-data/taxonomy-export-data.action';
import { first } from 'rxjs/operators';
import { NotificationService } from '@apiax/web-commons';

@Component({
  selector: 'app-full-export',
  templateUrl: './full-export.component.html',
  styleUrls: ['./full-export.component.scss']
})
export class FullExportComponent implements OnInit {
  isPerformingOtherExports$: Observable<boolean>;
  performFullExport = this.getFulLExportFunction();

  constructor(private store: Store, private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.isPerformingOtherExports$ = this.store.select(
      TaxonomyExportDataState.isPerformingPartialExport
    );
  }

  getFulLExportFunction() {
    return (callback: () => void) => {
      this.store.dispatch(new FullConceptExport())
        .pipe(first())
        .subscribe(() => {
          callback();
          this.notificationService.showSimpleAlert('Full export performed successfully', 'success');
        });
    };
  }
}
