<form [formGroup]="form">
  <quill-editor
    #definitionEditor
    [class.disabled]="isDisabled"
    [modules]="quillModules"
    [formControl]="internalControl"
    [readOnly]="isReadOnly"
    [required]="isRequired"
    [disabled]="isDisabled"
    [customToolbarPosition]="'top'"
    (onFocus)="onDefinitionFocus()"
    (focusin)="focusIn($event)"
    (onEditorCreated)="onEditorCreated($event)"
    (onContentChanged)="change($event)"
  >
    <div quill-editor-toolbar [class.disabled]="isDisabled">
      <button type="button" class="ql-bold">
        <svg viewBox="0 0 18 18">
          <path
            class="ql-stroke"
            d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
          ></path>
          <path
            class="ql-stroke"
            d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
          ></path>
        </svg>
      </button>
      <button type="button" class="ql-italic">
        <svg viewBox="0 0 18 18">
          <path
            class="ql-stroke"
            d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
          ></path>
          <path
            class="ql-stroke"
            d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
          ></path>
        </svg>
      </button>
      <button type="button" class="toolbar-block ql-underline">
        <svg viewBox="0 0 18 18">
          <path
            class="ql-stroke"
            d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
          ></path>
          <path
            class="ql-stroke"
            d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
          ></path>
        </svg>
      </button>
      <button type="button" class="ql-list" value="ordered">
        <svg viewBox="0 0 18 18">
          <path
            class="ql-stroke"
            d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
          ></path>
          <path
            class="ql-stroke"
            d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
          ></path>
        </svg>
      </button>
      <button type="button" class="toolbar-block ql-list" value="bullet">
        <svg viewBox="0 0 18 18">
          <path
            class="ql-stroke"
            d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
          ></path>
          <path
            class="ql-stroke"
            d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
          ></path>
        </svg>
      </button>
      <button type="button" class="toolbar-block ql-link">
        <svg viewBox="0 0 18 18">
          <path
            class="ql-stroke"
            d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
          ></path>
          <path
            class="ql-stroke"
            d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
          ></path>
        </svg>
      </button>
      <button type="button" class="ql-indent" value="-1">
        <svg viewBox="0 0 18 18">
          <path
            class="ql-stroke"
            d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
          ></path>
          <path
            class="ql-stroke"
            d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
          ></path>
        </svg>
      </button>
      <button type="button" class="ql-indent" value="+1">
        <svg viewBox="0 0 18 18">
          <path
            class="ql-stroke"
            d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
          ></path>
          <path
            class="ql-stroke"
            d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
          ></path>
        </svg>
      </button>
      <button
        type="button"
        class="btn-open-modal add-variable-btn"
        [matTooltip]="'Add variable'"
        [matTooltipPosition]="'above'"
        *ngIf="showBtnModal"
        (click)="onAddVariableClicked()"
      >
        <span class="material-icons-round">add_circle</span>
      </button>
    </div>
  </quill-editor>
</form>
