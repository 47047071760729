import { LLMOption } from '../../../generated/v3';
import { ChipsSelectionOption } from '@apiax/web-commons';

export class LlmOptionsMapper {
  static mapLLMOptionToChipsSelectionOption(generatedModel: LLMOption): ChipsSelectionOption {
    return {
      id: generatedModel.value,
      label: generatedModel.name
    };
  }
}
