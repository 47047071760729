/**
 * Taxonomy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateConceptRelation { 
    relationId?: string;
    conceptRelationType?: UpdateConceptRelation.ConceptRelationTypeEnum;
    targetConceptId?: string;
}
export namespace UpdateConceptRelation {
    export type ConceptRelationTypeEnum = 'RELATED' | 'NARROWER' | 'BROADER';
    export const ConceptRelationTypeEnum = {
        RELATED: 'RELATED' as ConceptRelationTypeEnum,
        NARROWER: 'NARROWER' as ConceptRelationTypeEnum,
        BROADER: 'BROADER' as ConceptRelationTypeEnum
    };
}


