import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConceptHierarchySearchComponent } from './concept-hierarchy-search/concept-hierarchy-search.component';
import { ConceptHierarchySearchableDirective } from './concept-hierarchy-search/concept-hierarchy-search.directive';

import { ConceptSearchComponent } from './concept-search/concept-search.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { ApxQuillComponent } from './apx-quill/apx-quill.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressBarModule,
    MatChipsModule,
    MatAutocompleteModule,
    QuillModule
  ],
  declarations: [
    SearchInputComponent,
    ConceptSearchComponent,
    ConceptHierarchySearchComponent,
    ConceptHierarchySearchableDirective,
    ApxQuillComponent
  ],
  exports: [
    SearchInputComponent,
    ConceptSearchComponent,
    ConceptHierarchySearchComponent,
    ConceptHierarchySearchableDirective,
    ApxQuillComponent
  ]
})
export class SharedComponentsModule {
}
