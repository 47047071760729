<div class="term-editor-wrapper">
  <apx-loading-spinner [isLoading]="isLoading$">
    <form [formGroup]="form">
      <ng-template [ngIf]="isCreateMode">
        <span class="info">All fields marked with an * are mandatory in order to create a new taxonomy term.</span>
      </ng-template>
      <ng-template [ngIf]="!isCreateMode">
        <div class="single-field">
          <div class="single-field">
            <apx-country-slider
              [control]="form.get(formNames.Slider)"
              [options]="sliderOptions"
              [selectAddButton]="selectSliderAdd"
              [showAddButton]="
                isCreateMode ? taxonomyUtilsService.canCreateConcept() : taxonomyUtilsService.canUpdateConcept()
              "
              (plusClicked)="openTranslationLanguageModal()"
            ></apx-country-slider>
          </div>
        </div>
      </ng-template>
      <div class="multiple-fields">
        <apx-simple-input [control]="form.get(formNames.Term)" [placeholder]="'Term'"> </apx-simple-input>
        <apx-simple-input [control]="form.get(formNames.TermId)" [placeholder]="'Term ID'"> </apx-simple-input>
        <apx-template-picker
          [control]="form.get(formNames.Owner)"
          [placeholder]="'Owner'"
          [label]="'Owner'"
          [removeSelection]="true"
          [isJurisdiction]="false"
          [defaultIcon]="'business'"
          [availableOptions$]="availableOwners$"
          [optionTemplate]="ownersOptionTemplate"
        ></apx-template-picker>
        <apx-chips-with-addition
          [control]="form.get(formNames.RuleSets)"
          [placeholder]="'Rule Sets'"
          [removable]="true"
          [entityPlural]="'Rule Sets'"
          [additionalInfo]="ruleSetsAdditionalInfo"
          [additionalInfoTooltip]="ruleSetsAdditionalInfoTooltip"
          (callbackAction)="openRuleSetsModal()"
        >
        </apx-chips-with-addition>
        <apx-chips-with-addition
          [control]="form.get(formNames.Jurisdictions)"
          [placeholder]="'Jurisdictions'"
          [removable]="true"
          [entityPlural]="'Jurisdictions'"
          (callbackAction)="openJurisdictionsModal()"
        >
        </apx-chips-with-addition>
        <apx-chips
          [control]="form.get(formNames.Categories)"
          [removable]="true"
          [placeholder]="'Categories'"
          [onlyRemovedOnes]="false"
        >
        </apx-chips>
        <apx-template-picker
          [control]="form.get(formNames.Privacy)"
          [placeholder]="'Privacy'"
          [label]="'Privacy'"
          [removeSelection]="false"
          [isJurisdiction]="false"
          [iconCode]="'info'"
          [iconTooltip]="privacyTooltip"
          [availableOptions$]="availablePrivacyValues$"
          [optionTemplate]="defaultOptionTemplate"
        ></apx-template-picker>
        <ng-template [ngIf]="!isCreateMode">
          <apx-template-picker
            [control]="form.get(formNames.Status)"
            [placeholder]="'Status'"
            [label]="'Status'"
            [removeSelection]="false"
            [isJurisdiction]="false"
            [iconCode]="'info'"
            [iconTooltip]="statusTooltip"
            [availableOptions$]="availableStatusValues$"
            [optionTemplate]="defaultOptionTemplate"
          ></apx-template-picker>
          <apx-simple-input
            [control]="form.get(formNames.Id)"
            [placeholder]="'ID'"
            [callbackIcon]="'copy'"
            [callbackTooltip]="'Copy ID'"
            (callbackAction)="copyID()"
          >
          </apx-simple-input>
        </ng-template>
      </div>
      <div class="single-field">
        <app-apx-quill
          [conceptUtils]="conceptUtils"
          [isDisabled]="form.get(formNames.Definition).disabled"
          [controlName]="formNames.Definition"
          [isReadOnly]="form.get(formNames.Definition).disabled"
          [isRequired]="true"
          [isSanitize]="true"
          [showBtnModal]="true"
        ></app-apx-quill>
      </div>
      <div class="actions-wrapper">
        <apx-action-button
          *ngIf="isCreateMode ? taxonomyUtilsService.canCreateConcept() : taxonomyUtilsService.canUpdateConcept()"
          [enabled]="form.valid && form.dirty"
          [colorClass]="'primary-action'"
          [loadingName]="isCreateMode ? 'Creating...' : 'Updating...'"
          [name]="isCreateMode ? 'Create new taxonomy term' : 'Update term'"
          [method]="onSubmit()"
        >
        </apx-action-button>
      </div>
    </form>
  </apx-loading-spinner>
</div>

<ng-template #ownersOptionTemplate let-option>
  <div class="option-wrapper">
    <ng-template [ngIf]="option.image" [ngIfElse]="defaultIconTemplate">
      <img alt="image" [src]="option.image" />
    </ng-template>
    <ng-template #defaultIconTemplate>
      <mat-icon>business</mat-icon>
    </ng-template>
    <span>{{ option.label }}</span>
  </div>
</ng-template>

<ng-template #defaultOptionTemplate let-option>
  <div class="option-wrapper">
    <span>{{ option.label }}</span>
  </div>
</ng-template>
