// angular
import {CommonModule} from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {CORE_DIRECTIVES} from './directives';
import {CORE_PROVIDERS} from './services';

// module
interface ICoreModuleOptions {
    window?: any;
    console?: any;
}

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */
@NgModule({ declarations: [CORE_DIRECTIVES],
    exports: [CommonModule, FormsModule, RouterModule, CORE_DIRECTIVES], imports: [CommonModule, RouterModule], providers: [CORE_PROVIDERS, provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule {
    // configuredProviders: *required to configure WindowService and ConsoleService per platform
    public static forRoot(configuredProviders: any[]): ModuleWithProviders<CoreModule> {
      return {
        ngModule: CoreModule,
        providers: configuredProviders
      };
    }
    constructor(
        @Optional()
        @SkipSelf()
            parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('CoreModule already loaded; Import in root module only.');
        }
    }


}
