<apx-template-modal [data]='templateData' [template]='modalTemplate'>
  <ng-template #modalTemplate>
    <apx-loading-spinner [isLoading]='isLoading$'>
      <mat-dialog-content class='clone-concept-modal-wrapper'>
        <form [formGroup]='form'>
          <apx-simple-input
            [control]="form.get(formNames.TermId)"
            [placeholder]="'Term ID'">
          </apx-simple-input>
          <apx-template-picker
            [control]='form.get(formNames.Owner)'
            [placeholder]="'Owner'"
            [removeSelection]='true'
            [isJurisdiction]='false'
            [defaultIcon]="'business'"
            [availableOptions$]='availableOwners$'
            [optionTemplate]='ownersOptionTemplate'></apx-template-picker>
          <apx-chips-with-addition
            [control]='form.get(formNames.RuleSets)'
            [placeholder]="'Rule Sets'"
            [removable]="true"
            [entityPlural]="'Rule Sets'"
            [additionalInfo]="ruleSetsAdditionalInfo"
            [additionalInfoTooltip]="ruleSetsAdditionalInfoTooltip"
            (callbackAction)="openRuleSetsModal()">
          </apx-chips-with-addition>
          <apx-chips-with-addition
            placeholder="Jurisdictions"
            [control]='form.get(formNames.Jurisdictions)'
            [removable]="true"
            [entityPlural]="'Jurisdictions'"
            (callbackAction)="openJurisdictionsModal()">
          </apx-chips-with-addition>
        </form>
      </mat-dialog-content>
      <mat-dialog-actions class='clone-concept-modal-actions'>
        <button class='btn-action primary-action' [disabled]="shouldBeDisabled()" (click)="onConfirmation()">
          Confirm
        </button>
      </mat-dialog-actions>
    </apx-loading-spinner>
  </ng-template>
</apx-template-modal>

<ng-template #ownersOptionTemplate let-option>
  <div class='option-wrapper'>
    <ng-template [ngIf]='option.image' [ngIfElse]='defaultIconTemplate'>
      <img alt='image' [src]='option.image'>
    </ng-template>
    <ng-template #defaultIconTemplate>
      <mat-icon>business</mat-icon>
    </ng-template>
    <span>{{option.label}}</span>
  </div>
</ng-template>
