import { SearchCriteria } from '@apiax/web-commons';
import {
  ConceptSearchConcept,
  ExportSearchCriteria,
  SearchTermsExactSearchConcept,
  SearchTermsExactSearchRequest,
  SearchTermsStandardSearchConcept,
  SearchTermsStandardSearchRequest
} from '../../../generated/v3';
import { ConceptSearchResult, ConceptSearchResultModel } from '../../models';

export enum ConceptSearchMode {
  Standard = 'Standard',
  Exact = 'Exact'
}

export class TaxonomySearchTermsMapper {
  // Search

  static mapToStandardSearchRequest(
    searchCriteria: SearchCriteria
  ): SearchTermsStandardSearchRequest {
    return {
      page: searchCriteria.page,
      pageSize: searchCriteria.pageSize,
      searchText: searchCriteria.searchFilter,
      filters: {
        jurisdictions: searchCriteria?.contentFilters?.['jurisdictions'] || [],
        organizations: searchCriteria?.contentFilters?.['organizations'] || [],
        ruleSetFamilyIds: searchCriteria?.contentFilters?.['ruleSetFamilies'] || [],
        ruleSetIds: searchCriteria?.contentFilters?.['ruleSets'] || [],
        categories: searchCriteria?.contentFilters?.['categories'] || []
      },
      excludedConceptIds: searchCriteria.excludedIds || [],
      includedConceptIds: searchCriteria.selectedIds || []
    };
  }

  static mapToExactSearchRequest(searchCriteria: SearchCriteria): SearchTermsExactSearchRequest {
    return {
      page: searchCriteria.page,
      pageSize: searchCriteria.pageSize,
      searchText: searchCriteria.searchFilter,
      filters: {
        jurisdictions: searchCriteria?.contentFilters?.['jurisdictions'] || [],
        organizations: searchCriteria?.contentFilters?.['organizations'] || [],
        ruleSetFamilyIds: searchCriteria?.contentFilters?.['ruleSetFamilies'] || [],
        ruleSetIds: searchCriteria?.contentFilters?.['ruleSets'] || [],
        categories: searchCriteria?.contentFilters?.['categories'] || []
      },
      excludedConceptIds: searchCriteria.excludedIds || [],
      includedConceptIds: searchCriteria.selectedIds || []
    };
  }

  static mapToConceptSearchResult(
    concept: SearchTermsStandardSearchConcept | SearchTermsExactSearchConcept
  ): ConceptSearchResult {
    return {
      id: concept.id,
      termId: concept.termId,
      term: concept.term,
      rawDefinition: concept.rawDefinition,
      categoriesLabel: concept.categoriesLabel,
      isPrivate: concept.isPrivate,
      jurisdictionsLabel: concept.jurisdictionsLabel,
      ownerLabel: concept.ownerLabel,
      state: concept.state,
      ruleSetsLabel: concept.ruleSetRefsLabel
    };
  }

  static mapToConceptResult(concept: ConceptSearchConcept): ConceptSearchResultModel {
    return {
      id: concept.id,
      termId: concept.termId,
      term: concept.term,
      rawDefinition: concept.rawDefinition,
      jurisdictionsLabel: concept.jurisdictionsLabel,
      jurisdictions: concept.jurisdictions,
      ownerLabel: concept.ownerLabel
    };
  }

  // Export

  static mapToStandardExportRequest(
    searchCriteria: SearchCriteria
  ): SearchTermsStandardSearchRequest {
    return {
      searchText: searchCriteria.searchFilter,
      filters: {
        jurisdictions: searchCriteria?.contentFilters?.['jurisdictions'] || [],
        organizations: searchCriteria?.contentFilters?.['organizations'] || [],
        ruleSetFamilyIds: searchCriteria?.contentFilters?.['ruleSetFamilies'] || [],
        ruleSetIds: searchCriteria?.contentFilters?.['ruleSets'] || [],
        categories: searchCriteria?.contentFilters?.['categories'] || []
      },
      excludedConceptIds: searchCriteria.excludedIds || [],
      includedConceptIds: searchCriteria.selectedIds || []
    };
  }

  static mapToExactExportRequest(searchCriteria: SearchCriteria): ExportSearchCriteria {
    return {
      searchText: searchCriteria.searchFilter,
      filters: {
        jurisdictions: searchCriteria?.contentFilters?.['jurisdictions'] || [],
        organizations: searchCriteria?.contentFilters?.['organizations'] || [],
        ruleSetFamilyIds: searchCriteria?.contentFilters?.['ruleSetFamilies'] || [],
        ruleSetIds: searchCriteria?.contentFilters?.['ruleSets'] || [],
        categories: searchCriteria?.contentFilters?.['categories'] || []
      },
      excludedConceptIds: searchCriteria.excludedIds || [],
      includedConceptIds: searchCriteria.selectedIds || []
    };
  }
}
