import {
  ExportLatestChangesFilters,
  GetLatestChangesFilters,
  GetLatestChangesRequest,
  LatestChange
} from '../../../generated/v3';
import { LatestChange as LatestChangeModel } from '../../models/latest-change';
import { SearchCriteria } from '@apiax/web-commons';
import { v4 as uuidv4 } from 'uuid';
import StatesEnum = GetLatestChangesFilters.StatesEnum;

export class TaxonomyLatestChangesMapper {
  static mapToLatestChangeSearchRequest(searchCriteria: SearchCriteria): GetLatestChangesRequest {
    return {
      page: searchCriteria.page,
      pageSize: searchCriteria.pageSize,
      filters: {
        from: searchCriteria?.contentFilters?.dateRange?.[0]
          ? searchCriteria?.contentFilters?.dateRange[0]
          : null,
        to: searchCriteria?.contentFilters?.dateRange?.[1]
          ? searchCriteria?.contentFilters?.dateRange[1]
          : null,
        text: searchCriteria.searchFilter,
        userIds: searchCriteria.contentFilters['userIds'] || [],
        jurisdictions: searchCriteria.contentFilters['jurisdictions'] || [],
        organizations: searchCriteria.contentFilters['organizations'] || [],
        ruleSetFamilyIds: searchCriteria.contentFilters['ruleSetFamilies'] || [],
        ruleSetIds: searchCriteria.contentFilters['ruleSets'] || [],
        categories: searchCriteria.contentFilters['categories'] || [],
        states:
          (searchCriteria.contentFilters['status'] || []).map(s => this.mapToStateEnum(s)) || []
      }
    };
  }

  static mapToStateEnum(value: string): StatesEnum {
    switch (value.toUpperCase()) {
      case StatesEnum.ACTIVE:
        return StatesEnum.ACTIVE;
      case StatesEnum.DEPRECATED:
        return StatesEnum.DEPRECATED;
      case StatesEnum.INACTIVE:
        return StatesEnum.INACTIVE;
    }
  }

  static mapToLatestChangeModel(change: LatestChange): LatestChangeModel {
    return {
      id: uuidv4(),
      changes: change.change,
      term: change.term,
      termId: change.termId,
      conceptId: change.conceptId,
      timestamp: change.timeStamp,
      userName: change.userName
    };
  }

  static mapToLatestChangeExportRequest(
    searchCriteria: SearchCriteria
  ): ExportLatestChangesFilters {
    return {
      from: searchCriteria?.contentFilters?.dateRange?.[0]
        ? searchCriteria?.contentFilters?.dateRange[0]
        : null,
      to: searchCriteria?.contentFilters?.dateRange?.[1]
        ? searchCriteria?.contentFilters?.dateRange[1]
        : null,
      text: searchCriteria.searchFilter,
      userIds: searchCriteria.contentFilters['userIds'] || [],
      jurisdictions: searchCriteria.contentFilters['jurisdictions'] || [],
      organizations: searchCriteria.contentFilters['organizations'] || [],
      ruleSetFamilyIds: searchCriteria.contentFilters['ruleSetFamilies'] || [],
      ruleSetIds: searchCriteria.contentFilters['ruleSets'] || [],
      categories: searchCriteria.contentFilters['categories'] || [],
      states: (searchCriteria.contentFilters['status'] || []).map(s => this.mapToStateEnum(s)) || []
    };
  }
}
