import { Injectable } from '@angular/core';
import { getConfig } from 'shared/app/config';

@Injectable()
export class PhotoService {
  public get basePath() {
    return `${getConfig('userPhotoBaseUrl')}`;
  }

  getUrl(id: string) {
    return `${this.basePath}${id}`;
  }
}
