<apx-template-modal [data]='templateData' [template]='modalTemplate'>
  <ng-template #modalTemplate>
    <mat-dialog-content>
      <apx-loading-spinner [isLoading]="isLoading$">
        <div class='concept-relations-modal-search'>
          <div>
              <app-concept-search (selectConcept)="addConceptToRelation($event)"
              [excludedConceptIds]="excludedConceptIds"></app-concept-search>
          </div>
        </div>
        <div class='concept-relations-modal-content'>
          <apx-data-table
            class="data-table"
            #table
            [entity]="'Concepts'"
            [data]="conceptRelationsPage"
            [rowIcon]="rowIcon"
            [totalData]="conceptRelations.length"
            [pageSize]="PAGE_SIZE"
            [columns]="columns"
            [idFetcher]="idFetcher"
            [noResultsMessage]="'No relations found'"
            [initialLoadingState]="false"
            (onFetch)="fetchData($event)"
          ></apx-data-table>
        </div>
      </apx-loading-spinner>
    </mat-dialog-content>
    <mat-dialog-actions class='concept-relations-modal-actions'>
      <div>
        <button class='btn-action secondary-action'
                (click)='onCancel()'>
          Cancel
        </button>
        <button class='btn-action primary-action'
                [disabled]="!hasChanges"
                (click)="onConfirmation()">
          Confirm
        </button>
      </div>
    </mat-dialog-actions>
  </ng-template>
</apx-template-modal>
