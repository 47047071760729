import { PartialExportType } from '../../mappers/taxonomy-export-data-mapper';

export enum TaxonomyConceptExportAction {
  FullConceptExport = '[Full Concept Export] perform a full export of the concepts',
  PartialConceptExport = '[Partial Concept Export] perform a partial export of the concepts'
}

export class FullConceptExport {
  public static readonly type = TaxonomyConceptExportAction.FullConceptExport;
}

export class PartialConceptExport {
  public static readonly type = TaxonomyConceptExportAction.PartialConceptExport;

  constructor(public payload: { type: PartialExportType; values: string[] }) {}
}
