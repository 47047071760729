<div class="partial-export-data-wrapper">
  <p class="title">Partial CSV export</p>
  <p class="info">Select Taxonomy data to export in CSV format</p>

  <div class="form-inputs">
    <div class="field-description">
      <span class="number">1</span>
      Choose a field to export based on those field values
    </div>
    <div class="radio-control">
      <apx-radio-button [control]="typeFormControl" [availableOptions$]="partialExportTypeOptions$"> </apx-radio-button>
    </div>

    <div class="field-values field-description">
      <span class="number">2</span>
      Paste your data followed by enter, comma or tab
    </div>
    <div class="values-control">
      <apx-chips [placeholder]="'Paste your data'" [control]="valuesFormControl"></apx-chips>
    </div>
  </div>

  <apx-action-button
    [loadingName]="'Exporting...'"
    [colorClass]="'primary-action'"
    [name]="'Partial export'"
    [enabled]="formGroup.valid && valuesFormControl.value.length > 0 && !(isPerformingOtherExports$ | async)"
    [method]="performPartialExport"
  ></apx-action-button>
</div>
