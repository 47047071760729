<apx-loading-spinner [isLoading]="isLoading$">

  <div *ngIf="concept" class="title">{{concept.term}}</div>

  <as-split direction="horizontal" unit="percent" gutterSize="3">

    <as-split-area [size]="60" [minSize]="30" [maxSize]="90" class="relations-container">
      <div class="subtitle">Relations</div>

      <apx-chips-with-addition
        [control]="form.get(conceptRelationType.RELATED)"
        [placeholder]="'Related'"
        [removable]="true"
        [showEmptyValuesMessage]="false"
        (callbackAction)="openRelationsModal(conceptRelationType.RELATED)">
      </apx-chips-with-addition>

      <apx-chips-with-addition
        [control]="form.get(conceptRelationType.NARROWER)"
        [placeholder]="'Narrower'"
        [removable]="true"
        [showEmptyValuesMessage]="false"
        (callbackAction)="openRelationsModal(conceptRelationType.NARROWER)">
      </apx-chips-with-addition>

      <apx-chips-with-addition
        [control]="form.get(conceptRelationType.BROADER)"
        [placeholder]="'Broader'"
        [removable]="true"
        [showEmptyValuesMessage]="false"
        (callbackAction)="openRelationsModal(conceptRelationType.BROADER)">
      </apx-chips-with-addition>

      <div class="actions-wrapper">
        <apx-action-button
          *ngIf="canEdit"
          [enabled]="form.dirty && form.valid"
          [colorClass]="'primary-action'"
          [loadingName]="'Updating...'"
          [name]="'Update relations'"
          [method]="onSubmit()"></apx-action-button>
      </div>
    </as-split-area>

    <as-split-area [size]="40" [minSize]="20" class="hierarchy-container">
      <div class="subtitle">Term Hierarchy</div>
      <app-taxonomy-concept-hierarchy></app-taxonomy-concept-hierarchy>
    </as-split-area>

  </as-split>
</apx-loading-spinner>
