import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CollapsableTreeModule, WebCommonsModule } from '@apiax/web-commons';
import { SharedComponentsModule } from '../shared-components.module';
import { CloneConceptModalComponent } from './clone-concept/clone-concept-modal.component';
import { ConceptJurisdictionsModalComponent } from './concept-jurisdictions/concept-jurisdictions-modal.component';
import { ConceptRelationsModalComponent } from './concept-relations/concept-relations-modal.component';
import { ConceptRuleSetsModalComponent } from './concept-rule-sets/concept-rule-sets-modal.component';
import { DeployedUsageModalComponent } from './deployed-usage/deployed-usage-modal.component';
import { OutdatedConceptModalComponent } from './outdated-concept/outdated-concept-modal.component';
import { TranslationLanguageModalComponent } from './translation-language/translation-language-modal.component';
import { ConceptSelectionVariablesComponent } from './concept-selection-variables/concept-selection-variables.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CollectionModalComponent } from './collection-modal/collection-modal.component';
import { RemoveFromMyCollectionModalComponent } from './remove-from-my-collection-modal/remove-from-my-collection-modal.component';
import {
  InstrumentsDataVariablesComponent
} from './concept-selection-variables/instruments-data-variables/instruments-data-variables.component';
import { MatInput } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WebCommonsModule,
    SharedComponentsModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSortModule,
    MatTableModule,
    MatExpansionModule,
    MatChipsModule,
    MatFormFieldModule,
    MatTabsModule,
    CollapsableTreeModule,
    MatInput
  ],
  declarations: [
    ConceptJurisdictionsModalComponent,
    ConceptRuleSetsModalComponent,
    DeployedUsageModalComponent,
    TranslationLanguageModalComponent,
    OutdatedConceptModalComponent,
    CloneConceptModalComponent,
    ConceptRuleSetsModalComponent,
    ConceptRelationsModalComponent,
    ConceptSelectionVariablesComponent,
    CollectionModalComponent,
    RemoveFromMyCollectionModalComponent,
    InstrumentsDataVariablesComponent
  ],
  exports: [ConceptJurisdictionsModalComponent, ConceptRuleSetsModalComponent]
})
export class ModalsModule {
}
