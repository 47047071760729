import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {getConfig} from 'shared/app/config';

if (getConfig('production')) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
