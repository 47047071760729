import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ConceptSearchResultModel } from '../../../models';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { TaxonomySearchTermsMapper } from '../../../domain/mappers/taxonomy-search-terms-mapper';
import { isEmpty } from 'lodash-es';
import { ConceptApiService } from '../../../../generated/v3';

@UntilDestroy()
@Component({
  selector: 'app-concept-search',
  templateUrl: './concept-search.component.html',
  styleUrls: ['./concept-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConceptSearchComponent implements OnInit {
  @Output()
  selectConcept = new EventEmitter<ConceptSearchResultModel>();

  public isLoading = false;
  results: ConceptSearchResultModel[];
  control = new UntypedFormControl();
  @Input() excludedConceptIds: string[];

  constructor(private taxonomyV3ApiService: ConceptApiService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initSearch();
  }

  selectedConcept(conceptSearchResult: ConceptSearchResultModel) {
    this.selectConcept.emit(conceptSearchResult);
  }

  private initSearch() {
    this.control.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(400),
        distinctUntilChanged(),
        filter(text => {
          return text && text !== '' && typeof text === 'string';
        }),
        tap(() => {
          this.isLoading = true;
          this.cdr.markForCheck();
        }),
        switchMap(searchTerm =>
          this.taxonomyV3ApiService.conceptSearch({
            filters: {
              excludedConceptIds: this.excludedConceptIds
            },
            searchText: searchTerm
          })
        ),
        map(result => {
          return result.list.map(c => TaxonomySearchTermsMapper.mapToConceptResult(c));
        })
      )
      .subscribe((results: ConceptSearchResultModel[]) => {
        if (results && !isEmpty(results)) {
          this.results = results;
        } else {
          this.results = [];
        }
        this.isLoading = false;
        this.cdr.markForCheck();
      });
  }

  autoCompleteSelected($event: MatAutocompleteSelectedEvent) {
    this.results = [];
    this.control.setValue(undefined, {
      emitEvent: false
    });
    this.selectConcept.emit($event.option.value);
  }
}
