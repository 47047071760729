import { isEmpty } from 'lodash-es';

export class ConceptTableUtils {
  static getPrivacyIcon(isPrivate: boolean) {
    if (isPrivate) {
      return `<div class="privacy-icon"><span class="material-icons-outlined">lock</span></div>`;
    } else {
      return `<div class="privacy-icon"><span class="material-icons-outlined">lock_open</span></div>`;
    }
  }

  static getPrivacyToolTip(isPrivate: boolean) {
    if (isPrivate) {
      return 'Private';
    } else {
      return 'Public';
    }
  }

  static getStatusLabel(value: string) {
    if (!isEmpty(value)) {
      return `<div class='status-badge ${value.toLowerCase()}'><span>${value}</span></div>`;
    } else {
      return '-';
    }
  }
}
