import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FiltersApiService } from '../../../../generated/v3';
import { FilterOption } from '../../../models';
import {
  LoadSearchOnRulesFilterOptions,
  LoadLatestChangesFilterOptions,
  LoadSearchTermsFilterOptions,
  LoadUsageFilterOptions
} from './taxonomy-filters.action';
import { TaxonomyFiltersStateUseCases } from './usecases/taxonomy-filters-usecases.state';
import { RuleSetFilterOption } from '../../../models/rule-set-filter-option';

export interface TaxonomyFiltersStateModel {
  jurisdictionOptions: FilterOption[];
  organizationOptions: FilterOption[];
  ruleSetFamilyOptions: FilterOption[];
  ruleSetOptions: RuleSetFilterOption[];
  usageRuleSetFamilyOptions: FilterOption[];
  usageRuleSetOptions: RuleSetFilterOption[];
  categoryOptions: FilterOption[];
  usageTypeGroupOptions: FilterOption[];
  activationStateOptions: FilterOption[];
  employeeOptions: FilterOption[];
  documentTypeOptions: FilterOption[];
  documentActivationStatusOptions: FilterOption[];
  documentVersionOptions: FilterOption[];
  isUsageFiltersLoaded: boolean;
  isSearchTermsFiltersLoaded: boolean;
  isLatestChangesFiltersLoaded: boolean;
  isSearchOnRulesFiltersLoaded: boolean;
}

export const DEFAULT_STATE: TaxonomyFiltersStateModel = {
  jurisdictionOptions: [],
  organizationOptions: [],
  ruleSetFamilyOptions: [],
  usageRuleSetFamilyOptions: [],
  usageRuleSetOptions: [],
  ruleSetOptions: [],
  categoryOptions: [],
  usageTypeGroupOptions: [],
  activationStateOptions: [],
  employeeOptions: [],
  documentTypeOptions: [],
  documentActivationStatusOptions: [],
  documentVersionOptions: [],
  isUsageFiltersLoaded: false,
  isSearchTermsFiltersLoaded: false,
  isLatestChangesFiltersLoaded: false,
  isSearchOnRulesFiltersLoaded: false,
};

@State<TaxonomyFiltersStateModel>({
  name: 'taxonomyFilters',
  defaults: DEFAULT_STATE
})
@Injectable()
export class TaxonomyFiltersState {
  private taxonomyFiltersStateUseCases: TaxonomyFiltersStateUseCases;

  @Selector()
  public static jurisdictionOptions(state: TaxonomyFiltersStateModel): FilterOption[] {
    return state.jurisdictionOptions;
  }

  @Selector()
  public static organizationOptions(state: TaxonomyFiltersStateModel): FilterOption[] {
    return state.organizationOptions;
  }

  @Selector()
  public static ruleSetFamilyOptions(state: TaxonomyFiltersStateModel): FilterOption[] {
    return state.ruleSetFamilyOptions;
  }

  @Selector()
  public static ruleSetOptions(state: TaxonomyFiltersStateModel): RuleSetFilterOption[] {
    return state.ruleSetOptions;
  }

  @Selector()
  public static usageRuleSetFamilyOptions(state: TaxonomyFiltersStateModel): FilterOption[] {
    return state.usageRuleSetFamilyOptions;
  }

  @Selector()
  public static usageRuleSetOptions(state: TaxonomyFiltersStateModel): RuleSetFilterOption[] {
    return state.usageRuleSetOptions;
  }

  @Selector()
  public static categoryOptions(state: TaxonomyFiltersStateModel): FilterOption[] {
    return state.categoryOptions;
  }

  @Selector()
  public static usageTypeGroupOptions(state: TaxonomyFiltersStateModel): FilterOption[] {
    return state.usageTypeGroupOptions;
  }

  @Selector()
  public static activationStateOptions(state: TaxonomyFiltersStateModel): FilterOption[] {
    return state.activationStateOptions;
  }

  @Selector()
  public static employeeOptions(state: TaxonomyFiltersStateModel): FilterOption[] {
    return state.employeeOptions;
  }

  @Selector()
  public static isUsageFiltersLoaded(state: TaxonomyFiltersStateModel): boolean {
    return state.isUsageFiltersLoaded;
  }

  @Selector()
  public static isSearchTermsFiltersLoaded(state: TaxonomyFiltersStateModel): boolean {
    return state.isSearchTermsFiltersLoaded;
  }

  @Selector()
  public static isLatestChangesFiltersLoaded(state: TaxonomyFiltersStateModel): boolean {
    return state.isLatestChangesFiltersLoaded;
  }

  @Selector()
  public static isSearchOnRulesFiltersLoaded(state: TaxonomyFiltersStateModel): boolean {
    return state.isSearchOnRulesFiltersLoaded;
  }

  @Selector()
  public static documentTypeOptions(state: TaxonomyFiltersStateModel): FilterOption[] {
    return state.documentTypeOptions;
  }

  @Selector()
  public static documentActivationStatusOptions(state: TaxonomyFiltersStateModel): FilterOption[] {
    return state.documentActivationStatusOptions;
  }

  @Selector()
  public static documentVersionOptions(state: TaxonomyFiltersStateModel): FilterOption[] {
    return state.documentVersionOptions;
  }

  constructor(filtersApiService: FiltersApiService) {
    this.taxonomyFiltersStateUseCases = new TaxonomyFiltersStateUseCases(filtersApiService);
  }

  @Action(LoadUsageFilterOptions)
  public loadUsageFilterOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.taxonomyFiltersStateUseCases.loadUsageFilterOptions(context);
  }

  @Action(LoadSearchTermsFilterOptions)
  public loadSearchFilterOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.taxonomyFiltersStateUseCases.loadSearchTermsFilterOptions(context);
  }

  @Action(LoadLatestChangesFilterOptions)
  public loadLatestChangesFilterOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.taxonomyFiltersStateUseCases.loadLatestChangesFilterOptions(context);
  }

  @Action(LoadSearchOnRulesFilterOptions)
  public LoadSearchOnRulesFilterOptions(context: StateContext<TaxonomyFiltersStateModel>) {
    return this.taxonomyFiltersStateUseCases.loadSearchOnRulesFilterOptions(context);
  }
}
