export enum VariableType {
  COMPANY = 'COMPANY_DATA',
  SYSTEM = 'SYSTEM',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  INSTRUMENTS_DATA_GENERAL= 'INSTRUMENTS_DATA_GENERAL',
  INSTRUMENTS_DATA_INVESTMENT_FUNDS = 'INSTRUMENTS_DATA_INVESTMENT_FUNDS'
}
export interface VariableConcept {
  name: string;
  value: string;
  type: VariableType;
}
