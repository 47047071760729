import { Component, Inject, ViewChild } from '@angular/core';
import {
  AutocompleteComponent,
  Option,
  TemplateModalComponent,
  TemplateModalData,
  ValidatorUtils
} from '@apiax/web-commons';
import { combineLatest, Observable, of } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupWeb } from '../../../../models/group-web';
import { CollectionsState } from '../../../../domain/stores/collections/collections.state';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Activity, AuthorizationService } from 'shared/core';
import {
  GetCollections,
  IsCollectionNameValid,
  IsCollectionNameValidExcludeSelf,
  LoadGroups
} from '../../../../domain/stores/collections/collections.action';
import { CollectionDetailModel, CollectionType } from '../../../../models/collection-model';
import { first } from 'rxjs/operators';
import { isEmpty } from 'lodash-es';

enum CollectionForm {
  Id = 'id',
  Name = 'name',
  IsShared = 'isShared',
  Groups = 'groups'
}

export interface CollectionModalOutput {
  id: string;
  name: string;
  sharedGroups: string[];
  isDelete: boolean;
}

export interface CollectionModalInput {
  collectionId: string;
}

@UntilDestroy()
@Component({
  selector: 'app-collection-modal',
  templateUrl: './collection-modal.component.html',
  styleUrls: ['./collection-modal.component.scss']
})
export class CollectionModalComponent {
  public static modalConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG,
    width: '600px'
  };

  public addToCollectionData: TemplateModalData = {
    title: 'Add to collection',
    hideCloseIcon: false,
    disableClose: false
  } as unknown as TemplateModalData;

  public editData: TemplateModalData = {
    title: 'Edit collection',
    hideCloseIcon: false,
    disableClose: false
  } as unknown as TemplateModalData;

  public form = new UntypedFormGroup({});
  public formNames = CollectionForm;

  public isLoading$ = of(false);
  public isLoadingCollections$: Observable<boolean>;
  public isLoadingGroups$: Observable<boolean>;

  private collections: CollectionDetailModel[] = [];
  private searchTimer?: ReturnType<typeof setTimeout>;

  public actionOption: Option = {
    value: 'addNewCollection',
    label: 'Add new collection'
  };

  public warningMessage: string;
  public isOptionSelected = false;
  public selectedGroups: string[] = [];
  public groups: GroupWeb[];
  isEdit = false;
  formControlName?: UntypedFormControl;
  collection: CollectionDetailModel = {
    id: undefined,
    name: undefined,
    type: CollectionType.USER_ONLY,
    sharedGroups: []
  };

  @ViewChild('autocomplete') apxAutocomplete: AutocompleteComponent | undefined;

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<CollectionModalComponent, CollectionModalOutput>,
    private authorizationService: AuthorizationService,
    @Inject(MAT_DIALOG_DATA) public inputData: CollectionModalInput
  ) {
    this.isEdit = !!inputData?.collectionId;
    this.warningMessage = '';

    this.isLoadingCollections$ = this.store.select(CollectionsState.isLoadingCollections);
    this.isLoadingGroups$ = this.store.select(CollectionsState.isLoadingGroups);

    combineLatest([this.isLoadingCollections$, this.isLoadingGroups$])
      .pipe(untilDestroyed(this))
      .subscribe(([collections, groups]) => {
        this.isLoading$ = of(collections || groups);
      });

    this.listenToGroups();
    this.fetchGroups();
    this.fetchCollection(inputData?.collectionId);
    this.initForm();
  }

  private listenToGroups() {
    this.store
      .select(CollectionsState.groups)
      .pipe(untilDestroyed(this))
      .subscribe(groups => {
        this.groups = this.authorizationService.isAllowed(Activity.CompaniesManagement)
          ? groups.map(g => {
            return {
              id: g.id,
              name: g.organizationName + ' - ' + g.name,
              organizationName: g.organizationName,
              organizationId: g.organizationId,
              organizationPhotoId: g.organizationPhotoId
            };
          })
          : groups;
      });
  }

  private fetchCollection(collectionId: string) {
    this.store
      .dispatch(new GetCollections([CollectionType.USER_ONLY, CollectionType.SHARED_BY_ME]))
      .pipe(first())
      .subscribe(() => {
        if (collectionId) {
          const collectionDetailModels = this.store.selectSnapshot(CollectionsState.collections);
          const selectedCollection = collectionDetailModels.find(l => l.id === collectionId);
          if (selectedCollection) {
            this.collection = selectedCollection;
            this.initForm();
          }
        }
      });
  }

  private fetchGroups() {
    let groupWebs = this.store.selectSnapshot(CollectionsState.groups);
    if (!groupWebs || groupWebs.length === 0) {
      this.store.dispatch(new LoadGroups());
    }
  }

  private initForm() {
    this.formControlName = new UntypedFormControl(this.collection.name, [
      Validators.required,
      ValidatorUtils.noWhitespaceValidator
    ]);
    this.form = new UntypedFormGroup({});
    this.form.addControl(CollectionForm.Id, new UntypedFormControl(this.collection.id, []));
    this.form.addControl(CollectionForm.Name, this.formControlName);
    this.form.addControl(
      CollectionForm.IsShared,
      new UntypedFormControl(this.collection.type !== 'USER_ONLY', [])
    );
    this.form.addControl(CollectionForm.Groups, new UntypedFormControl(this.collection.sharedGroups, []));

    if (this.isEdit) {
      this.form?.get(CollectionForm.IsShared)?.enable();
    } else {
      this.form?.get(CollectionForm.IsShared)?.disable();
    }

    this.form
      .get(CollectionForm.Name)
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe(value => {
        this.warningMessage = '';

        if (this.isEdit) {
          this.validateCollectionNameExcludeSelf(this.form?.get(CollectionForm.Id)?.value, value);
        } else {
          if (isEmpty(value)) {
            this.form?.get(CollectionForm.IsShared)?.disable();
          } else {
            this.form?.get(CollectionForm.IsShared)?.enable();
          }

          const collection = this.getCollectionById(this.form.get(CollectionForm.Id)?.value);

          if (collection && collection.name !== value) {
            this.apxAutocomplete?.clearInput();
            this.resetForm(true);
          }
        }
      });
  }

  private resetForm(disabledData: boolean) {
    this.form.get(CollectionForm.Id)?.setValue(undefined);
    this.form.get(CollectionForm.Name)?.setValue(undefined);
    this.form.get(CollectionForm.IsShared)?.setValue(false);
    this.form.get(CollectionForm.Groups)?.setValue(undefined);

    if (disabledData) {
      this.form?.get(CollectionForm.IsShared)?.disable();
      this.form?.get(CollectionForm.Groups)?.disable();
    } else {
      this.form?.get(CollectionForm.IsShared)?.enable();
      this.form?.get(CollectionForm.Groups)?.enable();
    }
  }

  private getCollectionById(id: string) {
    return this.collections.find(collection => collection.id === id);
  }

  private validateCollectionName(collectionName: string) {
    this.store
      .dispatch(new IsCollectionNameValid(collectionName))
      .pipe(first())
      .subscribe(() => {
        if (!this.store.selectSnapshot(CollectionsState.isCollectionNameValid)) {
          this.warningMessage = `There's already a collection with the same name.`;
        }
      });
  }

  private validateCollectionNameExcludeSelf(collectionId: string, collectionName: string) {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.searchTimer = setTimeout(() => {
      this.store
        .dispatch(new IsCollectionNameValidExcludeSelf({ collectionId: collectionId, name: collectionName }))
        .pipe(first())
        .subscribe(() => {
          if (!this.store.selectSnapshot(CollectionsState.isCollectionNameValidExcludeSelf)) {
            this.warningMessage = `There's already a collection with the same name.`;
          }
        });
    }, 250);
  }

  public getCollectionsData() {
    this.collections = this.store.selectSnapshot(CollectionsState.collections);
    return this.collections.map(collection => {
      return {
        value: collection.id,
        label: collection.name
      } as Option;
    });
  }

  public onCollectionSelection(selectedOption: Option) {
    this.isOptionSelected = true;
    this.warningMessage = '';

    if (selectedOption.value === this.actionOption.value) {
      this.validateCollectionName(selectedOption.label);
    }

    const collection = this.getCollectionById(selectedOption.value);

    if (collection) {
      this.form.get(CollectionForm.Id)?.setValue(collection.id);
      this.form.get(CollectionForm.Name)?.setValue(collection.name);
      this.form.get(CollectionForm.IsShared)?.setValue(!isEmpty(collection.sharedGroups));
      this.form.get(CollectionForm.Groups)?.setValue(collection.sharedGroups);

      this.form?.get(CollectionForm.IsShared)?.disable();
      this.form?.get(CollectionForm.Groups)?.disable();
    } else {
      this.resetForm(false);
      const name = selectedOption.label !== this.actionOption.label ? selectedOption.label : '';
      this.form.get(CollectionForm.Name)?.setValue(name);
    }
  }

  public onDropDownClose() {
    if (!this.isOptionSelected) {
      this.apxAutocomplete?.clearInput();
      this.resetForm(true);
    }
    this.isOptionSelected = false;
  }

  public shouldSeeSharedContent() {
    return this.form?.get(CollectionForm.IsShared)?.value;
  }

  public invalidSharedOptions() {
    if (Array.isArray(this.form?.get(CollectionForm.Groups)?.value)) {
      this.selectedGroups = this.form?.get(CollectionForm.Groups)?.value;
    }
    const isGroupValueInvalid =
      isEmpty(this.selectedGroups) || !Array.isArray(this.form?.get(CollectionForm.Groups)?.value);
    return this.shouldSeeSharedContent() && isGroupValueInvalid;
  }

  public saveCollection() {
    this.dialogRef.close({
      id: this.form?.get(CollectionForm.Id)?.value,
      name: this.form?.get(CollectionForm.Name)?.value,
      sharedGroups: this.form?.get(CollectionForm.Groups)?.value,
      isDelete: false
    });
  }

  public sharedClick() {
    this.form.get(CollectionForm.Groups)?.setValue([]);
  }

  public performDelete($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    this.dialogRef.close({
      id: this.form?.get(CollectionForm.Id)?.value,
      name: this.collection.name || '',
      sharedGroups: this.collection.sharedGroups || [],
      isDelete: true
    });
  }

}
