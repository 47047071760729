<div class="my-collection-content-wrapper">
  <apx-loading-spinner [isLoading]='isLoading$'>
    <ng-template [ngIf]='totalData === 0' [ngIfElse]='table'>
      <div class='no-terms-message-container'>
        Start building your collections by adding terms under the tab "My terms"
      </div>
    </ng-template>
    <ng-template #table>
      <apx-data-table
        [data]='data'
        [totalData]='totalData'
        [columns]='columns'
        [pageSize]='pageSize'
        [menuActions]='menuActions'
        [noResultsMessage]="'No terms found for this collection.'"
        [idFetcher]='idFetcher'
        [allowSelection]='true'
        [showDownload]="true"
        [downloadPermission]="downloadPermission"
        [downloadTooltip]="'Download collection list'"
        [isDownloading$]="isDownloading$"
        (onFetch)='onFetch($event)'
        [entity]="'Term'"
        [rowIcon]='rowIcon'
        [initialLoadingState]='false'
        [sortBy]='defaultSortBy'
        [sortOrder]='defaultSortOrder'
        [currentUserPermissions]='currentUserPermissions'
        [saveToStorage]="saveToStorageStore"
        [loadFromStorage]="loadFromStorageStore"
        (onDownload)="downloadData($event)"
        (onSelect)="onSelect($event)"
      ></apx-data-table>
    </ng-template>
  </apx-loading-spinner>
</div>
