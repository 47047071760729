import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanMatch,
  Data,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { Activity, AuthorizationService } from 'shared/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard implements CanMatch {

  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService,
    protected authorizationService: AuthorizationService
  ) {
    super(router, keycloakAngular);
  }

  canMatch(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        this.authenticated = await this.keycloakAngular.isLoggedIn();
        this.roles = await this.keycloakAngular.getUserRoles(true);

        const result = await this.checkAccessAllowed(route.data);
        resolve(result);
      } catch (error) {
        reject('An error happened during access validation. Details:' + error);
      }
    });
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.checkAccessAllowed(route.data);
  }

  checkAccessAllowed(data: Data): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!this.authenticated) {
        this.authorizationService.currentUser.next(null);
        this.keycloakAngular
          .login()
          .then(() => resolve(true))
          .catch(() => resolve(false));
      }

      if (!this.authorizationService.currentUser.getValue()) {
        this.authorizationService.currentUser.next({
          activities: this.keycloakAngular.getKeycloakInstance().tokenParsed['activities'],
          email: this.keycloakAngular.getKeycloakInstance().tokenParsed['email'],
          organizationId: this.keycloakAngular.getKeycloakInstance().tokenParsed['oid'],
          photoId: this.keycloakAngular.getKeycloakInstance().tokenParsed['photoId'],
          id: this.keycloakAngular.getKeycloakInstance().tokenParsed['uid'],
          firstName: this.keycloakAngular.getKeycloakInstance().tokenParsed['firstName'],
          lastName: this.keycloakAngular.getKeycloakInstance().tokenParsed['lastName'],
          organization: {
            id: this.keycloakAngular.getKeycloakInstance().tokenParsed['oid'],
            name: this.keycloakAngular.getKeycloakInstance().tokenParsed['oname']
          }
        });
      }

      const activities = this.authorizationService.currentUser.getValue().activities;
      return resolve(activities.includes(Activity.PlatformAccess));
    });
  }
}
