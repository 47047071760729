import { ConceptHierarchy } from './concept-hierarchy';

export class ConceptHierarchyFlatNode {
  public isExpanded: boolean;
  public isLoading: boolean;
  public tooltip: string;
  public canShow = true;

  private _level: number | undefined = undefined;

  public static flattener(
    acc: ConceptHierarchyFlatNode[],
    concept: ConceptHierarchy
  ): ConceptHierarchyFlatNode[] {
    acc.push(new ConceptHierarchyFlatNode(concept));
    if (concept.childrenConcepts) {
      return concept.childrenConcepts.reduce(ConceptHierarchyFlatNode.flattener, acc);
    }
    return acc;
  }

  constructor(public concept: ConceptHierarchy) {
    this.calculateLevel();
    this.tooltip = this.getTooltip();
  }

  private getTooltip() {
    return `${this.concept.term} (${this.jurisdictions}${
      this.concept.ownerName?.length > 0 ? ` - ${this.concept.ownerName}` : ''
    })${this.categories?.length > 0 ? ` - ${this.categories}` : ''}`;
  }

  get key(): string {
    return this.concept.uid;
  }

  get id() {
    return this.concept.id;
  }

  get hasChildrenLoaded() {
    return this.concept.hasChildrenLoaded;
  }

  get hasSiblingsLoaded() {
    return this.concept.hasSiblingsLoaded;
  }

  get hasChildren() {
    return this.concept?.childrenConcepts?.length > 0;
  }

  get level(): number {
    return this._level;
  }

  get jurisdictions(): string {
    return this.concept.jurisdictions?.join(', ') || '';
  }

  get categories(): string {
    return this.concept.categories?.join(', ') || '';
  }

  private calculateLevel() {
    let level = -1;
    let traverse = this.concept;
    do {
      ++level;
      traverse = traverse?.parent;
      if (traverse === undefined) {
        break;
      }
    } while (level < 100);
    this._level = level;
  }
}
