import {Config} from 'shared/core';

Config.PLATFORM_TARGET = Config.PLATFORMS.WEB;


if (win()['__config'].target === 'desktop') {
  Config.PLATFORM_TARGET = Config.PLATFORMS.DESKTOP;
}

// For AoT compilation to work:
export function win() {
  return window;
}

export function cons() {
  return console;
}
