import { SearchCriteria } from '@apiax/web-commons';
import { UpdateConceptRequest } from 'generated/v3/model/updateConceptRequest';
import { GetEditorConceptHierarchyRequest, RuleSetFamily } from '../../../../generated/v3';
import { Concept, ConceptRelation, ConceptTranslation } from '../../../models';
import { GoBackType } from './taxonomy-concept.state';
import RelationTypeEnum = GetEditorConceptHierarchyRequest.RelationTypeEnum;

export enum TaxonomyConceptAction {
  LoadTranslationLanguages = '[Load Translation Languages] Loads all available languages for translations',
  CheckDeployedUsages = '[Check Deployed Usages] Checks if a given concept has deployed usages',
  LoadConcept = '[Load Concept] Loads a concept',
  ResetConcept = '[Reset Concept] Resets store concept',
  CreateConcept = '[Create Concept] Creates a new concept',
  Clear = '[Clear] Clear all state',
  UpdateConcept = '[Update Concept] Updates an existing concept',
  CloneConcept = '[Clone Concept] Clones an existing concept',
  SearchUsages = '[Search Usages] Performs a search of usages',
  ExportUsages = '[Export Usages] Performs a export of usages',
  SearchChangeLog = '[Search Change Log] Performs a search of change log',
  LoadMoreChangeLog = '[Load More Change Log] Loads more change log',
  LoadConceptTranslation = '[Load Concept Translation] Load Concept Translation',
  AddConceptTranslation = '[Add Concept Translation] Add Concept Translation',
  UpdateSelectedLanguage = '[Update Selected language] Update Selected Language',
  UpdateLanguageTermAndDefinition = '[Update Language Term and Definition] Update Language Term and Definition',
  UpdateConceptTermAndDefinition = '[Update Concept Term and Definition] Update Concept Term and Definition',
  UpdateIsDirty = '[Update Concept isDirty] Update Concept isDirty',
  DeleteConceptTranslation = '[Delete Concept Translation] Update Concept Translation',
  GetConceptRelations = '[Get Concept Relations] gets the relations of a concept',
  UpdateConceptRelations = '[Update Concept Relations] updates the relations of a concept',
  UpdateConceptsRelationsLocally = '[Update Concept Relations Locally] updates the relations of a concept locally',
  UpdateConceptLocally = '[Update Concept Locally] update concept locally',
  LoadConceptHierarchy = '[Load Concept Hierarchy] loads the concepts hierarchy',
  LoadAndAppendNarrowerConceptHierarchy = '[Loads And Appends Narrower Concept Hierarchy] loads and appends narrower concept hierarchy entries',
  LoadAndAppendSiblingsConceptHierarchy = '[Loads And Appends Siblings Concept Hierarchy] loads and appends sibling concepts to the concept hierarchy',
  SetGoBackType = '[Set Go Back Type] Sets the go back type to perform a correct back when in concept view',
  LoadVariables = '[Load Variables] Loads List Of Variables',

}

export class LoadTranslationLanguages {
  public static readonly type = TaxonomyConceptAction.LoadTranslationLanguages;
}

export class CheckDeployedUsages {
  public static readonly type = TaxonomyConceptAction.CheckDeployedUsages;

  constructor(public conceptId: string) {
  }
}

export class LoadConceptTranslation {
  public static readonly type = TaxonomyConceptAction.LoadConceptTranslation;

  constructor(
    public payload: {
      existingTranslations: { [key: string]: ConceptTranslation };
      selectedTranslation: string;
    }
  ) {
  }
}

export class AddConceptTranslation {
  public static readonly type = TaxonomyConceptAction.AddConceptTranslation;

  constructor(public payload: { selectedLanguage: string }) {
  }
}

export class DeleteConceptTranslation {
  public static readonly type = TaxonomyConceptAction.DeleteConceptTranslation;
}

export class UpdateSelectedLanguage {
  public static readonly type = TaxonomyConceptAction.UpdateSelectedLanguage;

  constructor(public payload: { selectedLanguage: string }) {
  }
}

export class UpdateLanguageTermAndDefinition {
  public static readonly type = TaxonomyConceptAction.UpdateLanguageTermAndDefinition;

  constructor(public payload: { term?: string; definition?: string }) {
  }
}

export class UpdateIsDirty {
  public static readonly type = TaxonomyConceptAction.UpdateIsDirty;

  constructor(public payload: { isDirty: boolean }) {
  }
}

export class UpdateConceptTermAndDefinition {
  public static readonly type = TaxonomyConceptAction.UpdateConceptTermAndDefinition;

  constructor(public payload: { term?: string; definition?: string }) {
  }
}

export class LoadConcept {
  public static readonly type = TaxonomyConceptAction.LoadConcept;

  constructor(public conceptId: string) {
  }
}

export class ClearTaxonomyConceptState {
  public static readonly type = TaxonomyConceptAction.Clear;
}

export class ResetConcept {
  public static readonly type = TaxonomyConceptAction.ResetConcept;
}

export class CreateConcept {
  public static readonly type = TaxonomyConceptAction.CreateConcept;

  constructor(
    public payload: {
      termId: string;
      term: string;
      definition: string;
      owner: string;
      ruleSetFamilyList: RuleSetFamily[];
      jurisdictions: string[];
      categoryTags: string[];
      isPrivate: boolean;
    }
  ) {
  }
}

export class UpdateConcept {
  public static readonly type = TaxonomyConceptAction.UpdateConcept;

  constructor(
    public payload: {
      id: string;
      termId: string;
      term: string;
      definition: string;
      owner: string;
      ruleSetFamilyList: RuleSetFamily[];
      jurisdictions: string[];
      categories: string[];
      isPrivate: boolean;
      state: UpdateConceptRequest.StateEnum;
      updatedAt: string;
      translations?: { [key: string]: ConceptTranslation };
    }
  ) {
  }
}

export class UpdateConceptLocally {
  public static readonly type = TaxonomyConceptAction.UpdateConceptLocally;

  constructor(
    public payload: {
      concept: Concept;
      isDirty: boolean;
      isTermDirty: boolean;
      isDefinitionDirty: boolean;
    }
  ) {
  }
}

export class CloneConcept {
  public static readonly type = TaxonomyConceptAction.CloneConcept;

  constructor(
    public payload: {
      id: string;
      termId: string;
      owner: string;
      ruleSetFamilyList: RuleSetFamily[];
      jurisdictions: string[];
    }
  ) {
  }
}

export class SearchUsages {
  public static readonly type = TaxonomyConceptAction.SearchUsages;

  constructor(
    public payload: {
      searchCriteria: SearchCriteria;
      conceptId: string;
    }
  ) {
  }
}

export class ExportUsages {
  public static readonly type = TaxonomyConceptAction.ExportUsages;

  constructor(
    public payload: {
      searchCriteria: SearchCriteria;
      conceptId: string;
    }
  ) {
  }
}

export class SearchChangeLog {
  public static readonly type = TaxonomyConceptAction.SearchChangeLog;

  constructor(
    public payload: {
      conceptId: string;
      pageNumber: number;
      pageSize: number;
    }
  ) {
  }
}

export class LoadMoreChangeLog {
  public static readonly type = TaxonomyConceptAction.LoadMoreChangeLog;

  constructor(
    public payload: {
      conceptId: string;
      pageNumber: number;
      pageSize: number;
    }
  ) {
  }
}

export class GetConceptRelations {
  public static readonly type = TaxonomyConceptAction.GetConceptRelations;

  constructor(
    public payload: {
      conceptId: string;
    }
  ) {
  }
}

export class UpdateConceptRelations {
  public static readonly type = TaxonomyConceptAction.UpdateConceptRelations;

  constructor(
    public payload: {
      conceptId: string;
      relatedRelations: ConceptRelation[];
      narrowerRelations: ConceptRelation[];
      broaderRelations: ConceptRelation[];
    }
  ) {
  }
}

export class UpdateConceptsRelationsLocally {
  public static readonly type = TaxonomyConceptAction.UpdateConceptsRelationsLocally;

  constructor(
    public payload: {
      conceptRelationChanged: boolean;
      relatedRelations: ConceptRelation[];
      narrowerRelations: ConceptRelation[];
      broaderRelations: ConceptRelation[];
    }
  ) {
  }
}

export class LoadConceptHierarchy {
  public static readonly type = TaxonomyConceptAction.LoadConceptHierarchy;

  constructor(
    public payload: {
      conceptId: string;
      relationType: RelationTypeEnum;
      depth?: number;
    }
  ) {
  }
}

export class LoadAndAppendNarrowerConceptHierarchy {
  public static readonly type = TaxonomyConceptAction.LoadAndAppendNarrowerConceptHierarchy;

  constructor(
    public payload: {
      conceptId: string;
    }
  ) {
  }
}

export class LoadAndAppendSiblingsConceptHierarchy {
  public static readonly type = TaxonomyConceptAction.LoadAndAppendSiblingsConceptHierarchy;

  constructor(public payload: { conceptId: string; parentConceptId: string }) {
  }
}

export class SetGoBack {
  public static readonly type = TaxonomyConceptAction.SetGoBackType;

  constructor(public payload: { type: GoBackType }) {
  }
}

export class LoadVariables {
  public static readonly type = TaxonomyConceptAction.LoadVariables;
}
