import { Routes } from '@angular/router';
import { AuthGuard } from 'shared/app/guards';

export const ROUTES: Routes = [
  {
    canMatch: [AuthGuard],
    path: '',
    loadChildren: () => import('./presentation/pages/taxonomy-index/taxonomy.module')
      .then(m => m.TaxonomyModule)
  }
];
