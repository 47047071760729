<div class='taxonomy-index-wrapper'>
  <div class='taxonomy-index-header' [ngClass]="taxonomyUtilsService.canCreateConcept() ? 'max' :
  'min'">
    <div class='taxonomy-index-header-title'>Taxonomy</div>
    <ng-template [ngIf]="taxonomyUtilsService.canCreateConcept()">
      <div class='taxonomy-index-header-container'>
        <apx-action-button
          [colorClass]="'primary-action'"
          [loadingName]="'Create new taxonomy term'"
          [name]="'Create new taxonomy term'"
          [method]='onCreate()'></apx-action-button>
      </div>
    </ng-template>
  </div>
  <div class='taxonomy-index-content'>
    <mat-tab-group animationDuration='0ms' (selectedTabChange)='tabChanged($event)'
      [(selectedIndex)]='taxonomyUtilsService.rootSelectedTabIndex'
                   mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label='My terms'>
        <ng-template matTabContent>
          <app-taxonomy-search-terms></app-taxonomy-search-terms>
        </ng-template>
      </mat-tab>
      <mat-tab label='My collections' *ngIf='taxonomyUtilsService.hasTaxonomyMyCollectionAccess()'>
        <ng-template matTabContent>
          <app-my-collections></app-my-collections>
        </ng-template>
      </mat-tab>
      <mat-tab label='Search on rules'>
        <ng-template matTabContent>
          <app-taxonomy-search-on-rules></app-taxonomy-search-on-rules>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf='taxonomyUtilsService.hasTaxonomyLatestChangesAccess()' label='Latest changes'>
        <ng-template matTabContent>
          <app-taxonomy-latest-changes></app-taxonomy-latest-changes>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf='taxonomyUtilsService.hasTaxonomyExportDataAccess()' label='Export data'>
        <ng-template matTabContent>
          <app-taxonomy-export-data></app-taxonomy-export-data>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf='false && taxonomyUtilsService.hasTechnicalAdministration()' label='Control Panel'>
        <ng-template matTabContent>
          Control Panel
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
