import { FilterData, SearchCriteria, StorageData } from '@apiax/web-commons';
import { ConceptSearchMode } from '../../mappers/taxonomy-search-terms-mapper';

export enum TaxonomyMyTermsAction {
  Search = '[Search] perform a search for concepts',
  Export = '[Export] perform a export for concepts',
  SetSearchMode = '[Set search mode] changes the search mode',
  SetStorageData = '[Set Storage Data] save the storage data',
  SetFilterStorageData = '[Set Filter Storage Data] save the filter storage data',
  SetSearchCriteriaText = '[Set Search Criteria Text] sets the search criteria text'
}

export class Search {
  public static readonly type = TaxonomyMyTermsAction.Search;

  constructor(public searchCriteria: SearchCriteria) {}
}

export class Export {
  public static readonly type = TaxonomyMyTermsAction.Export;

  constructor() {}
}

export class SetSearchMode {
  public static readonly type = TaxonomyMyTermsAction.SetSearchMode;

  constructor(public searchMode: ConceptSearchMode) {}
}

export class SetStorageData {
  public static readonly type = TaxonomyMyTermsAction.SetStorageData;

  constructor(public storageData: StorageData) {}
}

export class SetFilterStorageData {
  public static readonly type = TaxonomyMyTermsAction.SetFilterStorageData;

  constructor(public storageData: Map<string, FilterData>) {}
}
