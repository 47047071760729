import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CollectionType } from '../my-collections.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  BasicModalComponent,
  Column,
  DataTableComponent,
  DialogType,
  MenuAction,
  MenuActionInput,
  NotificationService,
  RowIcon,
  SearchCriteria,
  SortOrder,
  StorageData
} from '@apiax/web-commons';
import { Activity, AuthorizationService } from 'shared/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CollectionModalComponent,
  CollectionModalOutput
} from '../../../../shared-components/modals/collection-modal/collection-modal.component';
import { first, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import {
  DeleteCollection,
  EditCollection,
  ExportCollection,
  RemoveFromCollection,
  SetCollectionsStorageData,
  SetDataByCollection,
  SetKeepCollectionState
} from '../../../../../domain/stores/collections/collections.action';
import { CollectionsState } from '../../../../../domain/stores/collections/collections.state';
import { isEmpty } from 'lodash-es';
import { ConceptTableUtils } from '../../../../shared-components/utils/concept-table-utils';
import { CollectionsService, SearchConceptsByCollectionRequest } from '../../../../../../generated/v3';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CollectionsMapper } from '../../../../../domain/mappers/collections-mapper';
import { Router } from '@angular/router';
import { ConceptCollection } from '../../../../../models/concept-collection';

@UntilDestroy()
@Component({
  selector: 'app-collection-content',
  templateUrl: './collection-content.component.html',
  styleUrls: ['./collection-content.component.scss']
})
export class CollectionContentComponent implements OnInit, AfterViewInit {
  @Input()
  public set selectedCollectionType(value: CollectionType | undefined) {
    this._selectedCollectionType = value;
    this.setMenuActionsForSelectedCollectionType();
  }

  @Input()
  public set collectionId(value: string | undefined) {
    const keepCollectionState = this.store.selectSnapshot(CollectionsState.keepCollectionState);
    this._collectionId = value;
    if (keepCollectionState) {
      // This is the use case when user clicks to see the concept (term-editor) and then returns.
      // We must NOT clean the collection state
      this.store.dispatch(new SetKeepCollectionState(false));
    } else {
      this.table?.clearSelection();
      this.table?.resetSort();
      this.updateData();
      this.isLoadingData$.next(true);
    }

  }

  _selectedCollectionType?: CollectionType;

  @Output()
  collectionChanged: EventEmitter<boolean> = new EventEmitter();

  @Output()
  collectionDeleted: EventEmitter<boolean> = new EventEmitter();

  public data: ConceptCollection[] = [];
  public totalData = 0;
  public readonly pageSize = 10;
  private _collectionId?: string;

  defaultSortBy = 'termId';
  defaultSortOrder = SortOrder.ASC;

  isLoadingData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  currentUserPermissions = new Map<Activity, boolean>();
  rowIcon: RowIcon;

  public columns: Column[];

  private searchCriteria: SearchCriteria = {
    page: 0,
    pageSize: this.pageSize
  };

  public menuActions: MenuAction[] = [
    {
      permissions: [],
      icon: 'delete',
      iconClass: 'material-symbols-outlined',
      cssClass: 'plain-action critical-plain',
      title: 'Remove from <i>My collections</i>',
      tooltip: 'Remove from My collections',
      actionFunction: (input: MenuActionInput) => {
        return this.remove(input);
      }
    }
  ];

  public readonly myCollectionMenuActions: MenuAction[] = [
    {
      permissions: [],
      icon: 'edit',
      iconClass: 'material-symbols-outlined',
      cssClass: 'plain-action secondary-plain',
      title: 'Edit collection',
      tooltip: 'Edit collection',
      requiresSelectedRows: false,
      actionFunction: () => {
        return this.editCollection();
      }
    },
    {
      permissions: [],
      icon: 'delete',
      iconClass: 'material-symbols-outlined',
      cssClass: 'plain-action critical-plain',
      title: 'Remove from collection',
      tooltip: 'Remove from collection',
      actionFunction: (input: MenuActionInput) => {
        return this.remove(input);
      }
    }
  ];

  public readonly mySharedCollectionMenuActions: MenuAction[] = [
    {
      permissions: [],
      icon: 'edit',
      iconClass: 'material-symbols-outlined',
      cssClass: 'plain-action secondary-plain',
      title: 'Edit collection',
      tooltip: 'Edit collection',
      requiresSelectedRows: false,
      actionFunction: () => {
        return this.editCollection();
      }
    },
    {
      permissions: [],
      icon: 'delete',
      iconClass: 'material-symbols-outlined',
      cssClass: 'plain-action critical-plain',
      title: 'Remove from collection',
      tooltip: 'Remove from collection',
      actionFunction: (input: MenuActionInput) => {
        const selectedCollectionId = this.store.selectSnapshot(CollectionsState.selectedCollection);
        const sharedCollections = this.store.selectSnapshot(CollectionsState.mySharedCollections);
        const collectionName =
          sharedCollections.find(l => l.id == selectedCollectionId)?.name || '';

        const message = `Are you sure you want to remove these terms from the shared collection ${collectionName}?
        These changes will affect all users that have access to this collection.`;

        const dialog = this.dialog.open(BasicModalComponent, {
          ...BasicModalComponent.DEFAULT_CONFIG,
          data: {
            title: 'Remove from shared collection',
            message: message,
            actionLabel: 'Remove from collection',
            showCancel: false,
            hideClose: false,
            disableClose: false,
            displayDontShowAgain: false,
            type: DialogType.INFO
          }
        });

        return dialog.afterClosed().pipe(
          switchMap(r => {
            if (r?.result) {
              return this.remove(input);
            }
            return of(false);
          })
        );
      }
    }
  ];

  public downloadPermission = Activity.ViewTerms;
  public isDownloading$ = new BehaviorSubject(false);


  @ViewChild(DataTableComponent) table: DataTableComponent<never> | undefined;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private store: Store,
    private collectionsService: CollectionsService,
    private authorizationService: AuthorizationService
  ) {
    this.buildColumns();
    this.getCurrentUserPermissions();
  }

  private getCurrentUserPermissions() {
    this.authorizationService.currentUserPermissions.pipe(first(), untilDestroyed(this)).subscribe(permissions => {
      this.currentUserPermissions = permissions;
    });
  }

  ngAfterViewInit(): void {
    this.table?.triggerOnFetch();
  }

  ngOnInit(): void {
    this.setMenuActionsForSelectedCollectionType();
    this.initSubscriptions();
  }

  private initSubscriptions() {
    this.store.select(CollectionsState.searchCollections)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.data = results));

    this.store.select(CollectionsState.totalSearchResults)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.totalData = results));

    this.store.select(CollectionsState.isLoadingSearchCollections)
      .pipe(untilDestroyed(this))
      .subscribe(result => (this.isLoadingData$.next(result)));
  }


  editCollectionCallback() {
    return (callback: () => void) => {
      this.editCollection().subscribe();
      callback();
    };
  }

  private remove(actionInput: MenuActionInput): Observable<boolean> {
    if (!this._collectionId) {
      return of(false);
    }
    return this.store
      .dispatch(
        new RemoveFromCollection({
          collectionId: this._collectionId,
          includedIds: actionInput.selections,
          excludedIds: actionInput.exclusions
        })
      )
      .pipe(
        first(),
        tap(() => {
          this.notificationService.showSimpleAlert(
            `All changes have been successfully saved`,
            'success'
          );
          this.table?.clearSelection();
          this.updateData();
        }),
        switchMap(() => {
          return of(true);
        })
      );
  }

  public onFetch(searchCriteria: SearchCriteria) {
    this.searchCriteria = searchCriteria;
    this.updateData();
  }

  public idFetcher = (value: any) => {
    return value.id;
  };

  public downloadData(searchCriteria: SearchCriteria) {
    this.store.dispatch(new ExportCollection({
      collectionId: this._collectionId,
      sortBy: searchCriteria.sortBy,
      sortDirection: searchCriteria.sortOrder
    }))
      .pipe(first())
      .subscribe(() => {
        this.isDownloading$.next(false);
        this.notificationService.showSimpleAlert(
          'Collection list downloaded successfully',
          'success'
        );
      });
  }

  private setMenuActionsForSelectedCollectionType() {
    if (this._selectedCollectionType) {
      switch (this._selectedCollectionType) {
        case CollectionType.myCollections:
          this.menuActions = this.myCollectionMenuActions;
          break;
        case CollectionType.mySharedCollections:
          this.menuActions = this.mySharedCollectionMenuActions;
          break;
        case CollectionType.sharedCollectionsWithMe:
          this.menuActions = [];
          break;
      }
    } else {
      this.menuActions = [];
    }
  }

  editCollection(): Observable<boolean> {
    return this.dialog
      .open(CollectionModalComponent, {
        ...CollectionModalComponent.modalConfig,
        data: {
          collectionId: this._collectionId
        }
      })
      .afterClosed()
      .pipe(
        first(),
        switchMap((response: CollectionModalOutput) => {
          if (response) {
            if (response.isDelete) {
              return this.deleteCollection(response.id).pipe(
                switchMap(() => {
                  this.notificationService.showSimpleAlert(
                    `The collection "${response.name}" was successfully deleted`,
                    'success'
                  );
                  this.collectionDeleted.next(true);
                  return of(false);
                })
              );
            } else {
              return this.saveEditedCollection(response).pipe(
                switchMap(() => {
                  this.notificationService.showSimpleAlert(
                    `All changes have been successfully saved`,
                    'success'
                  );
                  this.collectionChanged.next(true);
                  if (this.table?.page) {
                    this.table.page = 0;
                  }
                  return of(true);
                })
              );
            }
          } else {
            return of(false);
          }
        })
      );
  }

  private saveEditedCollection(response: CollectionModalOutput) {
    return this.store.dispatch(
      new EditCollection({
        id: response.id,
        name: response.name,
        sharedGroups: response.sharedGroups
      })
    );
  }

  private deleteCollection(id: string) {
    return this.store.dispatch(new DeleteCollection(id));
  }

  private buildColumns() {
    this.columns = [
      {
        prop: 'term',
        label: 'Term',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: true,
        size: 2.5
      },
      {
        prop: 'termId',
        label: 'Term ID',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: true,
        size: 2.5
      },
      {
        prop: 'rawDefinition',
        label: 'Definition',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: true,
        size: 5
      },
      {
        prop: 'ruleSetRefsLabel',
        label: 'Rule set',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'jurisdictionsLabel',
        label: 'Jurisdiction',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: true,
        size: 2
      },
      {
        prop: 'ownerLabel',
        label: 'Owner',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: true,
        size: 2
      },
      {
        prop: 'categoriesLabel',
        label: 'Category',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: true,
        size: 2
      },
      {
        prop: 'isPrivate',
        label: 'Privacy',
        transformValue: value => ConceptTableUtils.getPrivacyIcon(value),
        tooltip: value => ConceptTableUtils.getPrivacyToolTip(value),
        sortable: true,
        extraClasses: 'center-aligned',
        size: 1.5
      },
      {
        prop: 'state',
        label: 'Status',
        transformValue: value => ConceptTableUtils.getStatusLabel(value),
        tooltip: value => value,
        sortable: true,
        extraClasses: 'center-aligned',
        size: 2
      }
    ];
  }

  private updateData() {
    if (this._collectionId) {
      const request: SearchConceptsByCollectionRequest = {
        collectionId: this._collectionId,
        page: this.searchCriteria.page,
        pageSize: this.searchCriteria.pageSize,
        sortBy: CollectionsMapper.mapToSortBy(this.searchCriteria.sortBy),
        sortDirection: CollectionsMapper.mapToSortOrder(this.searchCriteria.sortOrder),
        selectedIds: this.searchCriteria.selectedIds,
        excludedIds: this.searchCriteria.excludedIds
      };
      this.store.dispatch(new SetDataByCollection(request));
    } else {
      this.data = [];
      this.totalData = 0;
    }
  }

  onSelect(row) {
    this.store.dispatch(new SetKeepCollectionState(true));
    this.router.navigate(['concept', 'term-editor'], {
      queryParams: { conceptId: row.id }
    });
  }

  saveToStorageStore = (data: StorageData) => {
    this.store.dispatch(new SetCollectionsStorageData(data));
  };

  loadFromStorageStore = (): StorageData => {
    return this.store.selectSnapshot(CollectionsState.storageData);
  };
}
