<mat-form-field class="search-input-autocomplete" matAutocompleteOrigin #origin="matAutocompleteOrigin">
    <input type="text" [placeholder]="'Search Concept'" matInput [formControl]="control" [matAutocomplete]="auto"
           [matAutocompleteConnectedTo]="origin">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading" class="progressBar"></mat-progress-bar>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="autoCompleteSelected($event)" class="autocomplete-search-results">
        <mat-option *ngFor="let item of results ; let i = index" [value]="item">
            <div class="suggestion" [ngClass]="{'pair': i%2}" tabindex="{{i}}">
                <div>
                    <span class="term">{{ item.term }}</span>
                    <div>
                        <small class="jurisdictions">{{ item.jurisdictionsLabel }}</small>
                        <small class="source"><span class="separator">|</span>{{ item.ownerLabel }}</small>
                    </div>
                </div>
                <small class="suggestion-definition">{{ item.rawDefinition }}</small>
                <div>
                </div>
            </div>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
