import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CollapsableTreeComponent, CollapsableTreeEntry } from '@apiax/web-commons';
import { Store } from '@ngxs/store';
import { CollectionsState } from '../../../../domain/stores/collections/collections.state';
import {
  GetMyCollections, SetCollectionsStorageData,
  SetMyCollectionsData,
  SetSelectedCollection
} from '../../../../domain/stores/collections/collections.action';
import { first } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEmpty } from 'lodash-es';

export enum CollectionType {
  myCollections = 'myCollections',
  mySharedCollections = 'mySharedCollections',
  sharedCollectionsWithMe = 'sharedCollectionsWithMe'
}

@UntilDestroy()
@Component({
  selector: 'app-my-collections',
  templateUrl: './my-collections.component.html',
  styleUrls: ['./my-collections.component.scss']
})
export class MyCollectionsComponent implements OnInit {
  isLoading$: Observable<boolean> = of(false);
  collectionEntries: CollapsableTreeEntry[] = [
    {
      value: 'collections',
      label: 'Terms',
      infoText: 'Here you see all the terms from "My collections"',
      selectable: true
    },
    {
      value: 'myCollections',
      label: 'My collections',
      selectable: true
    },
    {
      value: 'mySharedCollections',
      label: 'My shared collections',
      selectable: true
    },
    {
      value: 'sharedCollectionsWithMe',
      label: 'Collections shared with me',
      selectable: true
    }
  ];
  selectedEntry = this.collectionEntries[0].value;
  collectionId?: string;
  selectedCollectionType?: CollectionType;
  emptyCollectionTypeMessage = '';

  @ViewChild('collectionsMenu') collectionsMenu: CollapsableTreeComponent | undefined;

  isInitialized = false;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {
    this.isLoading$ = this.store.select(CollectionsState.isLoading);

    this.store
      .dispatch(new GetMyCollections())
      .pipe(first())
      .subscribe(() => (this.isInitialized = true));
  }

  ngOnInit() {
    this.refreshCollection();

    this.subscribeToMyCollection();
    this.subscribeToMySharedCollections();
    this.subscribeToSharedWithMeCollections();
  }

  private refreshCollection() {
    const savedCollectionId = this.store.selectSnapshot(CollectionsState.selectedCollection);

    if (isEmpty(savedCollectionId)) {
      this.store.dispatch(new SetMyCollectionsData(
        {
          page: 0,
          pageSize: 10
        }
      ));
      this.selectAndExpandCollection(0);
    } else {
      this.collectionId = savedCollectionId;

      const myCollections = this.store.selectSnapshot(CollectionsState.myCollections);
      const mySharedCollections = this.store.selectSnapshot(CollectionsState.mySharedCollections);
      const collectionsSharedWithMe = this.store.selectSnapshot(
        CollectionsState.collectionsSharedWithMe
      );

      if (this.collectionId === 'collections') {
        this.selectAndExpandCollection(0);
      } else if (
        myCollections &&
        myCollections.find(collection => collection.id === this.collectionId)
      ) {
        this.selectAndExpandCollection(1);
        this.selectedCollectionType = CollectionType.myCollections;
      } else if (
        mySharedCollections &&
        mySharedCollections.find(collection => collection.id === this.collectionId)
      ) {
        this.selectAndExpandCollection(2);
        this.selectedCollectionType = CollectionType.mySharedCollections;
      } else if (
        collectionsSharedWithMe &&
        collectionsSharedWithMe.find(collection => collection.id === this.collectionId)
      ) {
        this.selectAndExpandCollection(3);
        this.selectedCollectionType = CollectionType.sharedCollectionsWithMe;
      } else {
        this.selectAndExpandCollection(0);
      }
    }
  }

  private selectAndExpandCollection(index: number) {
    this.selectedEntry = this.collectionEntries[index].value;
    this.collectionEntries[index].selected = true;
    this.collectionEntries[index].expanded = true;
    this.collectionEntries = [...this.collectionEntries];
  }

  private subscribeToMyCollection() {
    this.store
      .select(CollectionsState.myCollections)
      .pipe(untilDestroyed(this))
      .subscribe(collection => {
        const collapsableTreeEntry = this.collectionEntries.find(
          entry => entry.value === 'myCollections'
        );
        if (collapsableTreeEntry) {
          collapsableTreeEntry.children = collection.map(collectionItem => {
            return {
              value: collectionItem.id,
              label: collectionItem.name,
              selectable: true,
              selected: collectionItem.id === this.collectionId,
              parent: collapsableTreeEntry
            } as CollapsableTreeEntry;
          });
          collapsableTreeEntry.selectable = collapsableTreeEntry.children.length === 0;
        }
        this.refresh();
      });
  }

  private subscribeToMySharedCollections() {
    this.store
      .select(CollectionsState.mySharedCollections)
      .pipe(untilDestroyed(this))
      .subscribe(collection => {
        const collapsableTreeEntry = this.collectionEntries.find(
          entry => entry.value === 'mySharedCollections'
        );
        if (collapsableTreeEntry) {
          collapsableTreeEntry.children = collection.map(collectionItem => {
            return {
              value: collectionItem.id,
              label: collectionItem.name,
              selectable: true,
              selected: collectionItem.id === this.collectionId,
              parent: collapsableTreeEntry
            } as CollapsableTreeEntry;
          });
          collapsableTreeEntry.selectable = collapsableTreeEntry.children.length === 0;
        }
        this.refresh();
      });
  }

  private subscribeToSharedWithMeCollections() {
    this.store
      .select(CollectionsState.collectionsSharedWithMe)
      .pipe(untilDestroyed(this))
      .subscribe(collection => {
        const collapsableTreeEntry = this.collectionEntries.find(
          entry => entry.value === 'sharedCollectionsWithMe'
        );
        if (collapsableTreeEntry) {
          collapsableTreeEntry.children = collection.map(collectionItem => {
            return {
              value: collectionItem.id,
              label: collectionItem.name,
              selectable: true,
              selected: collectionItem.id === this.collectionId,
              parent: collapsableTreeEntry
            } as CollapsableTreeEntry;
          });
          collapsableTreeEntry.selectable = collapsableTreeEntry.children.length === 0;
        }
        this.refresh();
      });
  }

  public collectionSelected(entryValue: string) {
    this.store.dispatch(new SetCollectionsStorageData({
      filtersSelectionData: undefined,
      sortBy: undefined,
      selected: [],
      sortOrder: 'ASC',
      isSeeingSelection: false,
      searchValue: undefined,
      page: 0
    }))
      .pipe(first())
      .subscribe(() => {
        this.emptyCollectionTypeMessage = '';
        this.selectedEntry = entryValue;

        this.store.dispatch(new SetSelectedCollection(this.selectedEntry));
        if (!this.collectionEntries.find(l => l.value === entryValue)) {
          this.collectionId = entryValue;

          if (
            this.collectionEntries
              .find(rootEntry => rootEntry.value === CollectionType.sharedCollectionsWithMe)
              ?.children?.find(sharedCollection => sharedCollection.value === this.collectionId)
          ) {
            this.selectedCollectionType = CollectionType.sharedCollectionsWithMe;
          } else if (
            this.collectionEntries
              .find(rootEntry => rootEntry.value === CollectionType.mySharedCollections)
              ?.children?.find(sharedCollection => sharedCollection.value === this.collectionId)
          ) {
            this.selectedCollectionType = CollectionType.mySharedCollections;
          } else {
            this.selectedCollectionType = CollectionType.myCollections;
          }
        } else if (
          entryValue === CollectionType.myCollections ||
          entryValue === CollectionType.mySharedCollections
        ) {
          this.emptyCollectionTypeMessage = `Start building your collections by adding terms under the tab "My terms"`;
        } else if (entryValue === 'sharedCollectionsWithMe') {
          this.emptyCollectionTypeMessage = 'There are no collections shared with you at the moment.';
        }
      });
  }

  public refresh() {
    this.collectionEntries = [...this.collectionEntries];
    this.cdr.markForCheck();
  }

  public reloadCollections() {
    this.store
      .dispatch(new GetMyCollections())
      .pipe(first())
      .subscribe(() => {
        let selectedTreeEntry: CollapsableTreeEntry | undefined = undefined;
        this.collectionEntries.forEach(entry =>
          entry.children?.forEach(childEntry => {
            if (childEntry.value === this.selectedEntry) {
              selectedTreeEntry = childEntry;
            }
          })
        );

        if (selectedTreeEntry) {
          this.collectionsMenu?.selectEntry(selectedTreeEntry);
        }
      });
  }

  public reloadCollectionsForDelete() {
    this.store
      .dispatch(new GetMyCollections())
      .pipe(first())
      .subscribe(() => {
        const value = this.collectionsMenu?.selectFirstChild()?.value;
        if (value) {
          this.selectedEntry = value;
          this.collectionSelected(value);
        }
      });
  }

}
