import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PartialConceptExport } from '../../../../../domain/stores/taxonomy-export-data/taxonomy-export-data.action';
import { first } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { PartialExportType } from '../../../../../domain/mappers/taxonomy-export-data-mapper';
import { Observable, of } from 'rxjs';
import { NotificationService, RadioButtonOption } from '@apiax/web-commons';
import { TaxonomyExportDataState } from '../../../../../domain/stores/taxonomy-export-data/taxonomy-export-data.state';

@UntilDestroy()
@Component({
  selector: 'app-partial-export',
  templateUrl: './partial-export.component.html',
  styleUrls: ['./partial-export.component.scss']
})
export class PartialExportComponent implements OnInit {
  formGroup = new UntypedFormGroup({});
  typeFormControl = new UntypedFormControl(undefined, Validators.required);
  valuesFormControl = new UntypedFormControl(undefined, Validators.required);
  values: string[] = [];
  performPartialExport = this.getPartialExportFunction();
  partialExportTypeOptions$: Observable<RadioButtonOption[]>;
  isPerformingOtherExports$: Observable<boolean>;

  constructor(
    private store: Store,
    private notificationService: NotificationService
  ) {
    this.partialExportTypeOptions$ = of([
      {
        id: PartialExportType.CONCEPT_ID,
        label: 'Concept ID'
      },
      {
        id: PartialExportType.CONCEPT_TERM_ID,
        label: 'Term ID'
      },
      {
        id: PartialExportType.ORGANIZATION_ID,
        label: 'Company ID'
      }
    ]);
  }

  ngOnInit(): void {
    this.formGroup.addControl('type', this.typeFormControl);
    this.formGroup.addControl('values', this.valuesFormControl);
    this.isPerformingOtherExports$ = this.store.select(TaxonomyExportDataState.isPerformingFullExport);
  }

  getPartialExportFunction() {
    return (callback: () => void) => {
      const v: string[] = this.valuesFormControl.value as string[];
      this.store
        .dispatch(
          new PartialConceptExport({
            values: v,
            type: this.typeFormControl.value
          })
        )
        .pipe(first())
        .subscribe(() => {
          callback();
          this.notificationService.showSimpleAlert('Partial export performed successfully', 'success');
        });
    };
  }
}
