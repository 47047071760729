export interface ConceptUsage {
  id: string;
  conceptId?: string;
  termId?: string;
  usageTypeLabel?: string;
  referencedBy?: ConceptUsageReference;
  ruleId?: string;
  type?: ConceptUsageRuleType;
  ruleLabel?: string;
}

export enum ConceptUsageRuleType {
  TEMPLATE,
  COUNTRY_RULE,
  CUSTOM_RULE
}

export interface ConceptUsageReference {
  conceptId?: string;
  term?: string;
  label?: string;
}
