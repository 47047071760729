import { SearchCriteria } from '@apiax/web-commons';

export enum TaxonomyLatestChangesAction {
  SearchLatestChanges = '[Search Latest Changes] Performs a search of the latest changes',
  ExportLatestChanges = '[Export Latest Changes] Performs a export of the latest changes'
}

export class SearchLatestChanges {
  public static readonly type = TaxonomyLatestChangesAction.SearchLatestChanges;

  constructor(public searchCriteria: SearchCriteria) {}
}

export class ExportLatestChanges {
  public static readonly type = TaxonomyLatestChangesAction.ExportLatestChanges;

  constructor(public searchCriteria: SearchCriteria) {}
}
