/**
 * Taxonomy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetEditorConceptHierarchyRequest { 
    conceptId?: string;
    relationType?: GetEditorConceptHierarchyRequest.RelationTypeEnum;
    depth?: number;
}
export namespace GetEditorConceptHierarchyRequest {
    export type RelationTypeEnum = 'BROADER' | 'NARROWER';
    export const RelationTypeEnum = {
        BROADER: 'BROADER' as RelationTypeEnum,
        NARROWER: 'NARROWER' as RelationTypeEnum
    };
}


