import { FilterOption } from '../../models';
import { Filter, FILTER_TYPE, SearchCriteria } from '@apiax/web-commons';
import { ConceptUtils } from './concept.utils';
import { BehaviorSubject } from 'rxjs';
import { FilterOption as CommonsFilterOption } from '@apiax/web-commons/lib/filters/filters.interfaces';
import { RuleSetFilterOption } from '../../models/rule-set-filter-option';
import { isEmpty, isEqual } from 'lodash-es';

export class FilterUtils {
  static buildJurisdictionsFilter(
    jurisdictionOptions: FilterOption[],
    searchCriteria: SearchCriteria
  ): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'jurisdictions',
      type: 'jurisdictions',
      options: {
        searchable: true,
        values: jurisdictionOptions.map(j => {
          return {
            value: j.id,
            label: j.name,
            selected: !!searchCriteria?.contentFilters?.['jurisdictions']?.find(v => v === j.id)
          };
        })
      },
      name: 'Jurisdiction'
    };
  }

  static buildOrganizationFilter(
    organizationOptions: FilterOption[],
    searchCriteria: SearchCriteria,
    label = 'Owner'
  ): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'organizations',
      type: 'organizations',
      options: {
        searchable: true,
        values: organizationOptions.map(j => {
          return {
            value: j.id,
            label: j.name,
            selected: !!searchCriteria?.contentFilters?.['organizations']?.find(v => v === j.id)
          };
        })
      },
      name: label
    };
  }

  static buildStatusFilter(searchCriteria: SearchCriteria, hasViewInactiveTerms: boolean): Filter {
    let status = [...ConceptUtils.STATUS_VALUES];

    if (!hasViewInactiveTerms) {
      status = status.filter(s => s.id !== ConceptUtils.INACTIVE_STATUS_VALUE);
    }

    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'status',
      type: 'status',
      options: {
        searchable: true,
        values: status.map(v => {
          return {
            value: v.id,
            label: v.label,
            selected: !!searchCriteria?.contentFilters?.['status']?.find(sc => sc === v.id)
          };
        })
      },
      name: 'Status'
    };
  }

  static buildRuleSetFamiliesFilter(
    ruleSetFamiliesOptions: FilterOption[],
    searchCriteria: SearchCriteria
  ): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'ruleSetFamilies',
      type: 'ruleSetFamilies',
      noReorder: true,
      options: {
        searchable: true,
        values: ruleSetFamiliesOptions.map(j => {
          return {
            value: j.id,
            label: j.name,
            selected: !!searchCriteria?.contentFilters?.['ruleSetFamilies']?.find(v => v === j.id)
          };
        })
      },
      selectionChange$: new BehaviorSubject<CommonsFilterOption[]>([]),
      name: 'Rule set family'
    };
  }

  static buildRuleSetsFilter(
    ruleSetFamiliesOptions: FilterOption[],
    searchCriteria: SearchCriteria
  ): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'ruleSets',
      type: 'ruleSets',
      noReorder: false,
      options: {
        searchable: true,
        values: ruleSetFamiliesOptions.map(j => {
          return {
            value: j.id,
            label: j.name,
            selected: !!searchCriteria?.contentFilters?.['ruleSets']?.find(v => v === j.id)
          };
        })
      },
      rebuildOptions$: new BehaviorSubject<boolean>(false),
      name: 'Rule set'
    };
  }

  static buildCategoriesFilter(
    categoriesOptions: FilterOption[],
    searchCriteria: SearchCriteria
  ): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'categories',
      type: 'categories',
      options: {
        searchable: true,
        values: categoriesOptions.map(j => {
          return {
            value: j.id,
            label: j.name,
            selected: !!searchCriteria?.contentFilters?.['categories']?.find(v => v === j.id)
          };
        })
      },
      name: 'Category'
    };
  }

  static buildDateRangeFilter(searchCriteria: SearchCriteria): Filter {
    let values = [];
    if (searchCriteria?.contentFilters?.['dateRange']) {
      values = [
        {
          label: 'from',
          value: searchCriteria?.contentFilters['dateRange'][0],
          selected: true
        },
        {
          label: 'to',
          value: searchCriteria?.contentFilters['dateRange'][1],
          selected: true
        }
      ];
    }
    return {
      type: 'date',
      name: 'date',
      label: 'Enter a date range',
      property: 'dateRange',
      controlType: FILTER_TYPE.DATE_RANGE,
      options: {
        searchable: false,
        values: values
      }
    };
  }

  static buildEmployeesFilter(
    categoriesOptions: FilterOption[],
    searchCriteria: SearchCriteria
  ): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'userIds',
      type: 'userIds',
      options: {
        searchable: true,
        values: categoriesOptions.map(j => {
          return {
            value: j.id,
            label: j.name,
            selected: !!searchCriteria?.contentFilters?.['userIds']?.find(v => v === j.id)
          };
        })
      },
      name: 'Employee'
    };
  }

  static buildDocumentTypesFilter(
    documentTypes: FilterOption[],
    searchCriteria: SearchCriteria
  ): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'documentTypes',
      type: 'documentTypes',
      options: {
        values: documentTypes.map(j => {
          return {
            value: j.id,
            label: j.name,
            selected: !!searchCriteria?.contentFilters['documentTypes']?.find(v => v === j.id)
          };
        })
      },
      name: 'Document Type'
    };
  }

  static buildDocumentActivationStatusFilter(
    documentActivationStatus: FilterOption[],
    searchCriteria: SearchCriteria
  ): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'documentActivationStatus',
      type: 'documentActivationStatus',
      options: {
        values: documentActivationStatus.map(j => {
          return {
            value: j.id,
            label: j.name,
            selected: !!searchCriteria?.contentFilters?.['documentActivationStatus']?.find(
              v => v === j.id
            )
          };
        })
      },
      name: 'Activation Status'
    };
  }

  static buildDocumentVersionsFilter(
    documentVersions: FilterOption[],
    searchCriteria: SearchCriteria
  ): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'documentVersions',
      type: 'documentVersions',
      noReorder: true,
      options: {
        searchable: true,
        values: documentVersions.map(j => {
          return {
            value: j.id,
            label: j.name,
            selected: !!searchCriteria?.contentFilters?.['documentVersions']?.find(v => v === j.id)
          };
        })
      },
      name: 'Version'
    };
  }

  static rebuildRuleSetFilter(
    selectedRuleSetFamilyFilterOptions: CommonsFilterOption[],
    ruleSets: RuleSetFilterOption[],
    searchCriteria: SearchCriteria,
    ruleSetsFilter: Filter
  ) {
    const selectedRuleSetFamilies = isEmpty(selectedRuleSetFamilyFilterOptions)
      ? ruleSets
      : ruleSets.filter(rs =>
          selectedRuleSetFamilyFilterOptions.find(
            selectedRuleSetFamily => selectedRuleSetFamily.value === rs.ruleSetFamilyId
          )
        );
    const ruleSetsFilterAux = FilterUtils.buildRuleSetsFilter(
      selectedRuleSetFamilies,
      searchCriteria
    );
    if (!isEqual(ruleSetsFilter.options.values, ruleSetsFilterAux.options.values)) {
      ruleSetsFilter.options.values = ruleSetsFilterAux.options.values;
      ruleSetsFilter.rebuildOptions$.next(true);
    } else {
      ruleSetsFilter.rebuildOptions$.next(false);
    }
  }
}
