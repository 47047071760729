import { groupBy, isEqual, map } from 'lodash-es';
import moment from 'moment/moment';
import { GetConceptChangeLogItem, GetConceptChangeLogResponse } from '../../../generated/v3';
import {
  ChangeLogDayEntry,
  ChangeLogResultByDay,
  CommandEnum,
  ConceptChangeLog
} from '../../models/concept-changelog-models';

export class TaxonomyChangeLogMapper {
  static mapToConceptChangeLog(item: GetConceptChangeLogItem): ConceptChangeLog {
    return {
      id: item.id,
      tags: this.mapToTag(item),
      userOrganizationName: item.userOrganizationName,
      expanded: false,
      language: item.language,
      oldValue: item.oldValue,
      newValue: item.newValue,
      userName: item.userName,
      commandName: item.commandName,
      commandText: item.commandText,
      timestamp: item.timestamp,
      detailChanges: this.mapDetailChanges(item)
    };
  }

  static mapDetailChanges(changeLog: GetConceptChangeLogItem): Map<string, string> {
    const detailChanges = new Map<string, string>();
    if (changeLog.locationType && changeLog.locationField) {
      detailChanges.set('Location', changeLog.locationType + ' - ' + changeLog.locationField);
    }
    if (changeLog.language) {
      detailChanges.set('Language', changeLog.language.toUpperCase());
    }
    if (changeLog.oldValue) {
      detailChanges.set('Old Value', changeLog.oldValue);
    }
    if (changeLog.newValue) {
      detailChanges.set('New Value', changeLog.newValue);
    }
    return detailChanges;
  }

  static mapToTag(changeLogItem: GetConceptChangeLogItem) {
    const tags: string[] = [];
    const str = changeLogItem.commandName.toUpperCase().replace('COMMAND', '');

    if (str.startsWith(CommandEnum.CREATE)) {
      tags.push(CommandEnum.CREATE);
      tags.push(str.substring(CommandEnum.CREATE.length));
    }
    if (str.startsWith(CommandEnum.UPDATE)) {
      tags.push(CommandEnum.UPDATE);
      tags.push(str.substring(CommandEnum.UPDATE.length));
    }
    if (str.startsWith(CommandEnum.ADD)) {
      tags.push(CommandEnum.ADD);
      tags.push(str.substring(CommandEnum.ADD.length));
    }
    if (str.startsWith(CommandEnum.REMOVE)) {
      tags.push(CommandEnum.REMOVE);
      tags.push(str.substring(CommandEnum.REMOVE.length));
    }

    if (changeLogItem.tags != null && changeLogItem.tags.length > 0) {
      changeLogItem.tags.forEach(t => tags.push(t));
    }
    return tags;
  }

  static aggregateChangeLog(
    changeLogResult: ChangeLogResultByDay,
    newResult: ChangeLogResultByDay
  ) {
    changeLogResult.hasMore = newResult.hasMore;
    newResult.days.forEach(newChangeLogDay => {
      const found = changeLogResult.days.filter(changeLogDay =>
        isEqual(changeLogDay.day, newChangeLogDay.day)
      );
      if (found.length > 0) {
        found[0].entries = found[0].entries.concat(newChangeLogDay.entries);
      } else {
        changeLogResult.days = changeLogResult.days.concat(newChangeLogDay);
      }
    });
    return changeLogResult;
  }

  static convertToChangeLogByDay(
    result: GetConceptChangeLogResponse,
    pageNumber: number,
    pageSize: number
  ): ChangeLogResultByDay {
    const resultsByDay: ChangeLogResultByDay = {
      hasMore: result.total > (pageNumber + 1) * pageSize,
      days: []
    };
    map(
      groupBy(result.changeLogItems, function (item) {
        const ts = Number(item.timestamp);
        return moment.unix(ts).startOf('day').format();
      }),
      function (items, day) {
        const dayEntries: ChangeLogDayEntry = {
          day: day,
          entries: items.map(item => TaxonomyChangeLogMapper.mapToConceptChangeLog(item))
        };
        resultsByDay.days.push(dayEntries);
      }
    );
    return resultsByDay;
  }
}
