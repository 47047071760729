import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TemplateModalComponent, TemplateModalData } from '@apiax/web-commons';
import { Observable } from 'rxjs';

export interface DeployedUsageModalData {
  title: string;
  message: string;
  actionLabel: string;
}

@Component({
  selector: 'app-deployed-usage-modal',
  templateUrl: './deployed-usage-modal.component.html',
  styleUrls: ['./deployed-usage-modal.component.scss']
})
export class DeployedUsageModalComponent implements OnInit {

  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG,
    width: '570px',
    minHeight: 300
  };

  public templateData: TemplateModalData;

  public isLoading$: Observable<boolean>;

  constructor(
    public dialogRef: MatDialogRef<DeployedUsageModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: DeployedUsageModalData
  ) {
    this.templateData = {
      title: this.data.title,
      hideClose: false,
      disableClose: false
    };
  }

  ngOnInit() {
  }

  public onConfirmation() {
    this.dialogRef.close(true);
  }

  public onCancel() {
    this.dialogRef.close(false);
  }

}
