import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  Column,
  DataTableComponent,
  Filter,
  FilterData,
  MenuAction,
  MenuActionInput,
  NotificationService,
  RadioButtonOption,
  SearchCriteria,
  StorageData
} from '@apiax/web-commons';
import { DataTableSearchMetadata } from '@apiax/web-commons/lib/data-table/data-table.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { Activity, AuthorizationService } from 'shared/core';
import { ConceptSearchMode } from '../../../../domain/mappers/taxonomy-search-terms-mapper';
import { TaxonomyUtilsService } from '../../../../domain/services/taxonomy-utils.service';
import { ResetConcept } from '../../../../domain/stores/taxonomy-concept';
import { LoadSearchTermsFilterOptions } from '../../../../domain/stores/taxonomy-filters/taxonomy-filters.action';
import { TaxonomyFiltersState } from '../../../../domain/stores/taxonomy-filters/taxonomy-filters.state';
import {
  Export,
  Search,
  SetFilterStorageData,
  SetSearchMode,
  SetStorageData
} from '../../../../domain/stores/taxonomy-my-terms/taxonomy-my-terms.action';
import { TaxonomyMyTermsState } from '../../../../domain/stores/taxonomy-my-terms/taxonomy-my-terms.state';
import { FilterUtils } from '../../../../domain/utils/filter.utils';
import { ConceptSearchResult } from '../../../../models';
import { RuleSetFilterOption } from '../../../../models/rule-set-filter-option';
import { ConceptTableUtils } from '../../../shared-components/utils/concept-table-utils';
import {
  CollectionModalComponent,
  CollectionModalOutput
} from '../../../shared-components/modals/collection-modal/collection-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AddToCollection, CreateCollection } from '../../../../domain/stores/collections/collections.action';


@UntilDestroy()
@Component({
  selector: 'app-taxonomy-search-terms',
  templateUrl: './taxonomy-my-terms.component.html',
  styleUrls: ['./taxonomy-my-terms.component.scss']
})
export class TaxonomyMyTermsComponent implements OnInit {
  public readonly PAGE_SIZE = 10;

  @ViewChild('table')
  table: DataTableComponent<any>;

  public searchValue: string;

  public isDownloading$ = new BehaviorSubject(false);
  public downloadPermission = Activity.ViewTerms;

  public filters: Filter[];
  public columns: Column[];
  public search: DataTableSearchMetadata = {
    width: '568px',
    defaultText: '',
    placeholder: 'Search taxonomy term'
  };

  public searchResults: ConceptSearchResult[];
  public totalResults: number;

  public searchModeControl: UntypedFormControl;
  public searchModeOptions$: Observable<RadioButtonOption[]>;
  public hasSelectionMode$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  idFetcher = obj => obj.id;
  menuActions: MenuAction[];


  constructor(
    private router: Router,
    private store: Store,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private authorizationService: AuthorizationService,
    public taxonomyUtilsService: TaxonomyUtilsService
  ) {
    this.taxonomyUtilsService.conceptSelectedTabIndex = 0;
    this.isLoading$ = this.store.select(TaxonomyFiltersState.isSearchTermsFiltersLoaded).pipe(
      untilDestroyed(this),
      map(value => !value)
    );
    this.hasSelectionMode$ = this.authorizationService.isAllowed(Activity.MyCollectionsAccess);
    this.initSearchMode();
    this.buildColumns();
    this.menuActions = this.buildMenuActions();
  }

  private initSearchMode() {
    const options: RadioButtonOption[] = [
      {
        id: ConceptSearchMode.Standard,
        label: ConceptSearchMode.Standard
      },
      {
        id: ConceptSearchMode.Exact,
        label: ConceptSearchMode.Exact
      }
    ];

    this.searchModeControl = new UntypedFormControl(options[0], [Validators.required]);
    this.searchModeOptions$ = of(options);
  }

  private searchModeValueChanges() {
    this.searchModeControl.valueChanges
      .pipe(
        untilDestroyed(this),
        switchMap((value) => this.store.dispatch(new SetSearchMode(value)))
      )
      .subscribe(() => {
        if (this.searchValue) {
          this.table.clearSelection();
          this.table.changePage({
            offset: 0
          });
        }
      });
  }

  private buildColumns() {
    this.columns = [
      {
        prop: 'term',
        label: 'Term',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 5
      },
      {
        prop: 'termId',
        label: 'Term ID',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 5
      },
      {
        prop: 'rawDefinition',
        label: 'Definition',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 5
      },
      {
        prop: 'ruleSetsLabel',
        label: 'Rule set',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'jurisdictionsLabel',
        label: 'Jurisdiction',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'ownerLabel',
        label: 'Owner',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'categoriesLabel',
        label: 'Category',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'isPrivate',
        label: 'Privacy',
        transformValue: value => ConceptTableUtils.getPrivacyIcon(value),
        tooltip: value => ConceptTableUtils.getPrivacyToolTip(value),
        sortable: false,
        extraClasses: 'center-aligned',
        size: 1.5
      },
      {
        prop: 'state',
        label: 'Status',
        transformValue: value => ConceptTableUtils.getStatusLabel(value),
        tooltip: value => value,
        sortable: false,
        extraClasses: 'center-aligned',
        size: 2
      }
    ];
  }

  private loadFilterOptions() {
    if (!this.store.selectSnapshot(TaxonomyFiltersState.isSearchTermsFiltersLoaded)) {
      this.store.dispatch(new LoadSearchTermsFilterOptions())
        .pipe(first())
        .subscribe(() => this.buildFilters());
    } else {
      this.buildFilters();
    }
  }

  private initSubscriptions() {
    this.store.select(TaxonomyMyTermsState.searchResults)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.searchResults = results));

    this.store.select(TaxonomyMyTermsState.totalResults)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.totalResults = results));

    this.store.select(TaxonomyMyTermsState.searchMode)
      .pipe(untilDestroyed(this))
      .subscribe(searchMode => {
        this.searchModeControl.setValue(searchMode);
      });
  }

  private buildFilters() {
    combineLatest([
      this.store.select(TaxonomyMyTermsState.searchCriteria),
      this.store.select(TaxonomyFiltersState.jurisdictionOptions),
      this.store.select(TaxonomyFiltersState.organizationOptions),
      this.store.select(TaxonomyFiltersState.ruleSetFamilyOptions),
      this.store.select(TaxonomyFiltersState.ruleSetOptions),
      this.store.select(TaxonomyFiltersState.categoryOptions)
    ])
      .pipe(first())
      .subscribe(
        ([searchCriteria, jurisdictions, organizations, ruleSetFamilies, ruleSets, categories]) => {

          this.search = {
            defaultText: this.store.selectSnapshot(TaxonomyMyTermsState.storageData)?.searchValue,
            placeholder: this.search.placeholder,
            width: this.search.width
          };

          const ruleSetFamiliesFilter = FilterUtils.buildRuleSetFamiliesFilter(
            ruleSetFamilies,
            searchCriteria
          );
          const ruleSetsFilter = FilterUtils.buildRuleSetsFilter(ruleSets, searchCriteria);
          this.initReactRuleSetToRuleSetFamiliesChanges(
            ruleSetFamiliesFilter,
            ruleSets,
            searchCriteria,
            ruleSetsFilter
          );
          this.filters = [
            ruleSetFamiliesFilter,
            ruleSetsFilter,
            FilterUtils.buildOrganizationFilter(organizations, searchCriteria),
            FilterUtils.buildJurisdictionsFilter(jurisdictions, searchCriteria),
            FilterUtils.buildCategoriesFilter(categories, searchCriteria)
          ];
        }
      );
  }

  private initReactRuleSetToRuleSetFamiliesChanges(
    ruleSetFamiliesFilter: Filter,
    ruleSets: RuleSetFilterOption[],
    searchCriteria: SearchCriteria,
    ruleSetsFilter: Filter
  ) {
    ruleSetFamiliesFilter.selectionChange$
      .pipe(untilDestroyed(this))
      .subscribe(selectedRuleSetFamilyFilterOptions => {
        FilterUtils.rebuildRuleSetFilter(
          selectedRuleSetFamilyFilterOptions,
          ruleSets,
          searchCriteria,
          ruleSetsFilter
        );
      });
  }

  ngOnInit() {
    this.store.dispatch(new ResetConcept());
    this.loadFilterOptions();
    this.initSubscriptions();
    this.searchModeValueChanges();
  }

  public fetchData(searchCriteria: SearchCriteria) {
    this.searchValue = searchCriteria.searchFilter;

    this.store.dispatch(new Search(searchCriteria));
  }

  public onSelect(row) {
    this.router.navigate(['concept', 'term-editor'], {
      queryParams: { conceptId: row.id }
    });
  }

  public downloadData(_searchCriteria: SearchCriteria) {
    this.store.dispatch(new Export())
      .pipe(first())
      .subscribe(() => {
        this.isDownloading$.next(false);
        this.notificationService.showSimpleAlert(
          'Terms downloaded successfully',
          'success'
        );
      });
  }

  private buildMenuActions(): MenuAction[] {
    return [
      {
        permissions: [Activity.MyCollectionsAccess],
        icon: 'add',
        iconClass: 'material-symbols-outlined',
        cssClass: 'plain-action primary-plain',
        title: 'Add to collection',
        tooltip: 'Add to collection',
        actionFunction: (input: MenuActionInput) => {
          return this.addToListAction(input);
        }
      }
    ];
  }

  private addToListAction(actionInput: MenuActionInput) {
    return this.addToList(actionInput).pipe(
      first(),
      switchMap(modalResponse => {
        if (modalResponse) {
          if (!modalResponse.id) {
            this.notificationService.showSimpleAlert(
              `Your collection "${modalResponse.name}" was successfully created. You can see it under the tab "My collections".`,
              'success'
            );
          } else {
            this.notificationService.showSimpleAlert(
              `The selected terms were successfully added to the collection "${modalResponse.name}".`,
              'success'
            );
          }
          return of(true);
        }
        return of(false);
      })
    );
  }

  private addToList(actionInput: MenuActionInput): Observable<CollectionModalOutput> {
    let index = 0;
    return this.showAddToListModal().pipe(
      first(),
      switchMap((response: CollectionModalOutput) => {
        index++;
        if (index === 1) {
          if (response) {
            return this.processAddToListAction(actionInput, response).pipe(map(_r => response));
          } else {
            return of(undefined);
          }
        } else {
          return of(undefined);
        }
      })
    );
  }

  private showAddToListModal() {
    const dialogRef = this.dialog.open(
      CollectionModalComponent,
      CollectionModalComponent.modalConfig
    );
    return dialogRef.afterClosed();
  }

  private processAddToListAction(actionInput: MenuActionInput, modalOutput: CollectionModalOutput) {
    if (!modalOutput.id) {
      return this.store.dispatch(
        new CreateCollection({
          name: modalOutput.name,
          selections: actionInput.selections,
          exclusions: actionInput.exclusions,
          sharedGroups: modalOutput.sharedGroups,
          searchText: actionInput.searchValue,
          searchCriteria: this.store.selectSnapshot(TaxonomyMyTermsState.searchCriteria)
        })
      );
    } else {
      return this.store.dispatch(
        new AddToCollection({
          id: modalOutput.id,
          name: modalOutput.name,
          selections: actionInput.selections,
          exclusions: actionInput.exclusions,
          sharedGroups: modalOutput.sharedGroups,
          searchText: actionInput.searchValue,
          searchCriteria: this.store.selectSnapshot(TaxonomyMyTermsState.searchCriteria)
        })
      );
    }
  }

  saveToStorageStore = (data: StorageData) => {
    this.store.dispatch(new SetStorageData(data));
  };

  loadFromStorageStore = (): StorageData => {
    const storageData = this.store.selectSnapshot(TaxonomyMyTermsState.storageData);
    this.searchValue = storageData.searchValue;
    return storageData;
  };

  filterSaveToStore = (data: Map<string, FilterData>) => {
    this.store.dispatch(new SetFilterStorageData(data));
  };

  filterLoadFromStore: () => Map<string, FilterData> = (): Map<string, FilterData> => {
    const filterStorageData = this.store.selectSnapshot(TaxonomyMyTermsState.filterStorageData);
    return new Map(Object.entries(filterStorageData));
  };
}
