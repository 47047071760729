import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ChangeLogApiService } from 'generated/v3/api/changeLogApi.service';
import { ConceptApiService } from 'generated/v3/api/conceptApi.service';
import { LanguagesApiService } from 'generated/v3/api/languagesApi.service';
import { UsagesApiService, VariablesApiService } from '../../../../generated/v3';

import { ChangeLogResultByDay, Concept, ConceptHierarchy, ConceptRelation, ConceptUsage, TranslationLanguage } from '../../../models';
import { Translations } from '../../../models/translations-model';
import {
  AddConceptTranslation,
  CheckDeployedUsages,
  ClearTaxonomyConceptState,
  CloneConcept,
  CreateConcept,
  DeleteConceptTranslation,
  ExportUsages,
  GetConceptRelations,
  LoadAndAppendNarrowerConceptHierarchy,
  LoadAndAppendSiblingsConceptHierarchy,
  LoadConcept,
  LoadConceptHierarchy,
  LoadConceptTranslation,
  LoadMoreChangeLog,
  LoadTranslationLanguages, LoadVariables,
  ResetConcept,
  SearchChangeLog,
  SearchUsages,
  SetGoBack,
  UpdateConcept,
  UpdateConceptLocally,
  UpdateConceptRelations,
  UpdateConceptsRelationsLocally,
  UpdateConceptTermAndDefinition,
  UpdateIsDirty,
  UpdateLanguageTermAndDefinition,
  UpdateSelectedLanguage
} from './taxonomy-concept.action';
import { TaxonomyConceptTranslationsStateUseCases } from './usecases/taxonomy-concept-translations-usecases.state';
import { TaxonomyConceptStateUseCases } from './usecases/taxonomy-concept-usecases.state';
import { VariableConcept } from '../../../models/variable-concept';

export enum GoBackType {
  SEARCH_TERMS = 'SEARCH_TERMS',
  MY_COLLECTIONS = 'MY_COLLECTIONS',
  SEARCH_ON_RULES = 'SEARCH_ON_RULES',
  LATEST_CHANGES = 'LATEST_CHANGES',
  EXPORT_DATA = 'EXPORT_DATA',
  CONTROL_PANEL = 'CONTROL_PANEL'
}

export interface TaxonomyConceptStateModel {
  isLoadingTranslationLanguages: boolean;
  translationLanguages: TranslationLanguage[];
  translations: Translations;
  isCheckingDeployedUsages: boolean;
  hasDeployedUsages: boolean;
  conceptId: string;
  concept: Concept;
  isLoadingConcept: boolean;
  conceptUsages: ConceptUsage[];
  conceptUsagesTotal: number;
  isLoadingConceptUsages: boolean;
  isLoadingConceptChangeLog: boolean;
  conceptChangeLogByDay: ChangeLogResultByDay;
  isLoadingConceptRelations: boolean;
  conceptRelations: ConceptRelation[];
  isConceptRelationsDirty: boolean;
  conceptRelationsUpdating: boolean;
  conceptHierarchyTree: ConceptHierarchy[];
  isLoadingConceptHierarchyTree: boolean;
  isAppendingConceptHierarchyTree: boolean;
  isDirty: boolean;
  isTermDirty: boolean;
  isDefinitionDirty: boolean;
  goBack: GoBackType;
  hasAccess: boolean;
  listVariables: VariableConcept[]
}

export const DEFAULT_STATE: TaxonomyConceptStateModel = {
  isLoadingTranslationLanguages: false,
  translationLanguages: [],
  translations: {
    availableTranslationLanguages: [],
    selectedTranslationLanguage: undefined,
    availableConceptTranslations: undefined
  },
  isCheckingDeployedUsages: false,
  hasDeployedUsages: false,
  conceptId: undefined,
  concept: undefined,
  isLoadingConcept: true,
  conceptUsages: [],
  conceptUsagesTotal: undefined,
  isLoadingConceptUsages: false,
  isLoadingConceptChangeLog: false,
  conceptChangeLogByDay: undefined,
  isLoadingConceptRelations: false,
  conceptRelations: undefined,
  isConceptRelationsDirty: false,
  conceptRelationsUpdating: false,
  conceptHierarchyTree: [],
  isLoadingConceptHierarchyTree: true,
  isAppendingConceptHierarchyTree: false,
  isDirty: false,
  isTermDirty: false,
  isDefinitionDirty: false,
  goBack: GoBackType.SEARCH_TERMS,
  hasAccess: true,
  listVariables: []
};

@State<TaxonomyConceptStateModel>({
  name: 'taxonomyConcept',
  defaults: DEFAULT_STATE
})
@Injectable()
export class TaxonomyConceptState {
  private taxonomyConceptStateUseCases: TaxonomyConceptStateUseCases;
  private taxonomyConceptTranslationsStateUseCases: TaxonomyConceptTranslationsStateUseCases;

  @Selector()
  public static isLoadingTranslationLanguages(state: TaxonomyConceptStateModel): boolean {
    return state.isLoadingTranslationLanguages;
  }

  @Selector()
  public static isDirty(state: TaxonomyConceptStateModel): boolean {
    return state.isDirty;
  }

  @Selector()
  public static isTermDirty(state: TaxonomyConceptStateModel): boolean {
    return state.isTermDirty;
  }

  @Selector()
  public static isDefinitionDirty(state: TaxonomyConceptStateModel): boolean {
    return state.isDefinitionDirty;
  }

  @Selector()
  public static translationLanguages(state: TaxonomyConceptStateModel): TranslationLanguage[] {
    return state.translationLanguages;
  }

  @Selector()
  public static getConceptTranslations(state: TaxonomyConceptStateModel): Translations {
    return state.translations;
  }

  @Selector()
  public static isCheckingDeployedUsages(state: TaxonomyConceptStateModel): boolean {
    return state.isCheckingDeployedUsages;
  }

  @Selector()
  public static hasDeployedUsages(state: TaxonomyConceptStateModel): boolean {
    return state.hasDeployedUsages;
  }

  @Selector()
  public static concept(state: TaxonomyConceptStateModel): Concept {
    return state.concept;
  }

  @Selector()
  public static conceptId(state: TaxonomyConceptStateModel): string {
    return state?.conceptId || state.concept?.id;
  }

  @Selector()
  public static conceptUsages(state: TaxonomyConceptStateModel): ConceptUsage[] {
    return state.conceptUsages;
  }

  @Selector()
  public static isLoadingConceptUsages(state: TaxonomyConceptStateModel): boolean {
    return state.isLoadingConceptUsages;
  }

  @Selector()
  public static isLoadingConcept(state: TaxonomyConceptStateModel): boolean {
    return state.isLoadingConcept;
  }

  @Selector()
  public static conceptUsagesTotal(state: TaxonomyConceptStateModel): number {
    return state.conceptUsagesTotal;
  }

  @Selector()
  public static isLoadingConceptChangeLog(state: TaxonomyConceptStateModel): boolean {
    return state.isLoadingConceptChangeLog;
  }

  @Selector()
  public static conceptChangeLogByDay(state: TaxonomyConceptStateModel): ChangeLogResultByDay {
    return state.conceptChangeLogByDay;
  }

  @Selector()
  public static isLoadingConceptRelations(state: TaxonomyConceptStateModel): boolean {
    return state.isLoadingConceptRelations;
  }

  @Selector()
  public static conceptRelations(state: TaxonomyConceptStateModel): ConceptRelation[] {
    return state.conceptRelations;
  }

  @Selector()
  public static conceptRelationsUpdating(state: TaxonomyConceptStateModel): boolean {
    return state.conceptRelationsUpdating;
  }

  @Selector()
  public static conceptHierarchyTree(state: TaxonomyConceptStateModel): ConceptHierarchy[] {
    return state.conceptHierarchyTree;
  }

  @Selector()
  public static isLoadingConceptHierarchyTree(state: TaxonomyConceptStateModel): boolean {
    return state.isLoadingConceptHierarchyTree;
  }

  @Selector()
  public static isAppendingConceptHierarchyTree(state: TaxonomyConceptStateModel): boolean {
    return state.isAppendingConceptHierarchyTree;
  }

  @Selector()
  public static isConceptRelationsDirty(state: TaxonomyConceptStateModel): boolean {
    return state.isConceptRelationsDirty;
  }

  @Selector()
  public static goBack(state: TaxonomyConceptStateModel): GoBackType {
    return state.goBack;
  }

  @Selector()
  public static hasAccess(state: TaxonomyConceptStateModel): boolean {
    return state.hasAccess;
  }

  @Selector()
  public static variables(state: TaxonomyConceptStateModel): VariableConcept[] {
    return state.listVariables;
  }

  constructor(
    languagesApiService: LanguagesApiService,
    taxonomyV3ApiService: ConceptApiService,
    usagesApiService: UsagesApiService,
    changeLogApiService: ChangeLogApiService,
    variablesApiService: VariablesApiService
  ) {
    this.taxonomyConceptStateUseCases = new TaxonomyConceptStateUseCases(
      taxonomyV3ApiService,
      usagesApiService,
      changeLogApiService,
      variablesApiService
    );
    this.taxonomyConceptTranslationsStateUseCases = new TaxonomyConceptTranslationsStateUseCases(
      languagesApiService
    );
  }

  @Action(LoadTranslationLanguages)
  public loadTranslationLanguages(context: StateContext<TaxonomyConceptStateModel>) {
    return this.taxonomyConceptTranslationsStateUseCases.loadTranslationLanguages(context);
  }

  @Action(LoadConceptTranslation)
  public loadConceptTranslation(
    context: StateContext<TaxonomyConceptStateModel>,
    action: LoadConceptTranslation
  ) {
    return this.taxonomyConceptTranslationsStateUseCases.loadConceptTranslation(context, action);
  }

  @Action(AddConceptTranslation)
  public addConceptTranslation(
    context: StateContext<TaxonomyConceptStateModel>,
    action: AddConceptTranslation
  ) {
    return this.taxonomyConceptTranslationsStateUseCases.addConceptTranslation(context, action);
  }

  @Action(DeleteConceptTranslation)
  public deleteConceptTranslation(context: StateContext<TaxonomyConceptStateModel>) {
    return this.taxonomyConceptTranslationsStateUseCases.deleteConceptTranslation(context);
  }

  @Action(UpdateSelectedLanguage)
  public updateSelectedTranslation(
    context: StateContext<TaxonomyConceptStateModel>,
    action: UpdateSelectedLanguage
  ) {
    return this.taxonomyConceptTranslationsStateUseCases.updateSelectedLanguage(context, action);
  }

  @Action(UpdateLanguageTermAndDefinition)
  public updateLanguageTermAndDefinition(
    context: StateContext<TaxonomyConceptStateModel>,
    action: UpdateLanguageTermAndDefinition
  ) {
    return this.taxonomyConceptTranslationsStateUseCases.updateLanguageTermAndDefinition(
      context,
      action
    );
  }

  @Action(UpdateIsDirty)
  public updateIsDirty(context: StateContext<TaxonomyConceptStateModel>, action: UpdateIsDirty) {
    return this.taxonomyConceptTranslationsStateUseCases.updateIsDirty(context, action);
  }

  @Action(UpdateConceptTermAndDefinition)
  public updateConceptTermAndDefinition(
    context: StateContext<TaxonomyConceptStateModel>,
    action: UpdateConceptTermAndDefinition
  ) {
    return this.taxonomyConceptTranslationsStateUseCases.updateConceptTermAndDefinition(
      context,
      action
    );
  }

  @Action(CheckDeployedUsages)
  public checkDeployedUsages(
    context: StateContext<TaxonomyConceptStateModel>,
    action: CheckDeployedUsages
  ) {
    return this.taxonomyConceptStateUseCases.checkDeployedUsages(context, action);
  }

  @Action(LoadConcept)
  public loadConcept(context: StateContext<TaxonomyConceptStateModel>, action: LoadConcept) {
    return this.taxonomyConceptStateUseCases.loadConcept(context, action);
  }

  @Action(ResetConcept)
  public resetConcept(context: StateContext<TaxonomyConceptStateModel>, action: ResetConcept) {
    return this.taxonomyConceptStateUseCases.resetConcept(context, action);
  }

  @Action(CreateConcept)
  public createConcept(context: StateContext<TaxonomyConceptStateModel>, action: CreateConcept) {
    return this.taxonomyConceptStateUseCases.createConcept(context, action);
  }

  @Action(UpdateConceptLocally)
  public updateConceptDefinition(
    context: StateContext<TaxonomyConceptStateModel>,
    action: UpdateConceptLocally
  ) {
    return this.taxonomyConceptTranslationsStateUseCases.updateConceptLocally(context, action);
  }

  @Action(ClearTaxonomyConceptState)
  public clearTaxonomyConceptState(context: StateContext<TaxonomyConceptStateModel>) {
    return this.taxonomyConceptStateUseCases.clear(context);
  }

  @Action(UpdateConcept)
  public updateConcept(context: StateContext<TaxonomyConceptStateModel>, action: UpdateConcept) {
    return this.taxonomyConceptStateUseCases.updateConcept(context, action);
  }

  @Action(CloneConcept)
  public cloneConcept(context: StateContext<TaxonomyConceptStateModel>, action: CloneConcept) {
    return this.taxonomyConceptStateUseCases.cloneConcept(context, action);
  }

  @Action(SearchUsages, { cancelUncompleted: true })
  public search(context: StateContext<TaxonomyConceptStateModel>, action: SearchUsages) {
    return this.taxonomyConceptStateUseCases.searchUsages(context, action);
  }

  @Action(ExportUsages)
  public exportUsages(context: StateContext<TaxonomyConceptStateModel>, action: ExportUsages) {
    return this.taxonomyConceptStateUseCases.exportUsages(context, action);
  }

  @Action(SearchChangeLog)
  public searchChangeLog(
    context: StateContext<TaxonomyConceptStateModel>,
    action: SearchChangeLog
  ) {
    return this.taxonomyConceptStateUseCases.searchChangeLog(context, action);
  }

  @Action(LoadMoreChangeLog)
  public loadMoreChangeLog(
    context: StateContext<TaxonomyConceptStateModel>,
    action: LoadMoreChangeLog
  ) {
    return this.taxonomyConceptStateUseCases.loadMoreChangeLog(context, action);
  }

  @Action(GetConceptRelations)
  public getConceptRelations(
    context: StateContext<TaxonomyConceptStateModel>,
    action: GetConceptRelations
  ) {
    return this.taxonomyConceptStateUseCases.getConceptRelations(context, action);
  }

  @Action(UpdateConceptRelations)
  public updateConceptRelations(
    context: StateContext<TaxonomyConceptStateModel>,
    action: UpdateConceptRelations
  ) {
    return this.taxonomyConceptStateUseCases.updateConceptRelations(context, action);
  }

  @Action(LoadConceptHierarchy)
  public loadConceptHierarchy(
    context: StateContext<TaxonomyConceptStateModel>,
    action: LoadConceptHierarchy
  ) {
    return this.taxonomyConceptStateUseCases.loadConceptHierarchy(context, action);
  }

  @Action(LoadAndAppendNarrowerConceptHierarchy)
  public loadAndAppendNarrowerConceptHierarchy(
    context: StateContext<TaxonomyConceptStateModel>,
    action: LoadAndAppendNarrowerConceptHierarchy
  ) {
    return this.taxonomyConceptStateUseCases.loadAndAppendNarrowerConceptHierarchy(context, action);
  }

  @Action(LoadAndAppendSiblingsConceptHierarchy)
  public loadAndAppendSiblingsConceptHierarchy(
    context: StateContext<TaxonomyConceptStateModel>,
    action: LoadAndAppendSiblingsConceptHierarchy
  ) {
    return this.taxonomyConceptStateUseCases.loadAndAppendSiblingsConceptHierarchy(context, action);
  }

  @Action(UpdateConceptsRelationsLocally)
  public updateConceptRelationsLocally(
    context: StateContext<TaxonomyConceptStateModel>,
    action: UpdateConceptsRelationsLocally
  ) {
    return this.taxonomyConceptStateUseCases.updateConceptsRelationsLocally(context, action);
  }

  @Action(SetGoBack)
  public setGoBack(context: StateContext<TaxonomyConceptStateModel>, action: SetGoBack) {
    return this.taxonomyConceptStateUseCases.setGoBack(context, action);
  }

  @Action(LoadVariables)
  public loadVariables(context: StateContext<TaxonomyConceptStateModel>) {
    return this.taxonomyConceptStateUseCases.loadVariables(context);
  }
}
