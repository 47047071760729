import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {

  @Input()
  public placeholder: string;

  @Output()
  valueChange = new EventEmitter<string>();

  clearValue(searchNode: HTMLInputElement) {
    searchNode.value = '';
    this.valueChange.emit('');
  }

  onKeyup($event: any) {
    this.valueChange.emit($event.target.value);
  }

}
