import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'shared/app/guards';
import { TaxonomyAllowedGuard } from 'shared/app/guards/taxonomy-allowed-guard.service';
import { Activity } from 'shared/core';
import { TaxonomyIndexComponent } from './taxonomy-index.component';
import { TaxonomyMyTermsComponent } from './taxonomy-my-terms/taxonomy-my-terms.component';
import { TaxonomyConceptComponent } from './taxonomy-concept/taxonomy-concept.component';
import { TaxonomyTermEditorComponent } from './taxonomy-concept/taxonomy-term-editor/taxonomy-term-editor.component';
import { TaxonomyUsagesComponent } from './taxonomy-concept/taxonomy-usages/taxonomy-usages.component';
import { TaxonomyChangeLogComponent } from './taxonomy-concept/taxonomy-change-logs/taxonomy-change-log.component';
import { TaxonomyTermEditorTranslationComponent } from './taxonomy-concept/taxonomy-term-editor-translation/taxonomy-term-editor-translation.component';
import { TaxonomyLatestChangesComponent } from './taxonomy-latest-changes/taxonomy-latest-changes.component';
import { TaxonomyExportDataComponent } from './taxonomy-export-data/taxonomy-export-data.component';
import { TaxonomySearchOnRulesComponent } from './taxonomy-search-on-rules/taxonomy-search-on-rules.component';
import { TaxonomyVariablesResolver } from '../../../domain/services/taxonomy-variables-resolver.service';
import {
  TaxonomyLlmDefinitionsComponent
} from './taxonomy-concept/taxonomy-llm-definitions/taxonomy-llm-definitions.component';
import { MyCollectionsComponent } from './my-collections/my-collections.component';

const routes: Routes = [
  {
    path: '',
    canMatch: [AuthGuard],
    canActivate: [TaxonomyAllowedGuard],
    component: TaxonomyIndexComponent,
    resolve: { filters: TaxonomyVariablesResolver },
    data: {
      activities: [Activity.TaxonomyAccess],
      tabsId: 'taxonomyV3-tabs',
      tabs: [
        { text: 'Search terms', path: 'search-terms' },
        { text: 'My collections',
          path: 'my-collections',
          activities: [Activity.MyCollectionsAccess]
        },
        { text: 'Search on Rules', path: 'search-on-rules' },
        { text: 'My Collections', path: 'my-collections' },
        { text: 'Latest Changes', path: 'latest-changes' },
        { text: 'Export Data', path: 'export-data' },
        { text: 'Control Panel', path: 'control-panel' }
      ]
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'search-terms'
      },
      {
        path: 'search-terms',
        component: TaxonomyMyTermsComponent,
        runGuardsAndResolvers: 'always',
        canActivate: [TaxonomyAllowedGuard],
        data: {
          activities: [Activity.ViewTerms]
        }
      },
      {
        path: 'my-collections',
        component: MyCollectionsComponent,
        runGuardsAndResolvers: 'always',
        data: {
          activities: [Activity.MyCollectionsAccess]
        }
      },
      {
        path: 'search-on-rules',
        component: TaxonomySearchOnRulesComponent,
        runGuardsAndResolvers: 'always',
        canActivate: [TaxonomyAllowedGuard],
        data: {
          activities: [Activity.ViewTerms]
        },
        loadChildren: () =>
          import('./taxonomy-search-on-rules/taxonomy-search-on-rules.module').then(
            m => m.TaxonomySearchOnRulesModule
          )
      },
      {
        path: 'latest-changes',
        component: TaxonomyLatestChangesComponent,
        runGuardsAndResolvers: 'always',
        canActivate: [TaxonomyAllowedGuard],
        data: {
          activities: [Activity.TaxonomyLatestChanges]
        }
      },
      {
        path: 'export-data',
        component: TaxonomyExportDataComponent,
        runGuardsAndResolvers: 'always',
        canActivate: [TaxonomyAllowedGuard],
        data: {
          activities: [Activity.ExportData]
        },
        loadChildren: () =>
          import('./taxonomy-export-data/taxonomy-export-data.module').then(
            m => m.TaxonomyExportDataModule
          )
      },
      {
        path: 'control-panel',
        component: TaxonomyIndexComponent,
        runGuardsAndResolvers: 'always'
      }
    ]
  },
  {
    path: 'concept',
    canMatch: [AuthGuard],
    canActivate: [TaxonomyAllowedGuard],
    component: TaxonomyConceptComponent,
    resolve: { filters: TaxonomyVariablesResolver },
    data: {
      activities: [Activity.TaxonomyAccess]
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'term-editor'
      },
      {
        path: 'term-editor',
        component: TaxonomyTermEditorComponent
      },
      {
        path: 'term-editor-translation',
        component: TaxonomyTermEditorTranslationComponent
      },
      {
        path: 'llm-definitions',
        component: TaxonomyLlmDefinitionsComponent
      },
      {
        path: 'relations',
        component: TaxonomyConceptComponent
      },
      {
        path: 'usage',
        component: TaxonomyUsagesComponent
      },
      {
        path: 'change-log',
        component: TaxonomyChangeLogComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaxonomyRoutingModule {}
