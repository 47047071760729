import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataInitializer} from 'shared/core/interfaces/data-initializer.interface';
import {LoaderService} from 'shared/core/services/loader-service';
import {LogService} from './log.service';
import {getConfig} from 'shared/app/config';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class AppService {

    public dataInitializers: (() => Observable<boolean>)[] = [];
    private appLoaded = false;

    constructor(public log: LogService, public loaderService: LoaderService) {
        this.log.debug(`AppService -> Config env: ${getConfig('production') ? 'production' : 'development'}`);
    }

    public registerDataInitializer(dataInitializer: DataInitializer) {
        this.dataInitializers.push(() => dataInitializer.initialize());
    }

    public loadData() {
        if (!this.appLoaded) {
            this.log.debug('Starting the load of application data');
            this.loaderService.startLoading();
            this.appLoaded = true;
            const dataInitializations = this.triggerDataInitializations();
            return combineLatest(dataInitializations).pipe(map(result => {
                this.loaderService.stopLoading();
                return !!result;
            }));
        } else {
            return of(true);
        }
    }

    public unloadData() {
        this.appLoaded = false;
    }

    private triggerDataInitializations(): Observable<boolean>[] {
        const result: Observable<boolean>[] = [];
        this.dataInitializers.forEach(initializer => result.push(initializer()));
        return result;
    }
}
