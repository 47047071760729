import {Injectable} from '@angular/core';
import {cloneDeep, get, isEmpty, set, unset} from 'lodash-es';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

@Injectable({
    providedIn: 'root'
})
/**
 * This class is responsible for creating the a structure in the local and session storage to aggregate
 * data. It contains methods to add, update and remove data on that structure.
 * This will store a JSON structure like:
 * { structureKey: { propertyKey1: value1, propertyKey2: value2 ... } ... }
 *
 * We should use this class when we want to aggregate multiple information about a subject, to avoid
 * having loose data spread in the storage.
 */
export class StorageManagerService {
    constructor(
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService
    ) {
    }

    public createLocalStorageStructure(structureKey: string) {
        if (this.localStorage.retrieve(structureKey) == null) {
            this.localStorage.store(structureKey, {});
        }
    }

    public observeLocalStorage(key: string) {
        return this.localStorage.observe(key);
    }

    public storeInLocalStorage(structureKey: string, propertyKey: string, item: any) {
        const current = this.localStorage.retrieve(structureKey) || {};
        set(current, propertyKey, cloneDeep(item));
        this.localStorage.store(structureKey, current);
    }

    public getFromLocalStorage(structureKey: string, propertyKey?: string) {
        const obj = this.localStorage.retrieve(structureKey);
        return isEmpty(propertyKey) ? obj : get(obj, propertyKey);
    }

    public removeFromLocalStorage(structureKey: string, propertyKeys: string[] = []) {
        if (propertyKeys.length > 0) {
            const map = this.localStorage.retrieve(structureKey);
            propertyKeys.forEach(propKey => unset(map, propKey));
            this.localStorage.store(structureKey, map);
        } else {
            this.localStorage.clear(structureKey);
        }
    }

    public clear() {
        this.localStorage.clear();
        this.sessionStorage.clear();
    }

    public createSessionStorageStructure(structureKey: string) {
        if (this.sessionStorage.retrieve(structureKey) == null) {
            this.sessionStorage.store(structureKey, {});
        }
    }

    public storeInSessionStorage(structureKey: string, propertyKey: string, item: any) {
        const obj = this.sessionStorage.retrieve(structureKey) || {};
        set(obj, propertyKey, cloneDeep(item));
        this.sessionStorage.store(structureKey, obj);
    }

    public getFromSessionStorage(structureKey: string, propertyKey?: string) {
        const obj = this.sessionStorage.retrieve(structureKey);
        return isEmpty(propertyKey) ? obj : get(obj, propertyKey);
    }

    public removeFromSessionStorage(structureKey: string, propertyKeys: string[]) {
        const map = this.sessionStorage.retrieve(structureKey);
        propertyKeys.forEach(propKey => unset(map, propKey));
        this.sessionStorage.store(structureKey, map);
    }
}
