<ng-template [ngIf]="currentUser">
  <apx-navigation-bar
    *ngIf="navigationBarConfig"
    [user]="currentUser"
    [config]="navigationBarConfig"
    (logoutClicked)="logout()">
  </apx-navigation-bar>
</ng-template>
<div class="h-100">
  <div
    id="content"
    [class.collapsed]="collapsed"
    [class.logged-in]="currentUser"
    [class.logged-out]="!currentUser">
    <div class="container-fluid h-100">
      <apx-loading-spinner>
        <router-outlet></router-outlet>
      </apx-loading-spinner>
    </div>
  </div>
</div>
