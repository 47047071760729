import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaxonomyExportDataComponent } from './taxonomy-export-data.component';
import { ActionButtonModule, WebCommonsModule } from '@apiax/web-commons';
import { NgxsModule } from '@ngxs/store';
import { TaxonomyExportDataState } from '../../../../domain/stores/taxonomy-export-data/taxonomy-export-data.state';
import { FullExportComponent } from './full-export/full-export.component';
import { PartialExportComponent } from './partial-export/partial-export.component';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [TaxonomyExportDataComponent, FullExportComponent, PartialExportComponent],
  exports: [TaxonomyExportDataComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([TaxonomyExportDataState]),
    ActionButtonModule,
    WebCommonsModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatIconModule
  ]
})
export class TaxonomyExportDataModule {}
