import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ConceptRelation, ConceptRelationType, ConceptSearchResultModel } from '../../../../models';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {
  ActionInput,
  Column,
  DataTableComponent,
  NotificationService,
  RowIcon,
  SearchCriteria,
  TemplateModalComponent,
  TemplateModalData
} from '@apiax/web-commons';
import { cloneDeep, isEmpty, isEqual } from 'lodash-es';
import { BehaviorSubject, of } from 'rxjs';

export interface ConceptRelationsModalData {
  conceptId: string;
  conceptRelations: ConceptRelation[];
  relationType: ConceptRelationType;
}

export interface ConceptRelationModal extends ConceptRelation {
  id: string;
}

@UntilDestroy()
@Component({
  selector: 'app-concept-relations-modal',
  templateUrl: './concept-relations-modal.component.html',
  styleUrls: ['./concept-relations-modal.component.scss']
})
export class ConceptRelationsModalComponent implements OnInit, AfterViewInit {
  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG,
    width: '800px',
    minHeight: 240
  };
  @ViewChild('table') table: DataTableComponent<any>;
  public templateData: TemplateModalData;
  public conceptRelations: ConceptRelationModal[];
  public initialConceptIdsSet: Set<string> = new Set();
  public columns: Column[];
  public rowIcon: RowIcon;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly PAGE_SIZE = 6;
  conceptRelationsPage: ConceptRelationModal[];
  excludedConceptIds: string[];
  relationType: ConceptRelationType;
  hasChanges = false;
  idFetcher = (obj: any) => {
    return obj.targetConceptId;
  };

  constructor(
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<ConceptRelationsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConceptRelationsModalData
  ) {
    this.templateData = {
      title: data.relationType,
      hideClose: false,
      disableClose: false
    };
    this.excludedConceptIds = [data.conceptId];
    this.relationType = data.relationType;
    this.conceptRelations = cloneDeep(data.conceptRelations).map(r => {
      return {
        id: r.targetConceptId,
        ...r
      };
    });
    this.conceptRelations = this.conceptRelations.sort((a, b) =>
      a.targetConceptTerm.trim().localeCompare(b.targetConceptTerm.trim())
    );

    this.conceptRelations.map(c => c.id).forEach(id => this.initialConceptIdsSet.add(id));
  }

  ngOnInit(): void {
    this.buildColumns();
    this.buildRowIcon();
  }

  private buildColumns() {
    this.columns = [
      {
        prop: 'targetConceptTerm',
        label: 'Term',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 1
      },
      {
        prop: 'targetConceptTermId',
        label: 'Term ID',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 1
      },
      {
        prop: 'targetConceptJurisdictions',
        label: 'Jurisdiction',
        transformValue: value => (!isEmpty(value) ? this.toJurisdictionsTag(value) : '-'),
        tooltip: value => (!isEmpty(value) ? value.join(', ') : '-'),
        sortable: false,
        size: 0.7
      },
      {
        prop: 'targetConceptOwnerName',
        label: 'Owner',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 0.7
      },
      {
        prop: 'rowIcon',
        label: '',
        transformValue: value => value,
        tooltip: value => value,
        extraClasses: 'center-aligned',
        sortable: false,
        size: 0.2
      }
    ];
  }

  private buildRowIcon() {
    this.rowIcon = this.buildDelete();
  }

  private buildDelete(): RowIcon {
    return {
      permissions: [],
      icon: 'delete',
      iconClass: 'material-icons-outlined',
      tooltip: 'Remove',
      actionFunction: (input: ActionInput) => {
        this.conceptRelations = this.conceptRelations.filter(
          r => r.targetConceptId !== input.rowId
        );
        this.table.triggerOnFetch();
        this.hasChanges = this.checkHasChanges();
        return of(true);
      }
    };
  }

  private checkHasChanges(): boolean {
    const currentIdsSet = new Set<string>();
    this.conceptRelations.map(c => c.id).forEach(id => currentIdsSet.add(id));
    return !isEqual(this.initialConceptIdsSet, currentIdsSet);
  }

  public onConfirmation() {
    this.dialogRef.close(this.conceptRelations);
  }

  public onCancel() {
    this.dialogRef.close(false);
  }

  fetchData(searchCriteria: SearchCriteria) {
    const page = this.conceptRelations.slice(
      searchCriteria.page * searchCriteria.pageSize,
      (searchCriteria.page + 1) * searchCriteria.pageSize
    );
    if (page.length === 0 && searchCriteria.page > 0) {
      this.table.changePage({
        offset: searchCriteria.page - 1
      });
    } else {
      this.conceptRelationsPage = page;
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
        this.isLoading$.next(false);
      }, 300);
    }
  }

  private toJurisdictionsTag(value: string[]) {
    return value.map(v => `<span class="multiple-value">${v}</span>`).join('');
  }

  ngAfterViewInit(): void {
    this.table.changePage({
      offset: 0
    });
  }

  addConceptToRelation(concept: ConceptSearchResultModel) {
    if (this.conceptRelations.find(r => r.targetConceptId === concept.id)) {
      this.notificationService.showSimpleAlert('This concept is already selected', 'warning');
    } else {
      this.conceptRelations = [
        {
          id: concept.id,
          relationId: null,
          targetConceptTerm: concept.term,
          targetConceptId: concept.id,
          targetConceptJurisdictions: concept.jurisdictions,
          targetConceptOwnerName: concept.ownerLabel,
          targetConceptTermId: concept.termId,
          relationType: this.relationType
        },
        ...this.conceptRelations
      ];
      this.conceptRelations = this.conceptRelations.sort((a, b) =>
        a.targetConceptTerm.trim().localeCompare(b.targetConceptTerm.trim())
      );

      this.table.changePage({
        offset: 0
      });
      this.hasChanges = this.checkHasChanges();
    }
  }
}
