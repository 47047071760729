export class ConceptHierarchy {
  uid: string;
  id: string;
  term: string;
  jurisdictions: string[];
  ownerName: string;
  categories: string[];
  parent?: ConceptHierarchy;
  childrenConcepts?: ConceptHierarchy[];
  hasChildrenLoaded: boolean;
  hasSiblingsLoaded: boolean;

  static comparator(a: ConceptHierarchy, b: ConceptHierarchy) {
    return a.term.localeCompare(b.term);
  }

  static getRootElements(elements: ConceptHierarchy[]) {
    return elements.filter(e => !e.parent).sort(this.comparator);
  }
}
