/**
 * Taxonomy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetLatestChangesFilters { 
    text?: string;
    from?: string;
    to?: string;
    userIds?: Array<string>;
    organizations?: Array<string>;
    jurisdictions?: Array<string>;
    ruleSetFamilyIds?: Array<string>;
    ruleSetIds?: Array<string>;
    categories?: Array<string>;
    states?: Array<GetLatestChangesFilters.StatesEnum>;
}
export namespace GetLatestChangesFilters {
    export type StatesEnum = 'DEPRECATED' | 'ACTIVE' | 'INACTIVE';
    export const StatesEnum = {
        DEPRECATED: 'DEPRECATED' as StatesEnum,
        ACTIVE: 'ACTIVE' as StatesEnum,
        INACTIVE: 'INACTIVE' as StatesEnum
    };
}


