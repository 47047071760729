import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TemplateModalComponent, TemplateModalData } from '@apiax/web-commons';
import { Observable } from 'rxjs';

export interface OutdatedConceptModalData {
  id: string;
}

@Component({
  selector: 'app-outdated-concept-modal',
  templateUrl: './outdated-concept-modal.component.html',
  styleUrls: ['./outdated-concept-modal.component.scss']
})
export class OutdatedConceptModalComponent implements OnInit {

  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG,
    width: '500px',
    minHeight: 215
  };

  public templateData: TemplateModalData;

  public isLoading$: Observable<boolean>;

  constructor(
    public dialogRef: MatDialogRef<OutdatedConceptModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: OutdatedConceptModalData
  ) {
    this.templateData = {
      title: 'Operation not allowed',
      hideClose: false,
      disableClose: false
    };
  }

  ngOnInit() {
  }

  public onConfirmation() {
    this.dialogRef.close(true);
  }

  public onCancel() {
    this.dialogRef.close(false);
  }

}
