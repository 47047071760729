import { Component, Inject } from '@angular/core';
import { TemplateModalComponent, TemplateModalData } from '@apiax/web-commons';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { VariableConcept, VariableType } from '../../../../models/variable-concept';

export interface ConceptSelectionVariablesModalData {
  variables: VariableConcept[];
}

@Component({
  selector: 'app-concept-selection-variables',
  templateUrl: './concept-selection-variables.component.html',
  styleUrls: ['./concept-selection-variables.component.scss']
})
export class ConceptSelectionVariablesComponent {
  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG,
    width: '647px'
  };

  public templateData: TemplateModalData;
  public isLoading$: Observable<boolean>;
  public companyVariables: VariableConcept[];
  public legalEntityVariables: VariableConcept[];
  public systemVariables: VariableConcept[];
  public generalVariables: VariableConcept[];
  public investmentFundsVariables: VariableConcept[];
  selectedTabIndex: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ConceptSelectionVariablesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConceptSelectionVariablesModalData
  ) {
    this.templateData = {
      title: 'Add variables to term definition',
      hideClose: false,
      disableClose: false
    };
    this.companyVariables = this.data.variables.filter(variable => variable.type === VariableType.COMPANY);
    this.legalEntityVariables = this.data.variables.filter(variable => variable.type === VariableType.LEGAL_ENTITY)
    this.systemVariables = this.data.variables.filter(variable => variable.type === VariableType.SYSTEM);
    this.generalVariables = this.data.variables.filter(variable => variable.type === VariableType.INSTRUMENTS_DATA_GENERAL);
    this.investmentFundsVariables = this.data.variables.filter(variable => variable.type === VariableType.INSTRUMENTS_DATA_INVESTMENT_FUNDS);
  }

  onAddVariable(variable: VariableConcept) {
    return () => {
      this.onVariableSelected(variable)
    };
  }

  onVariableSelected(variable: VariableConcept) {
    this.dialogRef.close(variable)
  }
}
