export enum OrganizationsAction {
  LoadAccessibleOwners = '[Load Accessible Owners] Loads the accessible owners',
  LoadProductRuleSets = '[Load Product Rule Sets] Loads the accessible product rule sets combinations',
  Clear = '[Clear Organizations Store] Clear Organizations Store'
}

export class LoadAccessibleOwners {
  public static readonly type = OrganizationsAction.LoadAccessibleOwners;
}

export class LoadProductRuleSets {
  public static readonly type = OrganizationsAction.LoadProductRuleSets;
}

export class ClearOrganizationState {
  public static readonly type = OrganizationsAction.Clear;
}
